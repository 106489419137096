import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import { ButtonsBlock, ContentWrapper } from 'components/pages/order/OrderSummary/styles';
import { PaymentBlock } from 'components/pages/order/PaymentBlock/PaymentBlock';
import { ItinerariesDetailed } from 'components/ItinerariesDetailed/ItinerariesDetailed/ItinerariesDetailed';
import { PassengerListItem, MarginTop, Typography } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { routes } from 'routes';
import { BoxSpaceBetween, PaperItemWrapper } from 'style';
import { resetSearchState } from 'redux/slices/search/search-slice';
import { resetAllPagesAccess } from 'redux/slices/accessability/accessability-slice';
import { capitalizeWord } from 'utils/strings';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { resetPaymentState } from 'redux/slices/payment/payment-slice';

export const OrderSummary = () => {
  const { passengers, externalPNRs, pnrEmail, fasaxPNR } = useAppSelector((state) => state.flightOrder);
  const { cabin } = useAppSelector((state) => state.search);
  const priceQuote = useAppSelector((state) => state.priceOffer.priceOffer?.priceQuote);
  const journeyOption = useAppSelector((state) => state.journeyOption.journeyOption);

  const dispatch = useDispatch();
  const history = useHistory();

  const onNewSearch = () => {
    dispatch(resetAllPagesAccess());
    dispatch(resetSearchState());
    dispatch(resetPaymentState());
    history.push(routes.home);
  };

  const bookHotel = () => {
    const bookingUrl = 'https://www.booking.com/index.html?aid=7955215';
    window.open(bookingUrl, '_blank');
  };
  const hasExternalPNRsList = !!externalPNRs?.length;

  return (
    <>
      {hasExternalPNRsList && (
        <BoxSpaceBetween sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle">{externalPNRs[0].airlineName} PNR:</Typography>
          <Typography variant="lineHeightLarge">{externalPNRs[0].externalPNR}</Typography>
        </BoxSpaceBetween>
      )}
      <BoxSpaceBetween sx={{ alignItems: 'center' }}>
        <Typography variant="sectionTitle">Fasax Confirmation Code:</Typography>
        <Typography variant="lineHeightLarge">{fasaxPNR}</Typography>
      </BoxSpaceBetween>
      <ContentWrapper>
        <ItinerariesDetailed
          journeyOffer={journeyOption as JourneyOfferModel}
          cabin={capitalizeWord(cabin)}
          price={`${priceQuote?.currency} ${priceQuote?.amount}`}
          hasFooterButton={false}
        />

        <PaperItemWrapper>
          {passengers.map((passenger, index) => {
            return (
              <MarginTop marginTop={index >= 1 ? '24' : '0'}>
                <PassengerListItem
                  passengerCount={index + 1}
                  isBackButton={false}
                  firstName={passenger.firstName}
                  lastName={passenger.lastName}
                  pnrEmail={pnrEmail}
                  dateOfBirth={passenger.dateOfBirth}
                  passengerType={passenger.passengerType}
                />
              </MarginTop>
            );
          })}
        </PaperItemWrapper>

        <PaymentBlock />
      </ContentWrapper>
      <ButtonsBlock>
        <Button variant="contained" onClick={onNewSearch} size="large" fullWidth>
          Search more flights
        </Button>
        <Button onClick={bookHotel} size="large" fullWidth variant="outlined">
          Book hotel
        </Button>
      </ButtonsBlock>
    </>
  );
};
