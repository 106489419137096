import { styled, Typography } from '@mui/material';
import { colors } from 'style';

export const SegmentDetailedFooter = styled('div')`
  padding: 24px;
  display: flex;
  justify-content: space-between;
`;

export const TotalSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const TypographyTicketClass = styled(Typography)`
  font-size: 14px;
  line-height: 14px;
  color: ${colors.gray60};
`;

export const TypographyTotal = styled(Typography)`
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
`;
