import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { setPaymentPassengerInfo } from 'redux/slices/payment/payment-slice';
import { setBookingPassengersValues } from 'redux/slices/booking/booking-slice';
import { useDispatch } from 'react-redux';
import { JOURNEY_OFFERS, JOURNEY_OFFERS_data, JOURNEY_OFFERS_vars } from 'api/queries/journeyOffers';
import { useGetSearchOptionsForRequest } from './useGetSearchOptionsForRequest';

export const useGetRequestOptionsWithParams = () => {
  /** Hooks **/
  const [foundedOptions, setFoundedOptions] = useState<JOURNEY_OFFERS_data | null>(null);
  const dispatch = useDispatch();
  const searchOptions = useGetSearchOptionsForRequest();

  /** Requests **/
  const [getFlightOptionsRequest, { loading }] = useLazyQuery<JOURNEY_OFFERS_data, JOURNEY_OFFERS_vars>(JOURNEY_OFFERS, {
    fetchPolicy: 'no-cache',
  });

  const getRequestOptions = async () => {
    await getFlightOptionsRequest({
      variables: searchOptions,
      onCompleted: (data) => {
        // Transform the response data and save it in state.
        // In some cases we can get array of founded items and last elem is error because the back end
        // grab the data from few services, and we handle to avoid errors in our data result
        const transformedData = data?.journeyOffers?.filter((offer) => {
          return offer.__typename === 'JourneyOffer';
        });
        const result = {
          journeyOffers: transformedData || [],
        };
        setFoundedOptions(result);
      },
    });
  };

  /** UseEffects **/
  useEffect(() => {
    getRequestOptions();
    dispatch(setBookingPassengersValues([]));
    dispatch(setPaymentPassengerInfo(null));
  }, []);

  return {
    data: foundedOptions,
    isLoading: loading,
    refetch: getRequestOptions,
  };
};
