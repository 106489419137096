import * as yup from 'yup';
import 'yup-phone';
import { regex } from 'validation/regex';
import { PaymentNetwork } from 'enum/enum';
import { errorMessages } from './errorMessages';

export const definePaymentValidation = (isMpesaExpress: boolean, isVoucher: boolean) => {
  if (isMpesaExpress) {
    return {
      regex: regex.mPesaExpressPaymentCountryCode,
      errorMessage: errorMessages.mPesaExpressPaymentCountryCode,
    };
  }
  if (isVoucher) {
    return {
      regex: regex.voucher,
      errorMessage: errorMessages.voucher,
    };
  }
  return {
    regex: regex.waafiPaymentCountryCode,
    errorMessage: errorMessages.waafiPaymentCountryCode,
  };
};

export const validation = {
  email: yup.string().email(errorMessages.email).required(errorMessages.required),
  emailNotRequired: yup.string().email(errorMessages.email).notRequired(),
  boolean: yup.boolean(),
  number: yup.number().typeError(errorMessages.number).required(errorMessages.required),
  stringNotRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .notRequired(),
  stringOnlyLatinCharactersNotRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .matches(regex.latinChars, errorMessages.latinCharsOnly)
    .notRequired(),
  string: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required),
  stringOnlyLatinCharacters: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .matches(regex.latinChars, errorMessages.latinCharsOnly)
    .required(errorMessages.required),

  stringWithoutNumbers: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .matches(regex.includesNumbers, errorMessages.shouldNotContainerNumbers),

  stringWithoutNumbersNotRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .matches(regex.includesNumbers, errorMessages.shouldNotContainerNumbers)
    .notRequired(),
  numbersAndLatinCharactersRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .matches(regex.numbersAndCharacters, errorMessages.numbersAndLatinCharacters),
  confirmationCode: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .max(6, errorMessages.lengthCharacters6)
    .required(errorMessages.required)
    .matches(regex.numbersAndCharacters, errorMessages.confirmationCode),
  date: yup
    .date()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required),

  dateNotRequired: yup
    .date()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .nullable()
    .notRequired(),

  phoneNumber: () => yup.string().required(errorMessages.phoneRequired).nullable().phone(undefined, false, errorMessages.phoneInvalid),

  phoneNumberWaafi: yup.string().required(errorMessages.phoneRequired).nullable().phone('+252', false, errorMessages.phoneInvalid),

  paymentPhoneNumber: (network: PaymentNetwork) => {
    const isMpesaExpress = network === PaymentNetwork.M_PESA_EXPRESS;
    const isVoucher = network === PaymentNetwork.VOUCHER;

    return isVoucher
      ? yup
          .string()
          .matches(
            new RegExp(definePaymentValidation(isMpesaExpress, isVoucher).regex),
            definePaymentValidation(isMpesaExpress, isVoucher).errorMessage,
          )
          .required(isVoucher ? errorMessages.required : errorMessages.phoneRequired)
      : yup
          .string()
          .matches(
            new RegExp(definePaymentValidation(isMpesaExpress, isVoucher).regex),
            definePaymentValidation(isMpesaExpress, isVoucher).errorMessage,
          )
          .required(isVoucher ? errorMessages.required : errorMessages.phoneRequired)
          .nullable()
          .phone(undefined, false, errorMessages.phoneInvalid);
  },
};
