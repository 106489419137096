import React from 'react';
import { Box, Button } from '@mui/material';
import { ButtonsBlock, CardWrapper, CardContent } from './styles';
import { MarginTop, Typography } from 'components';

type PropsType = {
  title: string;
  firstButtonTitle?: string;
  subtitle?: string;
  secondButtonTitle?: string;
  hideFirstButton?: boolean;
  hideSecondButton?: boolean;
  disableFirstButton?: boolean;
  disableSecondButton?: boolean;
  children?: React.ReactNode;
  firstButtonFc?: () => void;
  secondButtonFc?: () => void;
};

export const Card = React.memo((props: PropsType) => {
  return (
    <CardWrapper>
      <CardContent>
        <Box>
          <Typography variant="title">{props.title}</Typography>
          <MarginTop marginTop="12">
            <Typography variant="smallTitle">{props.subtitle}</Typography>
          </MarginTop>
        </Box>
        {props.children}
        <ButtonsBlock>
          {!props.hideFirstButton && (
            <Button disabled={props.disableFirstButton} variant="outlined" onClick={props.firstButtonFc}>
              {props.firstButtonTitle}
            </Button>
          )}
          {!props.hideSecondButton && (
            <Button disabled={props.disableSecondButton} variant="contained" onClick={props.secondButtonFc}>
              {props.secondButtonTitle}
            </Button>
          )}
        </ButtonsBlock>
      </CardContent>
    </CardWrapper>
  );
});
