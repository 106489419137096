import { useQuery } from '@apollo/client';
import { useAppSelector } from 'hooks/useAppSelector';
import { SERVICE_LIST_data, SERVICE_LIST_vars, SERVICES_LIST } from 'api/queries/servicesList';
import { Currency } from 'enum/enum';
import { SegmentServices, ServiceListItem, ServiceModel } from 'models/service.model';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { PassengerGroupedServicesBySegments, PassengerModelWithPax } from 'models/passenger.model';

const hasServices = (data: ServiceListItem[]) => {
  return data.some((item) => item.services.length > 0);
};

const getServicesAllTrip = (servicesData: ServiceListItem[]) => {
  const servicesWithEmptySegRef: { [key: string]: ServiceModel[] } = {};
  for (let i = 0; i < servicesData.length; i++) {
    for (const service of servicesData[i].services) {
      if (service.segRef.length === 0) {
        const serviceName = servicesData[i].name;
        if (!servicesWithEmptySegRef[serviceName]) {
          servicesWithEmptySegRef[serviceName] = [];
        }
        servicesWithEmptySegRef[serviceName].push(service);
      }
    }
  }

  const servicesList = Object.entries(servicesWithEmptySegRef).map(([name, services]) => ({ name, services }));
  return {
    destination: 'All Trip',
    serviceList: servicesList,
  };
};

const transformDataServiceListAddDestination = (serviceDataList: ServiceListItem[], destinationKey: string, segmentName: string) => {
  const segmentServices: SegmentServices = {
    destination: segmentName,
    serviceList: serviceDataList
      .map((item) => {
        const filteredServices = item.services.filter((service) => service.segRef.includes(destinationKey));
        return filteredServices.length > 0
          ? {
              name: item.name,
              services: filteredServices,
            }
          : null;
      })
      .filter((item) => item !== null) as ServiceListItem[],
  };
  return segmentServices;
};

const getServicesWithSegment = (serviceList: ServiceListItem[], journeyOption: JourneyOfferModel) => {
  const segmentsWithServices: SegmentServices[] = [];
  journeyOption.itineraries.forEach((itinerary) => {
    itinerary.segments.forEach((segment) => {
      const segmentName = `${segment.originAirport.code} - ${segment.destinationAirport.code}`;
      const itemWithDestination = transformDataServiceListAddDestination(serviceList, segment.id, segmentName);
      if (itemWithDestination.serviceList.length) {
        segmentsWithServices.push(itemWithDestination);
      }
    });
  });
  return segmentsWithServices;
};

const getServicesWithRef = (servicesData: ServiceListItem[], journeyOption: JourneyOfferModel) => {
  const servicesListWithRef: { [key: string]: ServiceModel[] } = {};
  for (let i = 0; i < servicesData.length; i++) {
    for (const service of servicesData[i].services) {
      if (service.segRef.length !== 0) {
        const serviceName = servicesData[i].name;
        if (!servicesListWithRef[serviceName]) {
          servicesListWithRef[serviceName] = [];
        }
        servicesListWithRef[serviceName].push(service);
      }
    }
  }
  const servicesListWithCategory = Object.entries(servicesListWithRef).map(([name, services]) => ({ name, services }));
  return getServicesWithSegment(servicesListWithCategory, journeyOption);
};

const filterServicesByPassenger = (servicesData: ServiceListItem[], passenger: PassengerModelWithPax) => {
  const filteredServices: ServiceListItem[] = [];
  servicesData.forEach((item) => {
    const servicesForPAX = item.services.filter((serviceItem) => serviceItem.paxRef === passenger.paxId);
    if (servicesForPAX.length > 0) {
      filteredServices.push({
        name: item.name,
        services: servicesForPAX,
      });
    }
  });
  return filteredServices;
};

const groupSegmentsWithServices = (journeyOption: JourneyOfferModel, servicesData: ServiceListItem[]): SegmentServices[] => {
  const segmentsWithServices: SegmentServices[] = [getServicesAllTrip(servicesData), ...getServicesWithRef(servicesData, journeyOption)];
  return segmentsWithServices;
};

export const useGetUserInfoWithServices = () => {
  const { journeyOption } = useAppSelector((state) => state.journeyOption);
  const { currency } = useAppSelector((state) => state.payment);
  const priceQuote = useAppSelector((state) => state.priceOffer.priceOffer?.priceQuote);
  const passengersList = useAppSelector((state) => state.booking.passengers);
  const paxIdsArr = useAppSelector((state) => state.priceOffer?.priceOffer?.ticketingDetails?.paxPricings);

  const { loading, data } = useQuery<SERVICE_LIST_data, SERVICE_LIST_vars>(SERVICES_LIST, {
    variables: { journeyUUID: journeyOption?.id as string, currency: Currency.USD },
  });
  const servicesData = data?.serviceList ?? [];
  const services = servicesData.map((item) => item.services).reduce((acc, services) => acc.concat(services), []);
  const flightPrice = priceQuote?.amount ?? 0;

  const passengersWithGroupedServices: PassengerGroupedServicesBySegments[] = passengersList.map((p, i) => {
    const passengerWithPaxId: PassengerModelWithPax = { ...p, paxId: paxIdsArr?.[i].paxId ?? '' };
    const filteredServicesByPassenger = filterServicesByPassenger(servicesData, passengerWithPaxId);
    const groupedServicesBySegments = groupSegmentsWithServices(journeyOption as JourneyOfferModel, filteredServicesByPassenger);
    return {
      ...passengerWithPaxId,
      servicesBySegment: groupedServicesBySegments,
      hasServices: hasServices(filteredServicesByPassenger),
    };
  });

  return {
    loading,
    flightPrice,
    journeyUUID: journeyOption?.id,
    passengersWithGroupedServices: passengersWithGroupedServices,
    currency: currency ?? Currency.USD,
    services,
  };
};
