import React from 'react';
import { ContainerStyled } from 'style';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Hero } from './Hero';
import { Story } from './Story';
import { WhoWeAre } from './WhoWeAre';
import { Team } from './Team';
import { Partners } from './Partners';
import { Contact } from './Contact';
import { StyledBoxContainer, StyledBoxRow, StyledBoxWrapper } from './components';

export const AboutPageComponents = () => {
  return (
    <ContainerStyled>
      <Hero />
      <StyledBoxContainer>
        <Story />
      </StyledBoxContainer>
      <StyledBoxRow>
        <WhoWeAre />
      </StyledBoxRow>
      <StyledBoxWrapper>
        <Divider />
      </StyledBoxWrapper>
      <StyledBoxContainer>
        <Team />
      </StyledBoxContainer>
      <Box bgcolor={'alternate.main'}>
        <StyledBoxContainer>
          <Partners />
        </StyledBoxContainer>
      </Box>
      <Contact />
    </ContainerStyled>
  );
};
