import React from 'react';
import { Header } from 'components';
import { Typography } from '@mui/material';
import { colors } from 'style';
import { Blog } from 'components/pages/blog';

export const BlogPage = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      Blog
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <Blog />
    </>
  );
};
