import { PassengerModel } from 'models/passenger.model';

export const findErrorElement = (fieldPath: string): HTMLElement | null => {
  return document.querySelector(`input[name="${fieldPath}"]`) as HTMLElement | null;
};

export const focusOnFirstInvalidField = (errorField: HTMLElement) => {
  errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
  errorField.focus();
};

export const handlePassengerErrors = (passengerErrors: any[]) => {
  const passengerFieldOrder: (keyof PassengerModel)[] = [
    'firstName',
    'middleName',
    'lastName',
    'gender',
    'birthYear',
    'birthMonth',
    'birthDay',
    'nationality',
    'passportNumber',
  ];

  for (let i = 0; i < passengerErrors.length; i++) {
    const passengerError = passengerErrors[i];

    if (passengerError) {
      for (const nestedField of passengerFieldOrder) {
        if (passengerError[nestedField]) {
          const errorElement = findErrorElement(`passengers.${i}.${nestedField}`);
          if (errorElement) {
            focusOnFirstInvalidField(errorElement);
            return;
          }
        }
      }
    }
  }
};
