import React from 'react';
import 'firebase/auth';
import { LoginContained } from './styles';
import { SignInContent } from './SignInContent/SignInContent';

export const Login = () => {
  return (
    <LoginContained>
      <SignInContent />
    </LoginContained>
  );
};
