import {
  AddRounded,
  AirlineSeatFlatRounded,
  AirlineSeatLegroomExtraRounded,
  ChangeCircleRounded,
  Coffee,
  EmailRounded,
  FastForwardRounded,
  FaxRounded,
  LiquorRounded,
  LiveTvRounded,
  MovieRounded,
  MusicNoteRounded,
  OndemandVideoRounded,
  PhoneInTalkRounded,
  PowerRounded,
  RemoveShoppingCartRounded,
  RestaurantRounded,
  ShoppingCartRounded,
  SmokeFreeRounded,
  SmokingRoomsRounded,
  SportsEsportsRounded,
  SupportAgentRounded,
  TapasRounded,
  UsbRounded,
  WifiRounded,
} from '@mui/icons-material';
import React from 'react';
import { AmenityIconType } from 'components/ItinerariesDetailed/Amenities/Amenities';

export const amenityKeyPairs: Partial<AmenityIconType> = {
  AC_POWER_110V: <PowerRounded fontSize="small" />,
  AC_POWER_110V_BUSINESS: <PowerRounded fontSize="small" />,
  AC_POWER_110V_ECONOMY: <PowerRounded fontSize="small" />,
  AC_POWER_110V_FIRST: <PowerRounded fontSize="small" />,
  AC_POWER_110V_PREMIUM_ECO: <PowerRounded fontSize="small" />,
  ADDITIONAL_SERVICES: <AddRounded fontSize="small" />,
  AMENITIES_SUBJECT_TO_CHANGE: <ChangeCircleRounded fontSize="small" />,
  AUDIO_PROGRAMMING: <MusicNoteRounded fontSize="small" />,
  BEVERAGE_SERVICE: <Coffee fontSize="small" />,
  DUTY_FREE_SALES: <ShoppingCartRounded fontSize="small" />,
  EMAIL: <EmailRounded fontSize="small" />,
  EXTRA_LEGROOM: <AirlineSeatLegroomExtraRounded fontSize="small" />,
  IN_SEAT_POWER_SOURCE: <PowerRounded fontSize="small" />,
  IN_SEAT_VIDEO_PLAYER_LIBRARY: <OndemandVideoRounded fontSize="small" />,
  INTERNET_ACCESS: <WifiRounded fontSize="small" />,
  LIE_FLAT_SEAT_BUSINESS: <AirlineSeatFlatRounded fontSize="small" />,
  LIE_FLAT_SEAT_FIRST: <AirlineSeatFlatRounded fontSize="small" />,
  LIE_FLAT_SEAT_PREMIUM_ECO: <AirlineSeatFlatRounded fontSize="small" />,
  LIE_FLAT_SEATS: <AirlineSeatFlatRounded fontSize="small" />,
  LIVE_TV: <LiveTvRounded fontSize="small" />,
  MEAL_SERVICE: <RestaurantRounded fontSize="small" />,
  MOVIE: <MovieRounded fontSize="small" />,
  NON_SMOKING: <SmokeFreeRounded fontSize="small" />,
  NO_DUTY_FREE_SALES: <RemoveShoppingCartRounded fontSize="small" />,
  PERSONAL_INFLIGHT_ENTERTAINMENT: <SportsEsportsRounded fontSize="small" />,
  POWER_OUTLETS: <PowerRounded fontSize="small" />,
  PREMIUM_BEVERAGE_SERVICE: <LiquorRounded fontSize="small" />,
  PRIORITY_SERVICE: <FastForwardRounded fontSize="small" />,
  RESERVATION_BOOKING_SERVICE: <SupportAgentRounded fontSize="small" />,
  SHORT_FEATURE_VIDEO: <MovieRounded fontSize="small" />,
  SMOKING: <SmokingRoomsRounded fontSize="small" />,
  SNACK_SERVICE: <TapasRounded fontSize="small" />,
  TELEPHONE: <PhoneInTalkRounded fontSize="small" />,
  TELEX: <FaxRounded fontSize="small" />,
  USB_POWER: <UsbRounded fontSize="small" />,
  USB_POWER_BUSINESS: <UsbRounded fontSize="small" />,
  USB_POWER_ECONOMY: <UsbRounded fontSize="small" />,
  USB_POWER_FIRST: <UsbRounded fontSize="small" />,
  USB_POWER_PREMIUM_ECO: <UsbRounded fontSize="small" />,
  WIFI: <WifiRounded fontSize="small" />,
};
