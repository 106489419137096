import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const LabelContainer = styled('div')`
  margin: 12px 0;
`;
