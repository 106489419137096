import React from 'react';
import { SegmentModel } from 'models/segment.model';
import { SegmentDetailedItem } from 'components/ItinerariesDetailed/SegmentDetailed/SegmentDetailedItem';
import { SegmentsList } from 'components/ItinerariesDetailed/SegmentDetailed/styles';
import { BaggageAllowanceModel } from 'models/baggage.model';

interface SegmentDetailedListProps {
  segments: SegmentModel[];
  startDepartureDate: Date;
  baggageAllowance?: BaggageAllowanceModel | null;
}

export const SegmentDetailedList = ({ segments, baggageAllowance, startDepartureDate }: SegmentDetailedListProps) => {
  return (
    <SegmentsList>
      {segments?.map((s) => {
        return <SegmentDetailedItem key={s.id} {...s} baggageAllowance={baggageAllowance} startDepartureDate={startDepartureDate} />;
      })}
    </SegmentsList>
  );
};
