import { Box, styled } from '@mui/material';
import { colors } from 'style';
import { Typography } from 'components/kit/Typography';

export const StyledTypographyHeader = styled(Typography)`
  color: ${colors.yellow};
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
`;

StyledTypographyHeader.defaultProps = {
  variant: 'title',
};

export const StyledBoxContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  @media (min-width: ${(props) => props.theme.breakpoints.values.xs}px) {
    padding-top: ${(props) => props.theme.spacing(4)};
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    max-width: 720px;
    padding-top: ${(props) => props.theme.spacing(6)};
    padding-bottom: ${(props) => props.theme.spacing(6)};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    max-width: 1236px;
    padding-top: ${(props) => props.theme.spacing(8)};
    padding-bottom: ${(props) => props.theme.spacing(8)};
  }
`;

export const StyledBoxRow = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  @media (min-width: ${(props) => props.theme.breakpoints.values.xs}px) {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    max-width: 720px;
    padding-bottom: ${(props) => props.theme.spacing(6)};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    max-width: 1236px;
    padding-bottom: ${(props) => props.theme.spacing(8)};
  }
`;

export const StyledBoxWrapper = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    max-width: 720px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    max-width: 1236px;
  }
`;
