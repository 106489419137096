import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select as SelectMui, SelectProps } from '@mui/material';
import { Error } from './styles';

interface IOption {
  title: string;
  value: string | number;
}

export interface SelectType extends Omit<SelectProps, 'name' | 'error' | 'placeholder' | 'autoFocus'> {
  control: Control<any>;
  name: string;
  options: IOption[];
  error?: FieldError;
  onChangeSelect?: () => void;
  placeholder?: string;
  isCenterAutofocus?: boolean;
}

export const Select = ({ control, name, options, error, onChangeSelect, placeholder, isCenterAutofocus, ...props }: SelectType) => {
  return (
    <FormControl fullWidth>
      {props.label && <InputLabel htmlFor={name}>{props.label}</InputLabel>}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...other } }) => {
          return (
            <>
              <SelectMui
                {...other}
                {...props}
                labelId={name}
                itemID={name}
                error={!!error}
                value={value}
                onChange={(e) => {
                  if (onChangeSelect) onChangeSelect();
                  onChange(e);
                }}
                displayEmpty
              >
                {options.map(({ title, value }) => {
                  return (
                    <MenuItem
                      key={title}
                      value={value}
                      autoFocus={isCenterAutofocus && options[options.length / 2] && options[options.length / 2].value === value}
                    >
                      {title}
                    </MenuItem>
                  );
                })}
              </SelectMui>
              {error && <Error>{error?.message}</Error>}
            </>
          );
        }}
      />
    </FormControl>
  );
};
