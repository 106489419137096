import React from 'react';
import { StyledTypographyHeader } from 'components/pages/about/components';
import { Header } from 'components';
import { ServicesList } from 'components/pages/services';
import { ContainerStyled } from 'style';
import { SelectedIdsProvider } from 'components/pages/services/useSelectServicesContext';

export const ServicesPage = () => {
  const headerContent = <StyledTypographyHeader variant="title">Services</StyledTypographyHeader>;
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <SelectedIdsProvider>
          <ServicesList />
        </SelectedIdsProvider>
      </ContainerStyled>
    </>
  );
};
