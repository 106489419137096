import { Currency } from 'enum/enum';
import { PriceModel } from 'models/price.model';

export const getAmountCurrency = (currencyPayment: PriceModel) => {
  const formattedKesPrice = new Intl.NumberFormat('en-KE', {
    style: 'currency',
    currency: Currency.KES,
  }).format(+currencyPayment?.amount);

  const formattedUsdPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: Currency.USD,
  }).format(+currencyPayment?.amount);

  const currencies = {
    USD: formattedUsdPrice,
    KES: formattedKesPrice,
  };
  return currencies[currencyPayment?.currency as keyof typeof currencies];
};
