import { Box, Button, styled } from '@mui/material';
import { colors } from 'style';

export const TabsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 30px;
  height: 47px;
`;
export const ButtonTab = styled(Button)<{ active: boolean }>`
  padding: 12px;
  width: 100%;
  color: ${(props) => (props.active ? colors.yellow : colors.primary)};
  background-color: ${(props) => (props.active ? colors.primary : colors.white)};
  border-radius: 0;
  text-transform: none;
  min-height: max-content;
  border-top: 2px solid ${colors.primary};
  border-bottom: 2px solid ${colors.primary};

  &:hover {
    background-color: ${(props) => (props.active ? colors.primary : '')};
  }

  &:first-of-type {
    border-left: 2px solid ${colors.primary};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-right: 2px solid ${colors.primary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
