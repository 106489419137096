import React from 'react';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { ConnectionDetailsModel, ConnectionFlagsWithDuration } from 'models/segment.model';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { parseISO8601Time } from 'utils/dates';
import { regex } from 'validation/regex';

const getTitleDetailed = (type: string, connectionDetails: ConnectionDetailsModel) => {
  if (!connectionDetails?.duration) {
    return `Layover at ${getShortNameAirport(connectionDetails?.departureAirport?.name)}`;
  }
  const { duration, departureAirport } = connectionDetails;
  const durationTime = parseISO8601Time(duration);
  return `Layover ${durationTime} at ${getShortNameAirport(departureAirport?.name)}`;
};

export const getShortNameAirport = (name: string) => {
  return name?.replace(regex.excludedAirportWithInternationalWords, '') ?? '';
};

export interface ConnectionDetailType {
  icon: React.ReactNode;
  title: string;
}

export const getConnectionDetailItemByKey = (type: string, durationTitle?: string) => {
  return {
    changeOfAirport: {
      icon: <AirportShuttleIcon fontSize="small" />,
      title: 'Airport Change Required',
    },
    changeOfTerminal: {
      icon: <TransferWithinAStationIcon fontSize="small" />,
      title: 'Terminal Change Needed',
    },
    connectToCodeshare: {
      icon: <SwapHorizRoundedIcon fontSize="small" />,
      title: 'Codeshare Connection',
    },
    customsRequired: {
      icon: <FactCheckIcon fontSize="small" />,
      title: 'Customs Inspection Needed',
    },
    overnightStay: {
      icon: <Brightness3Icon fontSize="small" />,
      title: 'Overnight Stay',
    },
    duration: {
      icon: <AccessTimeIcon fontSize="small" />,
      title: durationTitle ?? '',
    },
  }[type];
};
export const useGetInformationDetails = (connectionDetails: ConnectionDetailsModel) => {
  const connectionDetailsFlagsWithDuration = {
    ...(connectionDetails?.duration && { duration: connectionDetails.duration }),
    ...connectionDetails?.connectionFlags,
  };

  const newArr: ConnectionDetailType[] = [];
  for (const key in connectionDetailsFlagsWithDuration) {
    if (key in connectionDetailsFlagsWithDuration) {
      const connectionItemValue = connectionDetailsFlagsWithDuration[key as keyof ConnectionFlagsWithDuration];
      const connectionDetailTitle = getTitleDetailed(key, connectionDetails);
      const newConnectionDetailItem = getConnectionDetailItemByKey(key, connectionDetailTitle) as ConnectionDetailType;
      const isExistConnectionItem = getConnectionDetailItemByKey(key) !== null && getConnectionDetailItemByKey(key) !== undefined; // value can be: true, false or string only
      if (isExistConnectionItem && connectionItemValue) {
        newArr.push(newConnectionDetailItem);
      }
    }
  }

  return {
    hasConnectionDetailsItems: newArr.length === 0,
    formedArrayConnectionDetails: newArr,
  };
};
