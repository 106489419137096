import * as yup from 'yup';
import { array, object } from 'yup';
import { validation } from 'validation/validation';
import { errorMessages } from 'validation/errorMessages';

const autocompleteOptionShape = {
  title: validation.string,
  value: validation.string,
};
const getPassengerShape = (isDauus: boolean) => {
  return {
    birthDay: validation.string,
    birthMonth: validation.string,
    birthYear: validation.string,
    dateOfBirth: validation.stringNotRequired,
    firstName: validation.stringOnlyLatinCharacters,
    middleName: validation.stringOnlyLatinCharactersNotRequired,
    lastName: validation.stringOnlyLatinCharacters,
    nationality: object().shape(autocompleteOptionShape).typeError(errorMessages.required),
    passengerType: validation.stringNotRequired,
    passportNumber: isDauus ? validation.stringNotRequired : validation.numbersAndLatinCharactersRequired,
    gender: object().shape(autocompleteOptionShape).typeError(errorMessages.required),
  };
};

export const schema = (isDauus: boolean) => {
  return yup.object().shape({
    passengers: array().of(object().shape(getPassengerShape(isDauus))),
    pnrPhoneNumber: validation.phoneNumber(),
    pnrEmail: validation.email,
  });
};
