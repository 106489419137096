import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Typography } from 'components';
import { AmenityModel } from 'models/amenity.model';
import { AccordionDetailsStyled, AccordionStyled, AccordionSummaryStyled, AmenityItem } from './styles';
import { getUniqueIcons } from './utils';
import { amenityKeyPairs } from './constants';

interface AmenitiesProps {
  amenities: AmenityModel[];
}

export type AmenityIconType = {
  [key in AmenityModel['amenity']]: React.ReactNode;
};

export const Amenities = ({ amenities }: AmenitiesProps) => {
  const amenityTitles = amenities.map((a) => a.amenity);
  const allAmenitiesLength = amenities?.length || 0;
  const uniqueIcons = getUniqueIcons(amenityTitles, amenityKeyPairs);

  if (!amenityTitles.length) return <></>;

  return (
    <AccordionStyled disableGutters>
      {allAmenitiesLength > 1 && <AccordionSummaryStyled expandIcon={<ExpandMore />}>{uniqueIcons}</AccordionSummaryStyled>}
      <AccordionDetailsStyled>
        {amenities.map((am) => {
          return (
            <AmenityItem key={am.amenity}>
              {amenityKeyPairs[am.amenity]}
              <Typography variant={'body'}>{am.description || 'The option is available on the flight'}</Typography>
            </AmenityItem>
          );
        })}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
