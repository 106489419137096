import { Box, IconButton, styled } from '@mui/material';
import { breakpoints, colors } from 'style';
import { modalHeader } from 'style/vars/constants';

export const ModalHeader = styled(Box)`
  background: ${colors.primary};
  position: relative;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: ${modalHeader}px;
  color: ${colors.white};

  svg {
    fill: ${colors.white};
  }
`;

export const ModalContent = styled(Box)`
  border: none;
  padding: 24px;
  background: ${colors.white};
  @media (max-width: ${breakpoints.sm}) {
    padding: 8px;
  }
`;

export const ModalStyled = styled(Box)<{ showModalHeaderWithBackground?: boolean; width?: string }>`
  position: absolute;
  overflow-y: auto;
  max-height: 100%;
  height: max-content;
  width: 80%;
  ${(props) => props.width && `max-width: ${props.width}px`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  ${(props) => !props.showModalHeaderWithBackground && `display: none`};
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 6px;
`;
