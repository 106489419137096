import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlightOrderModel } from 'models/flightOrder.model';

const initialState: FlightOrderModel = {
  flightOrderId: null,
  paymentState: null,
  fasaxPNR: null,
  externalPNRs: [],
  associatedPayments: [],
  orderPaymentStatus: null,
  journeys: [],
  passengerCount: null,
  passengers: [],
  pnrPhoneNumber: null,
  pnrEmail: null,
  priceOrderKes: null,
  priceOrderUsd: null,
};

export const flightOrderSlice = createSlice({
  name: 'flightOrder',
  initialState,
  reducers: {
    setFlightOrderDetails(state, action: PayloadAction<FlightOrderModel>) {
      return (state = action.payload);
    },
    setSuccessfulOrder(state, action) {
      state.orderPaymentStatus = action.payload.orderPaymentStatus;
      state.associatedPayments = action.payload.associatedPayments;
      state.journeys = action.payload.journeys;
    },
    resetFlightOrderState(state) {
      state = initialState;
    },
  },
});
export const { setFlightOrderDetails, setSuccessfulOrder, resetFlightOrderState } = flightOrderSlice.actions;
