export const regex = {
  parsePhoneNumber: (phoneNumber: string) => {
    return phoneNumber.replace(/[-()\s/]/g, '');
  },
  includesNumbers: /^[^0-9]*$/,
  latinChars: /^[a-zA-Z]+$/,
  numbersAndCharacters: /^[aA-zZ0-9\s]+$/,
  mPesaExpressPaymentCountryCode: `^\\+254`,
  waafiPaymentCountryCode: `^\\+252`,
  excludedAirportWithInternationalWords: /\b(airport|international)\b/gi,
  extractHoursWithMinutesByISO: /P(?:([\d.]*)Y)?(?:([\d.]*)M)?(?:([\d.]*)D)?(?:T(?:([\d.]*)H)?(?:([\d.]*)M)?(?:([\d.]*)S)?)?/,
  refcode: /^[a-zA-Z0-9]+$/,
  voucher: /^[a-zA-Z0-9_-]+$/,
};
