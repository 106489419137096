import React from 'react';
import { ConnectionDetailItem } from './styles';
import { ConnectionDetailType } from '../SegmentDetailed/helper';

interface ConnectionDetailProps {
  connectionDetails: ConnectionDetailType[];
}

export const ConnectionDetails: React.FC<ConnectionDetailProps> = ({ connectionDetails }) => {
  return (
    <>
      {connectionDetails.map(({ title, icon }, index) => (
        <ConnectionDetailItem key={index}>
          {icon} {title}
        </ConnectionDetailItem>
      ))}
    </>
  );
};
