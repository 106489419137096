import React from 'react';
import { ItineraryModel } from 'models/itinerary.model';
import { Itinerary } from 'components/Itinerary';
import { ItinerariesListWrapper } from './styles';
import { AirlineInfoModel } from 'models/airline.model';

interface ItinerariesListProps {
  items: ItineraryModel[];
  operationAirlines: AirlineInfoModel[];
}

export const ItinerariesList = ({ items, operationAirlines }: ItinerariesListProps) => {
  return (
    <ItinerariesListWrapper>
      {items?.map((itinerary) => {
        return (
          <React.Fragment key={itinerary.id}>
            <Itinerary
              operationAirlines={operationAirlines}
              segments={itinerary.segments}
              totalTripDuration={itinerary.duration}
              changeOfDate={itinerary.changeOfDate}
            />
          </React.Fragment>
        );
      })}
    </ItinerariesListWrapper>
  );
};
