import { gql } from '@apollo/client';

export interface USER_LOGIN_data {
  loggedIn: boolean;
}

export interface USER_LOGIN_vars {
  jwtToken: string;
}

export const USER_LOGIN = gql`
  query userLogin($jwtToken: JwtToken!) {
    userLogin(jwtToken: $jwtToken) {
      loggedIn
    }
  }
`;
