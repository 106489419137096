import { styled } from '@mui/material';

export const Title = styled('div')`
  margin-top: 32px;
  text-align: center;
`;

export const NotificationRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;
