import { FormHelperText } from '@mui/material';
import { Select } from '@mui/material';

import styled from '@emotion/styled';
import { breakpoints } from 'style/vars/breakpoints';

export const Error = styled(FormHelperText)`
  color: red;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: max-content;
`;

export const MuiSelect = styled(Select)`
  .MuiSelect-select {
    width: 150px;
    padding: 6px 0 10px 6px;
    background-color: white;
    @media (max-width: ${breakpoints.sm}) {
      width: 110px;
    }
  }

  &:before {
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    bottom: 3px;
  }

  &:after,
  &:focus,
  &::selection {
    border-bottom: none;
    background-color: unset;
  }
`;
