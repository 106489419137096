import React from 'react';
import { Typography, Box } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FooterContainer, FooterLinks, LinkFooterStyled } from './styles';

const footerLinks = [
  { link: 'https://www.facebook.com/fasaxtravel', icon: <FacebookOutlinedIcon color="secondary" /> },
  { link: 'https://www.instagram.com/fasaxtravel', icon: <InstagramIcon color="secondary" /> },
  { link: 'https://wa.me/19165532729', icon: <WhatsAppIcon color="secondary" /> },
  { link: 'https://twitter.com/FasaxTravel', icon: <TwitterIcon color="secondary" /> },
  { link: 'tel:+252633057433', icon: <PhoneIcon color="secondary" /> },
  { link: 'mailto:support@fasax.com', icon: <AlternateEmailIcon color="secondary" /> },
];
export const Footer = () => {
  return (
    <FooterContainer color="white" justify-items="center" align-items="center">
      <FooterLinks>
        {footerLinks.map(({ link, icon }) => {
          return (
            <LinkFooterStyled key={link} href={link} target={'_blank'}>
              {icon}
            </LinkFooterStyled>
          );
        })}
      </FooterLinks>

      <Box padding="5px" textAlign="center">
        <Typography variant="h6" component="h2" color="secondary" fontWeight={600} fontSize={13} lineHeight={'16px'}>
          Fasax Travel Services &reg; {new Date().getFullYear()}
        </Typography>
      </Box>
    </FooterContainer>
  );
};
