import { getCardTypes } from 'constants/payments';
import { PaymentMode } from 'enum/enum';
import voucher from 'assets/img/voucher.png';
import stripe from 'assets/img/stripe.png';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { isDauusDomain } from 'utils/dauus';

export const getPaymentCard = (payment: AssociatedPaymentModel) => {
  const includeVoucher = isDauusDomain();
  const foundCard = getCardTypes(includeVoucher)?.filter((el) => el?.cardBrand === payment?.cardBrand)[0];
  if (foundCard) return foundCard;
  if (payment?.paymentMode === PaymentMode.VOUCHER) {
    return {
      cardBrand: PaymentMode.VOUCHER,
      img: voucher,
    };
  }
  return {
    cardBrand: PaymentMode.STRIPE,
    img: stripe,
  };
};

export const getConfirmationNumber = (payment: AssociatedPaymentModel) => {
  if (payment?.networkConfirmationCode) return payment?.networkConfirmationCode;
  return `${payment?.paymentMode}${payment?.paymentId.slice(-4)}`;
};
