import { Paper, styled } from '@mui/material';
import { colors } from 'style';

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: ${colors.white};
  width: 100%;
  padding: 12px;
`;

export const TechnicalStopContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const TechnicalStopItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}));
