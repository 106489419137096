import { v4 as uuidv4 } from 'uuid';

export const getSessionId = (): string | null => {
  let sessionId = sessionStorage.getItem('fsxClientSessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    sessionStorage.setItem('fsxClientSessionId', sessionId);
  }
  return sessionId;
};
