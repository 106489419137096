import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PassengerModel } from 'models/passenger.model';

interface IInitialState {
  passengers: PassengerModel[];
}

const initialState: IInitialState = {
  passengers: [],
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingPassengersValues(state, action: PayloadAction<PassengerModel[]>) {
      state.passengers = action.payload;
    },
    resetBookingState(state) {
      return (state = initialState);
    },
  },
});
export const { setBookingPassengersValues, resetBookingState } = bookingSlice.actions;
