import { useAppSelector } from 'hooks/useAppSelector';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { getAmountCurrency } from 'utils/currency';

export const useGetFlightDetails = () => {
  const currentTicketPrice = useAppSelector((store) => store.priceOffer?.currentTicketPrice);
  const { journeyOption } = useAppSelector((store) => store.journeyOption);
  const { originAirport, destinationAirport } = journeyOption ?? ({} as JourneyOfferModel);
  const ticketPrice = getAmountCurrency(currentTicketPrice?.value) || '';
  const flightDirections = (originAirport?.code && destinationAirport?.code && `${originAirport.code}-${destinationAirport.code}`) || '';
  return {
    currentTicketPrice,
    ticketPrice,
    flightDirections,
  };
};
