import { gql } from '@apollo/client';

export interface RECOMMENDATION_DATES_data {
  searchRecommendation: Array<{
    recommendationDate: string;
  }>;
}

export interface RECOMMENDATION_DATES_vars {
  departureAirport: string;
  arrivalAirport: string;
  departureDate: string;
}

export const RECOMMENDATION_DATES = gql`
  query searchRecommendation($departureAirport: AlphanumericStr!, $arrivalAirport: AlphanumericStr!, $departureDate: Date!) {
    searchRecommendation(departureAirport: $departureAirport, arrivalAirport: $arrivalAirport, departureDate: $departureDate) {
      recommendationDate
    }
  }
`;
