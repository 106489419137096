import { gql } from '@apollo/client';
/**
 * This file contains common reusable fragments for queries/mutations.
 * Once the schema of any fragment will change on BE side - FE needs only to change in one fragment place
 * local (not reusable) fragments are located inside queries
 */

export const AIRPORT_FRAGMENT = gql`
  fragment AirportFragment on AirportInfo {
    code
    name
    terminal
  }
`;

export const CONNECTION_DETAILS_FRAGMENT = gql`
  fragment ConnectionDetailsFragment on ConnectionDetails {
    description
    duration
    connectionFlags {
      overnightStay
      changeOfTerminal
      changeOfAirport
      customsRequired
      connectToCodeshare
    }
    arrivalAirport {
      ...AirportFragment
    }
    arrivalCity {
      ...CityFragment
    }
    departureAirport {
      ...AirportFragment
    }
    departureCity {
      ...CityFragment
    }
  }
`;

export const TECHNICAL_STOP_FRAGMENT = gql`
  fragment TechnicalStopFragment on TechnicalStop {
    customsRequired
    description
    duration
    arrivalAt
    departureAt
    stopAirport {
      ...AirportFragment
    }
  }
  ${AIRPORT_FRAGMENT}
`;

export const FLIGHT_AMENITY_FRAGMENT = gql`
  fragment FlightAmenityFragment on FlightAmenity {
    amenity
    charged
    description
  }
`;

export const CITY_FRAGMENT = gql`
  fragment CityFragment on CityInfo {
    name
    code
  }
`;

export const AIRPORT_INFO_FRAGMENT = gql`
  fragment AirportInfoFragment on AirlineInfo {
    name
    code
  }
`;

export const AIRCRAFT_FRAGMENT = gql`
  fragment AircraftFragment on AircraftInfo {
    code
    description
  }
`;

export const SEGMENT_FRAGMENT = gql`
  fragment SegmentFragment on SegmentOffer {
    id
    source
    departureAt
    arrivalAt
    duration
    changeOfDate
    aircraft {
      ...AircraftFragment
    }
    originAirport {
      ...AirportFragment
    }
    destinationAirport {
      ...AirportFragment
    }
    originCity {
      ...CityFragment
    }
    destinationCity {
      ...CityFragment
    }
    flightinstanceId
    flightNumber
    fareCode
    cabin
    brandedFare
    fareDescription
    marketingAirline {
      ...AirportInfoFragment
    }
    operatingAirline {
      ...AirportInfoFragment
    }
    technicalStops {
      ...TechnicalStopFragment
    }
    connectionDetails {
      ...ConnectionDetailsFragment
    }
    addedServices {
      ...AddedServiceFragment
    }
    amenities {
      ...FlightAmenityFragment
    }
  }
  ${FLIGHT_AMENITY_FRAGMENT}
  ${AIRCRAFT_FRAGMENT}
  ${CONNECTION_DETAILS_FRAGMENT}
  ${TECHNICAL_STOP_FRAGMENT}
  ${CITY_FRAGMENT}
  ${AIRPORT_INFO_FRAGMENT}
  ${AIRPORT_FRAGMENT}
`;

export const PRICE_QUOTE_FRAGMENT = gql`
  fragment PriceQuoteFragment on MoneyType {
    amount
    currency
  }
`;
export const TICKET_TRANSFER_FRAGMENT = gql`
  fragment TicketTransferFragment on TicketTransfer {
    description
    duration
    overnightStay
    changeOfTerminal
    changeOfAirport
    customsRequired
    sameTicketingAirline
    sameOperatingAirline
  }
`;

export const ADDED_SERVICE_FRAGMENT = gql`
  fragment AddedServiceFragment on Service {
    id
    offerId
    description
    paxRef
    segRef
    priceQuote {
      ...PriceQuoteFragment
    }
  }
  ${PRICE_QUOTE_FRAGMENT}
`;

export const ITINERARY_FRAGMENT = gql`
  fragment ItineraryFragment on ItineraryOffer {
    id
    changeOfDate
    segments {
      ...SegmentFragment
    }
    duration
    ticketTransfer {
      ...TicketTransferFragment
    }
    addedServices {
      ...AddedServiceFragment
    }
  }
  ${SEGMENT_FRAGMENT}
  ${TICKET_TRANSFER_FRAGMENT}
  ${ADDED_SERVICE_FRAGMENT}
`;

export const TICKET_OFFER_FRAGMENT = gql`
  fragment TicketOfferFragment on TicketOffer {
    id
    itinerariesIncluded
  }
`;

export const PAX_PRICING_FRAGMENT = gql`
  fragment PaxPricingFragment on PaxPricing {
    paxId
    paxType
    services {
      ...AddedServiceFragment
    }
    priceQuote {
      ...PriceQuoteFragment
    }
  }
  ${ADDED_SERVICE_FRAGMENT}
  ${PRICE_QUOTE_FRAGMENT}
`;

export const BAGGAGE_ALLOWANCE_FRAGMENT = gql`
  fragment BaggageAllowanceFragment on BaggageAllowance {
    checkedDescription
    checkedWeight
    checkedQuantity
    checkedWeightUnit
    carryonDescription
    carryonWeight
    carryonQuantity
    carryonWeightUnit
  }
`;

export const TICKETING_DETAILS_FRAGMENT = gql`
  fragment TicketingDetailsFragment on TicketingDetails {
    codeshare
    description
    international
    multiTicket
    offerChanged
    priceConfirmed
    operatingAirlines {
      code
      name
    }
    paxPricings {
      ...PaxPricingFragment
    }
    ticketOffers {
      ...TicketOfferFragment
    }
  }
  ${PAX_PRICING_FRAGMENT}
  ${TICKET_OFFER_FRAGMENT}
`;

export const EXTERNAL_PNRS_FRAGMENT = gql`
  fragment ExternalPNRsFragment on ExternalPNR {
    airlineName
    airlineCode
    externalPNR
  }
`;

export const ASSOCIATED_PAYMENT_FRAGMENT = gql`
  fragment AssociatedPaymentFragment on PaymentDetails {
    paymentId
    paymentState
    paymentMode
    cardBrand
    cardLast4
    networkConfirmationCode
    paymentValue {
      ...PriceQuoteFragment
    }
  }
  ${PRICE_QUOTE_FRAGMENT}
`;

export const JOURNEY_FRAGMENT = gql`
  fragment JourneyFragment on JourneyOffer {
    id
    source
    priced
    farePerAdult
    farePerChild
    farePerInfant
    arrivalDate
    departureDate
    brandedFare
    majorityCabin
    dateChange
    priceQuote {
      ...PriceQuoteFragment
    }
    originAirport {
      ...AirportFragment
    }
    destinationAirport {
      ...AirportFragment
    }
    originCity {
      ...CityFragment
    }
    destinationCity {
      ...CityFragment
    }
    itineraries {
      ...ItineraryFragment
    }
    baggageAllowance {
      ...BaggageAllowanceFragment
    }
    ticketingDetails {
      ...TicketingDetailsFragment
    }
    addedServices {
      ...AddedServiceFragment
    }
  }
  ${PRICE_QUOTE_FRAGMENT}
  ${AIRPORT_FRAGMENT}
  ${CITY_FRAGMENT}
  ${ITINERARY_FRAGMENT}
  ${BAGGAGE_ALLOWANCE_FRAGMENT}
  ${TICKETING_DETAILS_FRAGMENT}
  ${ADDED_SERVICE_FRAGMENT}
`;

export const PASSENGERS_FRAGMENT = gql`
  fragment PassengersFragment on PassengerDetails {
    firstName
    middleName
    lastName
    passengerType
  }
`;
