import React from 'react';
import { Button } from '@mui/material';
import { Modal } from 'components';
import { ButtonsBlock } from './styles';

interface WarningBackModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export const WarningBackModal = ({ open, onClose, onConfirm }: WarningBackModalProps) => {
  return (
    <Modal open={open} onClose={onClose} maxWidth={'480'}>
      <>
        <p>Please note, that going back will return you to the home page as you have already created a flight order.</p>
        <ButtonsBlock>
          <Button onClick={onClose} variant="text" fullWidth>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant={'contained'} fullWidth>
            Back home
          </Button>
        </ButtonsBlock>
      </>
    </Modal>
  );
};
