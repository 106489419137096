import React from 'react';
import { ContainerStyled } from 'style';
import fasaxTravelLogo from 'assets/img/withTravelfasax.png';
import { MyTripsForm } from 'components/pages/mytrips/MyTripsForm/MyTripsForm';
import { ImageWrapper, MyTripWrapper } from './styles';

export const MyTripPageComponent = () => {
  return (
    <ContainerStyled>
      <MyTripWrapper>
        <ImageWrapper>
          <img src={fasaxTravelLogo} alt="fasax-logo" />
        </ImageWrapper>
        <div>
          <MyTripsForm />
        </div>
      </MyTripWrapper>
    </ContainerStyled>
  );
};
