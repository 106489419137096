import { useLocation, useParams } from 'react-router-dom';
import { Currency, DirectionEnum, TicketClassEnum } from 'enum/enum';
import { useAppSelector } from 'hooks/useAppSelector';

import { getPrepareDataFlightOptions } from '../helper';
import { paramsOptionsType } from '../types';

export const useGetSearchOptionsForRequest = (): {
  infants: number;
  includeWT: boolean;
  requestedItineraries: { includeDaysBefore: number; includeDaysAfter: number; departureDate: string }[];
  includeAeroCRS: boolean;
  adults: number;
  cabin: TicketClassEnum;
  includeOrchestra: boolean;
  ndcProvider: string;
  children: number;
  currency: Currency;
  includeMultiticket: boolean;
  includeAmadeus: boolean;
  direction: string;
} => {
  const params: paramsOptionsType = useParams();
  const { search } = useLocation();

  const passengerSearchInfo = useAppSelector((state) => state.search);
  const { isAerocrs } = useAppSelector((state) => state.debugger);
  const { currency } = useAppSelector((state) => state.payment);

  const { includeAmadeus, includeOrchestra, includeMultiticket, ndcProvider } = passengerSearchInfo;
  const { destination, origin, departureDate, returnDate, type } = params;

  const queryParams = new URLSearchParams(search);
  const adults = Number(queryParams.get('adults') || '1');
  const children = Number(queryParams.get('children') || '0');
  const infants = Number(queryParams.get('infants') || '0');
  const direction = queryParams.get('direction') || DirectionEnum.ONE_WAY;

  const preparedReturnDate = direction === DirectionEnum.ONE_WAY ? null : returnDate;

  return {
    adults,
    children,
    infants,
    cabin: type.toUpperCase() as TicketClassEnum,
    direction: direction,
    requestedItineraries: getPrepareDataFlightOptions(departureDate, destination, preparedReturnDate, origin),
    currency: currency ?? Currency.USD,
    includeAmadeus,
    includeOrchestra,
    includeMultiticket,
    ndcProvider,
    includeAeroCRS: isAerocrs,
    includeWT: false,
  };
};
