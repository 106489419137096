import React, { useEffect } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { CloseButton, ModalContent, ModalHeader, ModalStyled } from './styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Typography } from 'components/kit/Typography';

interface ModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactChild;
  showModalHeaderWithBackground?: boolean;
  title?: string;
  maxWidth?: string;
}

export const Modal = React.memo(({ open, onClose, children, title, showModalHeaderWithBackground = true, maxWidth }: ModalProps) => {
  useEffect(() => {
    if (open) {
      document.querySelector('.woot-widget-bubble')?.classList.add('hide-chatwood');
    } else {
      document.querySelector('.woot-widget-bubble')?.classList.remove('hide-chatwood');
    }
  }, [open]);

  return (
    <ModalMui open={open} onClose={() => onClose(false)}>
      <ModalStyled showModalHeaderWithBackground={showModalHeaderWithBackground} width={maxWidth}>
        {showModalHeaderWithBackground && (
          <ModalHeader>
            {title && <Typography variant="smallTitle">{title}</Typography>}
            <CloseButton onClick={() => onClose(false)}>
              <CloseRoundedIcon />
            </CloseButton>
          </ModalHeader>
        )}
        <ModalContent>{children}</ModalContent>
      </ModalStyled>
    </ModalMui>
  );
});
