const matchesWildcard = (hostname: string, domain: string) => {
  const regex = new RegExp('^' + domain.replace(/\*/g, '.*') + '$');
  return regex.test(hostname);
};

export const isDauusDomain = () => {
  const hostname = window.location.hostname;
  const dauusDomains = ['dauus.fasax.com', 'dauus-beta.fasax.com', 'develop.fasax.com', 'localhost', '*.fasax-travel-ui.pages.dev'];
  return dauusDomains.some((domain) => matchesWildcard(hostname, domain));
};
