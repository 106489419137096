import { useAppSelector } from 'hooks/useAppSelector';
import { successfulPaymentStatuses } from 'constants/payments';
import { PaymentMode, PaymentStatusEnum } from 'enum/enum';
import { getConfirmationNumber, getPaymentCard } from 'utils/payment';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { getAmountCurrency } from 'utils/currency';

export const useGetPaymentInfo = () => {
  const { associatedPayments } = useAppSelector((state) => state.flightOrder);

  const payment = associatedPayments?.find((payment) =>
    successfulPaymentStatuses.includes(payment.paymentState as PaymentStatusEnum),
  ) as AssociatedPaymentModel;

  const card = getPaymentCard(payment);
  const confirmationNumber = getConfirmationNumber(payment);
  const price = getAmountCurrency(payment?.paymentValue);
  const isMpesa = payment?.paymentMode === PaymentMode.MPESA_EXPRESS;

  return {
    cardLast4: payment?.cardLast4 || '-',
    lastCardDigitsLabel: isMpesa ? 'Last 3 Digits' : 'Last 4 Digits',
    price,
    card,
    confirmationNumber,
    paymentMode: payment?.paymentMode as PaymentMode,
  };
};
