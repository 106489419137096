import React from 'react';
import { BoxSpaceBetween } from 'style';
import { Typography } from '@mui/material';
import { colors } from 'style';

interface IProps {
  title: string;
}

export const BookingFormHeader = ({ title }: IProps) => {
  return (
    <BoxSpaceBetween sx={{ height: '36px' }}>
      <Typography fontSize={18} lineHeight={'24px'} fontWeight={700} color={colors.primary}>
        {title}
      </Typography>
      <Typography fontSize={16} lineHeight={'24px'} fontWeight={400} color={colors.gray80} sx={{ alignSelf: 'end' }}>
        *Required
      </Typography>
    </BoxSpaceBetween>
  );
};
