import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import ListItem from '@mui/material/ListItem';
import { ListTextItem } from '../Drawer/styles';

interface InstallPWAButtonProps {
  deferredPrompt: any;
  setDeferredPrompt: React.Dispatch<React.SetStateAction<any>>;
}

export const InstallPWAButton: React.FC<InstallPWAButtonProps> = ({ deferredPrompt, setDeferredPrompt }) => {
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('accepted PWA');
        } else {
          console.log('close install PWA');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return deferredPrompt ? (
    <ListItem button onClick={handleInstallClick}>
      <AppsIcon />
      <ListTextItem primary="Install App" />
    </ListItem>
  ) : null;
};
