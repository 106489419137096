import { CircularProgress } from '@mui/material';
import { LoaderWrapper, CenteredLoader } from './styles';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <CenteredLoader>
        <CircularProgress />
      </CenteredLoader>
    </LoaderWrapper>
  );
};
