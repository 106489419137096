export interface GeneratedOption {
  title: string;
  value: number;
}

export function generateYears(minYear: number, maxYear: number): GeneratedOption[] {
  const years: GeneratedOption[] = [];
  for (let year = maxYear; year >= minYear; year--) {
    years.push({ title: year.toString(), value: +year.toString() });
  }
  return years;
}

export function generateMonths(startDateOfBirth: Date, selectedYear: number, minYear: number, maxYear: number): GeneratedOption[] {
  const currentYear = startDateOfBirth.getFullYear();
  const currentMonth = startDateOfBirth.getMonth() + 1;

  const months: GeneratedOption[] = [];
  let minMonth = 1;
  let maxMonth = 12;

  const isMinYearLimitSelected = selectedYear === currentYear - maxYear;
  const isMaxYearLimitSelected = selectedYear === currentYear - minYear;

  if (isMinYearLimitSelected) {
    minMonth = currentMonth;
  }

  if (isMaxYearLimitSelected) {
    maxMonth = currentMonth;
  }

  for (let month = minMonth; month <= maxMonth; month++) {
    const monthName = new Date(2000, month - 1, 1).toLocaleString('en-US', { month: 'long' });
    const monthValue = month.toString();
    months.push({ title: monthName, value: +monthValue });
  }

  return months;
}

export function generateDays(
  startDateOfBirth: Date,
  selectedYear: number,
  selectedMonth: number,
  minYear: number,
  maxYear: number,
): GeneratedOption[] {
  const currentYear = startDateOfBirth.getFullYear();
  const currentMonth = startDateOfBirth.getMonth() + 1;
  const currentDay = startDateOfBirth.getDate();

  const days: GeneratedOption[] = [];

  let minDay = 1;
  let maxDay = new Date(selectedYear, selectedMonth, 0).getDate();

  const isMinYearLimitSelected = selectedYear === currentYear - maxYear;
  const isMaxYearLimitSelected = selectedYear === currentYear - minYear;

  if (isMinYearLimitSelected && selectedMonth === currentMonth) {
    minDay = currentDay + 1;
  }

  if (isMaxYearLimitSelected && selectedMonth === currentMonth) {
    maxDay = currentDay;
  }

  for (let day = minDay; day <= maxDay; day++) {
    const dayValue = day.toString();
    days.push({ title: dayValue, value: +dayValue });
  }

  return days;
}
