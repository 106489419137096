import { Modal } from 'components';
import React from 'react';
import { Button } from '@mui/material';
import { ButtonsBlock } from './styles';

interface LogoutModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const LogoutModal = ({ open, onClose, onConfirm }: LogoutModalProps) => {
  return (
    <Modal open={open} onClose={onClose} maxWidth={'480'}>
      <>
        <p>Are you sure you want to log out?</p>
        <ButtonsBlock>
          <Button onClick={onClose} variant="text" fullWidth>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant={'contained'} fullWidth>
            Logout
          </Button>
        </ButtonsBlock>
      </>
    </Modal>
  );
};
