import Button from '@mui/material/Button';
import { ButtonWrapper } from './styles';
import { Typography } from 'components/kit/Typography';

type ModalContentProps = {
  flightDirections: string;
  ticketPrice: string;
  handleConfirm: () => void;
  handleCancel: () => void;
};

export const ModalContent = ({ flightDirections, ticketPrice, handleConfirm, handleCancel }: ModalContentProps) => (
  <>
    <Typography variant="smallTitle">
      Please note, the price for the flight
      <Typography variant="smallTitle"> {flightDirections} </Typography>
      you have selected has been updated. The new price is
      <Typography variant="smallTitle"> {ticketPrice}</Typography>.
    </Typography>
    <ButtonWrapper>
      <Button variant="contained" onClick={handleConfirm} data-testid="confirm-flight">
        Confirm
      </Button>
      <Button variant="outlined" onClick={handleCancel}>
        Back to flight options
      </Button>
    </ButtonWrapper>
  </>
);
