import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FaqGroupItemProps {
  title: string;
  items: Array<{
    title: string;
    subtitle: string;
    isHTML?: boolean;
  }>;
}
const FaqGroupItem = ({ title, items }: FaqGroupItemProps) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box component={AccordionSummary} expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary" white-space="pre-wrap">
                {item?.isHTML === true ? <div dangerouslySetInnerHTML={{ __html: item.subtitle }} /> : item.subtitle}
              </Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Flights'}
          items={[
            {
              title: 'Help! I need to change/cancel my reservation!',
              subtitle:
                'If you need to make a change or cancellation greater than 24 hours before your departure, please contact us by email, chat, or WhatsApp. If you need to change or cancel a trip scheduled to begin within 24 hours, you may also need to contact the airline operating your flight.',
            },
            {
              title: "What is FASAX's refund policy?",
              subtitle:
                "When you book a flight through Fasax.com, you are entering into an agreement with one or more airlines, and refunds of the airfare, fees, and taxes charged by the airline are subject to that airline's policies. The airlines we partner with that predominantely serve Somalia typically allow cancellations up to 24 hours before your scheduled departure time without a fee. We do not charge any additional fees or withhold any part of a refund provided by an airline. If we charged a fee as part of the original booking, we will refund it alongside any refund provided by the airline. All refunds will be returned to their original form of payment whenever possible. Please refer to our [terms and conditions] - link to this - for more information.",
            },
            {
              title: 'Do prices include Taxes and Fees?',
              subtitle:
                'The prices for flights shown on Fasax.com include all applicable taxes and fees for air travel charged by the airline. Our prices may also include a service fee for Fasax to arrange your travels, although we currently do not charge a fee for booking flights online. \nYou may need to pay visa fees, entry / exit taxes or fees, or airport fees as part of your travels. Check with local authorities and airports to confirm the specific fees that may apply.',
            },
            {
              title: 'Do flights prices include baggage fees?',
              subtitle:
                "Flights come with different baggage allowances, depending on the airline's policies. Most airlines serving Somalia typically offer 20KG of checked baggage and 10KG of carryon baggage standard.",
            },
            {
              title: 'Can I search for a flight for my child and I?',
              subtitle:
                'Yes! Some airlines offer discounted fares for children under the age of 12. If you are traveling with an infant under the age of 2, you are expected to carry the infant on the lap of an adult. We would be happy to contact the airline on your behalf if you have more specific questions about traveling with children or infants. All bookings through Fasax must include at least one adult age 18+.',
            },
            {
              title: 'Can I book travel for a large group (>6 passengers) or a charter flight?',
              subtitle:
                'Yes, we would be happy to assist you in booking travel for a larger group. If you would like to arrange a group booking, please contact us at support@fasax.com. \n\nWe can also assist in arranging charter flights. Several of the airlines we partner with provide charter service for UN, the President of the Federal Government of Somalia, government agencies, and NGOs. If you are interested in a charter flight, email us at support@fasax.com',
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Trips'}
          items={[
            {
              title: 'Where can I find my booking confirmation?',
              subtitle:
                'Your confirmation code is 6 alpha-numeric characters (XYZZY1), which is included in our confirmation emails as well as on Fasax.com when you complete a booking',
            },
            {
              title: 'Can I book a hotel room stay with FASAX? ',
              subtitle:
                'We do not currently offer online booking of hotels in Somalia, but we would like to hear from you if this is something you would use. Email info@fasax.com. We can assist you in booking a hotel in Hargesia, Somaliland if you contact us at support@fasax.com. For bookings elsewhere, you can book through our affiliate link to <a href="https://www.booking.com/index.en-us.html?aid=7955215">Booking.com</a>. Using this link allows us to invest in improving Fasax.com.',
              isHTML: true,
            },
            {
              title: 'Is this a subscription service?',
              subtitle:
                'No, there are no subscription fees. We provide tools to help you manage your travels that are included as part of booking with Fasax.',
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Account & settings'}
          items={[
            {
              title: 'Can I create an account with FASAX?',
              subtitle:
                'We do not currently offer individual user accounts, but we may do so in the future. If you represent a company or organization, contact us at info@fasax.com.',
            },
            {
              title: 'How can I partner with FASAX?',
              subtitle:
                'We are interested in working with travel providers, payment processors, and other organizations interested in supporting the growth of travel and commercial aviation in Somalia. Please email us at info@fasax.com.',
            },
            {
              title: 'Can I establish a corporate / organization account with FASAX?',
              subtitle: "Yes, please contact us at info@fasax.com and we'll be in touch.",
            },
          ]}
        />
      </Box>
    </Box>
  );
};
