import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { routes } from './index';

interface ProtectedRouteWithDataProps extends RouteProps {
  component: React.ComponentType<object>;
  hasAccess?: boolean;
}

export const ProtectedRouteWithData: React.FC<ProtectedRouteWithDataProps> = ({ component: Component, hasAccess, path, ...rest }) => {
  const isDirectAccess = !hasAccess;
  return <Route {...rest} render={(props) => (isDirectAccess ? <Redirect to={routes.home} /> : <Component {...props} />)} />;
};
