import React from 'react';
import { CreatePaymentModel } from 'models/createPayment.model';
import { getAmountCurrency } from 'utils/currency';
import { MarginTop, Notification, Typography } from 'components';
import { NotificationRow } from 'components/pages/approval/styles';

interface Props {
  paymentBillingInfo: null | CreatePaymentModel;
  isFailed: boolean;
}

export const MpesaNotification = ({ paymentBillingInfo, isFailed }: Props) => {
  const instructionList = [
    <Typography variant={'smallTitle'}>Go to MPESA Menu on your phone</Typography>,
    <Typography variant={'smallTitle'}>Select Lipa Na Mpesa</Typography>,
    <Typography variant={'smallTitle'}>Select Paybill</Typography>,
    <>
      <Typography variant={'smallTitle'}>Account number:</Typography>
      <Typography variant={'smallTitleBold'}>{paymentBillingInfo?.billRefNumber}</Typography>
    </>,
    <>
      <Typography variant={'smallTitle'}>Input Paybill No.</Typography>
      <Typography variant={'smallTitleBold'}>{paymentBillingInfo?.paybillNumber}</Typography>
    </>,
    <>
      <Typography variant={'smallTitle'}>Make sure merchant name is:</Typography>
      <Typography variant={'smallTitleBold'}>{paymentBillingInfo?.merchantName}</Typography>
    </>,
    <>
      <Typography variant={'smallTitle'}>Enter</Typography>
      <Typography variant={'smallTitleBold'}>{getAmountCurrency(paymentBillingInfo!.paymentValue)}</Typography>
    </>,
    <Typography variant={'smallTitle'}>Wait for a Confirmation SMS from Mpesa and click confirm payment button below</Typography>,
  ];
  return (
    <>
      <Notification variant={'warning'}>
        <Typography variant={'smallTitle'}>Pay to complete your booking:</Typography>
        <MarginTop marginTop={'8'}>
          {isFailed ? (
            <Typography variant={'smallTitle'}>There was a technical error. Please retry the payment</Typography>
          ) : (
            instructionList?.map((instruction, index) => {
              return (
                <NotificationRow key={index}>
                  <Typography variant={'smallTitle'}>{index + 1}</Typography>.{instruction}
                </NotificationRow>
              );
            })
          )}
        </MarginTop>
      </Notification>
    </>
  );
};
