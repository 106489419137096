import { Alert, Box, styled } from '@mui/material';
import { breakpoints, colors } from 'style';
import { Typography } from 'components';
import { headerFooterHeight } from 'style/vars/constants';

// ServicesList
export const ServicesListContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: `calc(100vh - ${headerFooterHeight}px)`,
}));

export const ServicesListContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

// PassengerServiceCard
export const PassengerCardHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
}));

export const ServicesSegmentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',
}));

export const EmptyServices = styled('div')(() => ({
  padding: '12px',
}));

export const NoAvailableNotifier = styled(Alert)(() => ({
  backgroundColor: colors.primaryBgTransparent,
  '& .MuiSvgIcon-root': {
    fill: colors.primary,
  },
}));
NoAvailableNotifier.defaultProps = {
  severity: 'info',
};

// ServiceItemCategory
export const ServiceItemContainer = styled('div')`
  width: 50%;
  border-radius: 4px;
  border: 1px solid ${colors.gray20};
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

// GroupedServiceItem
export const GroupedServiceTitle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '6px',
  padding: '12px',
  borderBottom: `1px solid ${colors.gray20}`,
  backgroundColor: colors.primaryBgTransparent,
}));

export const ServiceOptions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '10px',
  span: {
    minWidth: 'max-content',
  },
}));

// TotalPrice
export const StyledBoxPrices = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-top: 1px solid ${colors.gray20};
`;

export const TotalText = styled(Typography)``;
TotalText.defaultProps = {
  marginTop: 1,
  paddingLeft: 2,
  variant: 'body',
};
