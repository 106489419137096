import React, { useEffect, useRef } from 'react';

type Handler = () => void;

export const useClickOutside = (handler: Handler): React.MutableRefObject<HTMLInputElement | undefined> => {
  const domNode = useRef<HTMLInputElement>();

  useEffect(() => {
    const maybeHandler = (event: MouseEvent) => {
      if (domNode && domNode.current && !domNode?.current.contains(event.target as HTMLElement)) {
        handler();
      }
    };
    document.addEventListener('mousedown', maybeHandler);
    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return domNode;
};
