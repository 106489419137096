import { styled } from '@mui/material';
import { headerFooterHeight, verticalContainerPaddings } from 'style/vars/constants';

export const CenteredLoader = styled('div')`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
export const LoaderWrapper = styled('div')`
  position: relative;
  height: calc(100vh - ${headerFooterHeight + verticalContainerPaddings}px);
`;
