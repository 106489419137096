import React from 'react';
import { PassengerGroupedServicesBySegments } from 'models/passenger.model';
import { PassengerServiceCard } from './PassengerServiceCard';

interface CardPassengerProps {
  items: PassengerGroupedServicesBySegments[];
}

export const PassengerServiceList = ({ items }: CardPassengerProps) => {
  return (
    <>
      {items.map((i) => (
        <PassengerServiceCard key={`${i.firstName}${i.lastName}`} item={i} />
      ))}
    </>
  );
};
