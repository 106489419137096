/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} justifyContent="center" xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our Process
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              We have proprietary search technology coupled with our wide ranging partnerships with air travel providers and payment processors. This
              allows us to provide a convenient platform for arranging airline travel.
            </Typography>
          </Box>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our History
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              The idea for Fasax was conceived in 2017 after a disastrous trip in Somalia. Fasax.com is managed and headquartered in Somalia. We are
              additionaly incorporated in The United States. We have plans to add offices in Addis Ababa, and Nairobi soon!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
