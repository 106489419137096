import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { TextFieldProps } from '@mui/material/TextField/TextField';

interface IProps {
  name: string;
  control: Control<any>;
  error?: FieldError;
}

export const InputPhone = ({
  control,
  name,
  error,
  ...props
}: IProps & Omit<TextFieldProps, 'variant' | 'onChange' | 'error' | 'defaultValue' | 'type'>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <NumericFormat {...field} {...props} prefix="+" customInput={TextField} fullWidth error={!!error} helperText={error?.message} />;
      }}
    />
  );
};
