export const errorMessages = {
  required: 'This field is required',
  email: 'Wrong e-mail format',
  number: 'This field must contain numbers only',
  latinCharsOnly: 'Only latin characters are allowed',
  phoneInvalid: 'Phone number is invalid',
  phoneRequired: 'Phone number is required',
  shouldNotContainerNumbers: 'This field should not contain numbers',
  confirmationCode: 'Only A-Z and 0-9 are in confirmation codes',
  numbersAndLatinCharacters: 'Only latin characters and numbers are allowed',
  lengthCharacters6: 'This field should contain 6 characters',
  mPesaExpressPaymentCountryCode: 'invalid country code, should start with +254',
  voucher: 'invalid voucher format',
  waafiPaymentCountryCode: 'invalid country code, should start with +252',
  independentPassengerAge: 'At least 1 passenger should be above 18+',
};
