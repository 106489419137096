import React from 'react';
import { Button } from '@mui/material';

import { Container, ExceptionNotification, ButtonWrapper } from './styles';
import { Typography } from 'components';

interface Props {
  goBackHandler: () => void;
  exceptionText: string;
  buttonName?: string;
}

export const ExceptionMessage = ({ exceptionText, goBackHandler, buttonName = 'back to search' }: Props) => {
  return (
    <Container>
      <ExceptionNotification>
        <Typography variant="sectionTitle">{exceptionText}</Typography>
      </ExceptionNotification>
      <ButtonWrapper>
        <Button fullWidth={true} variant="contained" size="large" onClick={goBackHandler}>
          {buttonName}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};
