import React from 'react';
import FlightLandRounded from '@mui/icons-material/FlightLandRounded';

import { TechnicalStopModel } from 'models/segment.model';
import { Typography } from 'components/kit/Typography';

import { TechnicalStopContent, TechnicalStopItem, Wrapper } from './styles';
import { parseISO8601Time } from 'utils/dates';

interface TechnicalStopProps {
  technicalStops: TechnicalStopModel[];
}

export const TechnicalStops = ({ technicalStops }: TechnicalStopProps) => {
  if (!technicalStops?.length) return <></>;

  return (
    <Wrapper>
      {technicalStops.map((t) => {
        return (
          <TechnicalStopItem>
            <FlightLandRounded />
            <TechnicalStopContent>
              <Typography variant={'subtitleBold'}>
                {parseISO8601Time(t.duration)} {t.description} at {t.stopAirport.name}
              </Typography>
              <Typography variant={'subtitle'}>{t.customsRequired ? 'You need to change aircraft' : `You don't need to change aircraft`}</Typography>
            </TechnicalStopContent>
          </TechnicalStopItem>
        );
      })}
    </Wrapper>
  );
};
