import { useState } from 'react';

type UseToggleReturnType = {
  close: () => void;
  open: () => void;
  toggle: () => void;
  isOpen: boolean;
};

export const useToggle = (): UseToggleReturnType => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => setIsOpen(!isOpen);

  const close = () => setIsOpen(false);

  const open = () => setIsOpen(true);

  return { close, open, toggle, isOpen };
};
