import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoutePage } from 'enum/enum';

type InitialState = {
  [key in RoutePage]?: boolean;
};

const initialState: InitialState = {
  [RoutePage.OPTIONS]: false,
  [RoutePage.BOOKING]: false,
  [RoutePage.SUMMARY]: false,
  [RoutePage.ENTRY]: false,
  [RoutePage.PAYMENT_OPTIONS]: false,
  [RoutePage.ORDER]: false,
  [RoutePage.SUCCESS]: false,
  [RoutePage.SERVICES]: false,
};

export const accessibilitySlice = createSlice({
  name: 'accessibility',
  initialState,
  reducers: {
    allowPageAccess(state, action: PayloadAction<RoutePage>) {
      state[action.payload] = true;
    },
    allowOptionsAccess(state) {
      // edge case:
      // need additional action because once we are in the middle/end of flow, and go back manually by browser url, rest of our pages will be available
      // that is why we need to set to options = true, and rest set as false accessibility.
      const keys = Object.keys(state) as Array<keyof InitialState>;
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        state[key] = key === RoutePage.OPTIONS;
      }
    },
    resetPageAccess(state, action: PayloadAction<RoutePage>) {
      state[action.payload] = false;
    },
    resetAllPagesAccess(state) {
      return (state = initialState);
    },
    resetMultiPagesAccess(state, action: PayloadAction<RoutePage[]>) {
      action.payload.forEach((route) => {
        state[route] = false;
      });
    },
  },
});
export const { allowPageAccess, resetPageAccess, resetAllPagesAccess, allowOptionsAccess, resetMultiPagesAccess } = accessibilitySlice.actions;
