import { styled } from '@mui/material';
import { headerFooterHeight, verticalContainerPaddings } from 'style/vars/constants';

export const InteractionContainer = styled('div')`
  min-height: calc(100vh - ${headerFooterHeight + verticalContainerPaddings}px);
  display: flex;
  flex-direction: column;
`;

export const FadeInContainer = styled('div')({
  opacity: 0,
  textAlign: 'center',
  paddingTop: '36px',
  transition: 'opacity 1s ease-in-out',
  '&.visible': {
    opacity: 1,
  },
  '&.hidden': {
    opacity: 0,
  },
});

export const ImageWrapper = styled('div')(() => ({
  display: 'flex',
  // flex: 1,
  width: '100%s',
  justifyContent: 'center',
  alignItems: 'center',
}));
