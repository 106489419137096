import React from 'react';
import { getDaysDifference } from 'utils/dates';
import { DateChangeIndicator } from './styles';

interface Props {
  departureAt: Date;
  changeOfDate: number;
}
export const ChangeDateNotification = ({ departureAt, changeOfDate }: Props) => {
  if (changeOfDate === 0) return null; // no changes between departure - arrival dates
  const getArrivalDate = getDaysDifference(departureAt, changeOfDate);
  return <DateChangeIndicator>Arrives: {getArrivalDate}</DateChangeIndicator>;
};
