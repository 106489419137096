import { Box, Paper, styled } from '@mui/material';
import { colors } from 'style';
import { Typography } from 'components';

export const SegmentDetailedWrapper = styled('div')`
  border-bottom: 1px solid ${colors.gray20};
  padding-bottom: 24px;
`;

export const SegmentDetailedItemContainer = styled(Paper)`
  display: flex;
  background-color: ${colors.primaryBg};
  margin: 0 12px;
  padding: 12px 0;
  border-radius: 4px;
  flex-direction: column;
`;

export const SegmentDetailedContent = styled('div')`
  height: 100%;
  width: 100%;
  padding: 12px;
`;

export const SegmentItemBlock = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const FlightLineLeft = styled('div')`
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  border: 1px dashed ${colors.gray80};
  transform: translateY(10px);
  margin-left: 10px;
`;

export const FlightLineRight = styled('div')`
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  border: 1px dashed ${colors.gray80};
  transform: translateY(10px);
  margin-right: 10px;
  margin-left: 2px;
  position: relative;
  &::before {
    width: 11px;
    height: 11px;
    content: '';
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent ${colors.gray80};
    top: -5px;
    right: -3px;
    position: absolute;
  }
`;

export const TitleImageSection = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 6px;
  padding: 12px 12px 0;
`;

export const TitleSection = styled('div')`
  display: flex;
`;

export const Title = styled('div')`
  display: flex;
  align-items: center;
`;

export const SegmentsList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ConnectionDetailsSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
`;

export const SegmentDetailedShortNames = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SegmentDetailedTimes = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SegmentDetailedLine = styled('div')`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const DurationTimeText = styled(Typography)(() => ({
  margin: '0 4px',
}));

export const ArrivesWithChangeDate = styled(Box)`
  display: flex;
`;

export const SegmentTitle = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}));
