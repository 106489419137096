import { AppBar, Box, styled, Toolbar } from '@mui/material';

export const AppBarStyled = styled(AppBar)`
  height: 70px;
`;

export const LogoIconWrapper = styled(Box)`
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
`;

export const ToolbarStyled = styled(Toolbar)`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 100%;
`;
