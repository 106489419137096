import React from 'react';
import { MarginTop } from 'components';
import { BoxSpaceBetween, FlexColumn, JustifyCenter } from 'style';
import evcplus from 'assets/img/evcplus.png';
import golis from 'assets/img/golis.png';
import edahab from 'assets/img/edahab.png';
import telesom from 'assets/img/telesom.png';
import { useWindowSize } from 'hooks/useWindowWidth';

export const CompanyPictures = () => {
  const size = useWindowSize();
  const windowWidth = size.width;
  const isSmallScreen = windowWidth <= 380;
  return (
    <MarginTop marginTop={'60'}>
      {isSmallScreen ? (
        <FlexColumn>
          <BoxSpaceBetween>
            <img src={evcplus} alt="evc logo" />
            <img src={golis} alt="golis logo" />
          </BoxSpaceBetween>
          <BoxSpaceBetween>
            <img src={edahab} alt="dahab logo" />
            <img src={telesom} alt="telesom logo" />
          </BoxSpaceBetween>
        </FlexColumn>
      ) : (
        <FlexColumn>
          <BoxSpaceBetween>
            <img src={evcplus} alt="evc logo" />
            <img src={golis} alt="golis logo" />
            <img src={edahab} alt="dahab logo" />
          </BoxSpaceBetween>
          <MarginTop marginTop={'16'}>
            <JustifyCenter>
              <img src={telesom} alt="telesom logo" />
            </JustifyCenter>
          </MarginTop>
        </FlexColumn>
      )}
    </MarginTop>
  );
};
