import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

export interface IAutocompleteOption {
  title: string;
  value: string;
}

interface IProps {
  name: string;
  control: Control<any>;
  options: IAutocompleteOption[];
  label: string;
  error?: FieldError;
}

export const AutocompleteInput = ({ name, control, options, error, ...inputProps }: IProps & Omit<TextFieldProps, 'error'>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...rest } }) => {
        return (
          <Autocomplete
            {...rest}
            options={options}
            value={rest.value}
            onChange={(e, v) => {
              rest.onChange(v);
            }}
            getOptionLabel={(option) => option.title || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoSelect
            renderInput={(params) => (
              <TextField {...inputProps} {...params} error={!!error} helperText={error?.message} variant="outlined" name={name} />
            )}
          />
        );
      }}
    />
  );
};
