import React, { useMemo } from 'react';
import { Typography } from 'components';
import { useNumberAnimation } from 'hooks/useNumberAnimation';
import { ServiceModel } from 'models/service.model';

import { StyledBoxPrices, TotalText } from './styles';

interface TotalPriceProps {
  flightPrice: number | string;
  currency: string;
  selectedServices: ServiceModel[];
}

export const TotalPrice = React.memo(({ selectedServices, flightPrice, currency }: TotalPriceProps) => {
  const totalAmountSelectedService = useMemo(() => {
    return selectedServices.reduce((total, service) => {
      const amount = parseInt(service?.priceQuote?.amount) ?? 0;
      return total + amount;
    }, 0);
  }, [selectedServices]);

  return (
    <StyledBoxPrices>
      <TotalText variant="body">
        Flight price:{' '}
        <Typography variant="smallTitleBold">
          {useNumberAnimation(parseInt(flightPrice as string), 300)} {currency}
        </Typography>
      </TotalText>
      <TotalText variant="body">
        Services price:{' '}
        <Typography variant="smallTitleBold">
          {useNumberAnimation(totalAmountSelectedService, 300)} {currency}
        </Typography>
      </TotalText>
      <TotalText variant="body">
        Total price:{' '}
        <Typography variant="smallTitleBold">
          {useNumberAnimation(parseInt(flightPrice as string) + totalAmountSelectedService, 300)} {currency}
        </Typography>
      </TotalText>
    </StyledBoxPrices>
  );
});
