import React from 'react';
import { CasesOutlined, LuggageOutlined } from '@mui/icons-material';
import { Typography } from 'components';
import { BaggageAllowanceModel } from 'models/baggage.model';
import { multipleOrSingleWordEnding } from 'utils/strings';
import { Wrapper } from './styles';

interface Props {
  baggageAllowance?: BaggageAllowanceModel | null;
}
export const Baggage = ({ baggageAllowance }: Props) => {
  const baggageWeight = baggageAllowance?.checkedWeight && baggageAllowance?.checkedWeightUnit; // i.e 30 KG
  const baggageCount = baggageAllowance?.checkedQuantity;

  if (!baggageWeight && !baggageCount) return <></>;

  return (
    <>
      {baggageWeight && (
        <Wrapper>
          <LuggageOutlined fontSize="small" />
          <Typography variant="body">
            {baggageAllowance?.checkedWeight} {baggageAllowance?.checkedWeightUnit}
          </Typography>
        </Wrapper>
      )}
      {baggageCount && (
        <Wrapper>
          <CasesOutlined fontSize="small" />
          <Typography variant="body">
            {baggageCount} {multipleOrSingleWordEnding('Checked Bag', baggageCount > 1)}
          </Typography>
        </Wrapper>
      )}
    </>
  );
};
