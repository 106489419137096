export enum DirectionEnum {
  ONE_WAY = 'one',
  ROUND = 'round',
  MULTI = 'multi',
}

export enum TicketClassEnum {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
  FIRST = 'FIRST',
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum GraphQlRequestError {
  PRICE_OFFER_TYPE = 'PricingError',
  JOURNEY_OFFER_NOT_AVAILABLE = 'JourneyOfferNotAvailable',
}

export enum PaymentStatusEnum {
  ERROR = 'ERROR',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  AUTHORIZED = 'AUTHORIZED',
  SUCCEEDED = 'SUCCEEDED',
  COMPLETE = 'COMPLETE',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}

export enum PaymentMode {
  STRIPE = 'stripe',
  WAAFI = 'waafi',
  AMADEUS = 'amadeus',
  ORCHESTRA = 'orchestra',
  MPESA_EXPRESS = 'mpesa_express',
  EDAHAB = 'edahab',
  MPESA_PAY_BILL = 'mpesa_paybill',
  VOUCHER = 'voucher',
}

export enum PassengerType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
}

export enum Currency {
  USD = 'USD',
  KES = 'KES',
}

export enum PaymentNetwork {
  EVC_PLUS = 'EVCplus',
  ZAAD = 'Zaad',
  SAHAL = 'Sahal',
  CREDIT_CARD = 'CreditCard',
  M_PESA_EXPRESS = 'M-Pesa-Express',
  VOUCHER = 'Voucher',
}

export enum PaymentNetworkTitle {
  EVC_PLUS = 'EVC Plus',
  ZAAD = 'Zaad',
  SAHAL = 'Sahal',
  CREDIT_CARD = 'Credit Card',
  M_PESA_EXPRESS = 'M-Pesa Express',
  VOUCHER = 'Voucher',
}

export enum RoutePage {
  HOME = '',
  OPTIONS = 'options',
  BOOKING = 'booking',
  SUMMARY = 'summary',
  PAYMENT_OPTIONS = 'paymentOptions',
  ENTRY = 'entry',
  APPROVAL = 'approval',
  SUCCESS = 'success',
  SERVICES = 'services',
  ORDER = 'order',
  ABOUT = 'about',
  PRIVACY_TERMS = 'privacyTerms',
  HELP = 'help',
  MY_TRIPS = 'mytrips',
  BLOG = 'blog',
  _404 = '404',
}

export enum FlightAmenities {
  WIFI = 'WIFI',
  POWER_OUTLETS = 'POWER_OUTLETS',
  EXTRA_LEGROOM = 'EXTRA_LEGROOM',
  PERSONAL_INFLIGHT_ENTERTAINMENT = 'PERSONAL_INFLIGHT_ENTERTAINMENT',
  MEAL_SERVICE = 'MEAL_SERVICE',
  SNACK_SERVICE = 'SNACK_SERVICE',
  BEVERAGE_SERVICE = 'BEVERAGE_SERVICE',
  PREMIUM_BEVERAGE_SERVICE = 'PREMIUM_BEVERAGE_SERVICE',
  PRIORITY_SERVICE = 'PRIORITY_SERVICE',
  MOVIE = 'MOVIE',
  TELEPHONE = 'TELEPHONE',
  TELEX = 'TELEX',
  AUDIO_PROGRAMMING = 'AUDIO_PROGRAMMING',
  LIVE_TV = 'LIVE_TV',
  RESERVATION_BOOKING_SERVICE = 'RESERVATION_BOOKING_SERVICE',
  DUTY_FREE_SALES = 'DUTY_FREE_SALES',
  SMOKING = 'SMOKING',
  NON_SMOKING = 'NON_SMOKING',
  SHORT_FEATURE_VIDEO = 'SHORT_FEATURE_VIDEO',
  NO_DUTY_FREE_SALES = 'NO_DUTY_FREE_SALES',
  IN_SEAT_POWER_SOURCE = 'IN_SEAT_POWER_SOURCE',
  INTERNET_ACCESS = 'INTERNET_ACCESS',
  EMAIL = 'EMAIL',
  IN_SEAT_VIDEO_PLAYER_LIBRARY = 'IN_SEAT_VIDEO_PLAYER_LIBRARY',
  LIE_FLAT_SEATS = 'LIE_FLAT_SEATS',
  LIE_FLAT_SEAT_FIRST = 'LIE_FLAT_SEAT_FIRST',
  LIE_FLAT_SEAT_BUSINESS = 'LIE_FLAT_SEAT_BUSINESS',
  LIE_FLAT_SEAT_PREMIUM_ECO = 'LIE_FLAT_SEAT_PREMIUM_ECO',
  AC_POWER_110V = 'AC_POWER_110V',
  AC_POWER_110V_FIRST = 'AC_POWER_110V_FIRST',
  AC_POWER_110V_BUSINESS = 'AC_POWER_110V_BUSINESS',
  AC_POWER_110V_PREMIUM_ECO = 'AC_POWER_110V_PREMIUM_ECO',
  AC_POWER_110V_ECONOMY = 'AC_POWER_110V_ECONOMY',
  USB_POWER = 'USB_POWER',
  USB_POWER_FIRST = 'USB_POWER_FIRST',
  USB_POWER_BUSINESS = 'USB_POWER_BUSINESS',
  USB_POWER_PREMIUM_ECO = 'USB_POWER_PREMIUM_ECO',
  USB_POWER_ECONOMY = 'USB_POWER_ECONOMY',
  AMENITIES_SUBJECT_TO_CHANGE = 'AMENITIES_SUBJECT_TO_CHANGE',
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
}

export enum JourneyOfferSource {
  NDC_APG = 'ndc-apg',
}
