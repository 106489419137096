import { Box } from '@mui/material';
import styled from '@emotion/styled';

export const ModalWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '12px',
}));

export const ButtonWrapper = styled('div')(() => ({
  marginTop: '16px',
  margin: '0 auto',
}));
