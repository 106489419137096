import React from 'react';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import {
  StyledAvatarBox,
  StyledBoxColContainer,
  StyledBoxColInfo,
  StyledBoxColMap,
  StyledBoxColMapContainer,
  StyledBoxColMapInner,
  StyledBoxColMapWrapper,
  StyledBoxContainer,
  StyledBoxMain,
  StyledBoxRow,
  StyledList,
  StyledListItem,
  StyledListItemAvatar,
  StyledTypography,
} from './styles';

const contactsList = [
  {
    label: 'WhatsApp',
    value: '+19165532729',
    icon: (
      <Link href="https://wa.me/19165532729">
        <WhatsAppIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Email',
    value: 'info@fasax.com',
    icon: (
      <Link href="mailto:info@fasax.com">
        <AlternateEmailIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Facebook',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://www.facebook.com/fasaxtravel">
        <FacebookOutlinedIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Twitter',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://twitter.com/FasaxTravel">
        <TwitterIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Instagram',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://instagram.com/FasaxTravel">
        <InstagramIcon color="primary" />
      </Link>
    ),
  },
];

export const Contact = () => {
  const theme = useTheme();

  const LeftSide = () => {
    return (
      <Box>
        <Box marginBottom={2}>
          <StyledTypography variant={'h4'} gutterBottom>
            Contact us
          </StyledTypography>
          <Typography color="text.secondary">
            If you are interested in partnering with Fasax, have questions, or just want to chat, you can reach us at:
          </Typography>
        </Box>
        <StyledList>
          {contactsList.map(({ label, value, icon }) => (
            <StyledListItem key={label} component={ListItem} disableGutters>
              <StyledListItemAvatar component={ListItemAvatar}>
                <StyledAvatarBox component={Avatar}>{icon}</StyledAvatarBox>
              </StyledListItemAvatar>
              <ListItemText primary={label} secondary={value} />
            </StyledListItem>
          ))}
        </StyledList>
      </Box>
    );
  };

  const RightSide = () => {
    return (
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        title="map"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.3761839936915!2d44.07184362688541!3d9.56280377124748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1628bfbfe498cf1b%3A0xefa8edadc1e2f311!2sGacan%20Jilib%20St%202-34%2C%20Hargeisa!5e0!3m2!1sen!2sso!4v1662161265617!5m2!1sen!2sso"
        style={{
          minHeight: 300,
          filter: theme.palette.mode === 'dark' ? 'grayscale(0.5) opacity(0.7)' : 'none',
        }}
      />
    );
  };

  return (
    <StyledBoxMain>
      <StyledBoxContainer>
        <StyledBoxRow>
          <StyledBoxColInfo>
            <StyledBoxColContainer>
              <LeftSide />
            </StyledBoxColContainer>
          </StyledBoxColInfo>
          <StyledBoxColMap>
            <StyledBoxColMapContainer>
              <StyledBoxColMapWrapper>
                <StyledBoxColMapInner>
                  <RightSide />
                </StyledBoxColMapInner>
              </StyledBoxColMapWrapper>
            </StyledBoxColMapContainer>
          </StyledBoxColMap>
        </StyledBoxRow>
      </StyledBoxContainer>
      <Divider />
    </StyledBoxMain>
  );
};
