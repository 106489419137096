import React, { createContext, useContext, useState } from 'react';

interface SelectedIdsContextProps {
  selectedIds: string[];
  setSelectedIds: (services: string[]) => void;
}

const SelectedIdsContext = createContext<SelectedIdsContextProps | undefined>(undefined);

export const SelectedIdsProvider: React.FC = ({ children }) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  return <SelectedIdsContext.Provider value={{ selectedIds, setSelectedIds }}>{children}</SelectedIdsContext.Provider>;
};

export const useGetSelectedServiceData = () => {
  const context = useContext(SelectedIdsContext);
  if (context === undefined) {
    throw new Error('useSelectedIds must be used within a SelectedIdsProvider');
  }
  return context;
};
