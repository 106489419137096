import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { TextField } from '@mui/material';

interface IProps {
  control: Control<any>;
  name: string;
  error?: FieldError;
}

export const Input = ({ control, name, error, ...props }: IProps & Omit<TextFieldProps, 'error'>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ...other } }) => {
        return <TextField {...props} {...other} error={!!error} helperText={error?.message} variant="outlined" fullWidth />;
      }}
    />
  );
};
