import React, { ReactElement } from 'react';
import { IconModel } from 'models/icon.model';

export const LogoIcon = ({ width = 30, height = 30 }: IconModel): ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.59322 14.9617L2.85927 20.703C2.1473 21.4201 1.74768 22.3902 1.74768 23.4014C1.74768 24.4126 2.1473 25.3826 2.85927 26.0998L3.85316 27.0949C4.56937 27.8078 5.53823 28.2079 6.54811 28.2079C7.558 28.2079 8.52685 27.8078 9.24306 27.0949L14.977 21.3537H14.9388L20.6727 27.0949C21.3889 27.8078 22.3578 28.2079 23.3677 28.2079C24.3776 28.2079 25.3464 27.8078 26.0626 27.0949L27.0565 26.0998C27.7685 25.3826 28.1681 24.4126 28.1681 23.4014C28.1681 22.3902 27.7685 21.4201 27.0565 20.703L21.3226 14.9617"
        fill="#F7D633"
      />
      <path d="M15.1338 9.56494L22.091 15H8.13843L15.1338 9.56494Z" fill="#F7D633" />
      <path
        d="M9.28518 2.86683C8.56896 2.15395 7.60011 1.75381 6.59023 1.75381C5.58034 1.75381 4.61149 2.15395 3.89527 2.86683L2.90139 3.86198C2.18942 4.57911 1.78979 5.5492 1.78979 6.56037C1.78979 7.57154 2.18942 8.54163 2.90139 9.25876L8.63533 15L15.0191 8.60809L9.28518 2.86683Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59322 15L2.85927 20.7413C2.1473 21.4584 1.74768 22.4285 1.74768 23.4397C1.74768 24.4508 2.1473 25.4209 2.85927 26.138L3.85316 27.1332C4.56937 27.8461 5.53823 28.2462 6.54811 28.2462C7.558 28.2462 8.52685 27.8461 9.24306 27.1332L14.977 21.3919H14.9388L20.6727 27.1332C21.3889 27.8461 22.3578 28.2462 23.3677 28.2462C24.3776 28.2462 25.3464 27.8461 26.0626 27.1332L27.0565 26.138C27.7685 25.4209 28.1681 24.4508 28.1681 23.4397C28.1681 22.4285 27.7685 21.4584 27.0565 20.7413L21.3226 15"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.133 9.25876C27.8449 8.54163 28.2446 7.57154 28.2446 6.56037C28.2446 5.5492 27.8449 4.57911 27.133 3.86198L26.1047 2.86683C25.3885 2.15395 24.4196 1.75381 23.4097 1.75381C22.3998 1.75381 21.431 2.15395 20.7148 2.86683L14.9808 8.60809L18.727 12.359L21.3646 15L27.133 9.25876Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
