import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferralCodeModel } from 'models/referralCode.model';

const initialState: ReferralCodeModel = {
  refCode: null,
};

export const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setReferralCode(state, action: PayloadAction<ReferralCodeModel>) {
      return (state = action.payload);
    },
  },
});
export const { setReferralCode } = metaSlice.actions;
