import React from 'react';
import { AmenityIconType } from './Amenities';

export const getUniqueIcons = (amenityTitles: string[], amenityIcon: Partial<AmenityIconType>): React.ReactNode[] => {
  const seenIcons = new Set<React.ReactNode>();
  const uniqueIcons: React.ReactNode[] = [];

  amenityTitles.forEach((title) => {
    const icon = amenityIcon[title as keyof AmenityIconType];

    if (icon && typeof icon === 'object' && 'type' in icon && typeof icon.type !== 'string') {
      if (!seenIcons.has(icon.type)) {
        seenIcons.add(icon.type);
        uniqueIcons.push(icon);
      }
    }
  });

  return uniqueIcons;
};
