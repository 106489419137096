import React, { useEffect, useState, SyntheticEvent } from 'react';
import { CircularProgress } from '@mui/material';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { routes } from 'routes';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { RoutePage } from 'enum/enum';
import { useDispatch } from 'react-redux';

interface CheckoutFormProps {
  stripeClientSecret: string;
}
export const CheckoutForm = ({ stripeClientSecret }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const hostname = process.env.REACT_APP_CREDIT_CARD_RETURN_URL
    ? process.env.REACT_APP_CREDIT_CARD_RETURN_URL
    : 'https://' + window.location.hostname + routes.success;

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const retrievePaymentIntentHandler = async () => {
    if (!stripe) {
      return;
    }
    await stripe.retrievePaymentIntent(stripeClientSecret);
    dispatch(allowPageAccess(RoutePage.SUCCESS));
  };

  useEffect(() => {
    if (!elements) {
      return;
    }
    if (!stripe) {
      return;
    }

    if (!stripeClientSecret) {
      return;
    }
    retrievePaymentIntentHandler();
  }, [stripe, elements]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return <CircularProgress />;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: String(hostname),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error?.message || 'Error');
    } else {
      setMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  return (
    <form id="stripe-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button id="stripe-button" disabled={isLoading || !stripe || !elements}>
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner" /> : 'Pay Now'}</span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
