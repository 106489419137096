import React, { ReactElement } from 'react';
import { IconModel } from 'models/icon.model';

export const ArrowRightIcon = ({ height = 13, width = 20, className = '' }: IconModel): ReactElement => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0L11.59 1.30929L16.17 5.57143H0V7.42857H16.17L11.58 11.6907L13 13L20 6.5L13 0Z" fill="white" />
    </svg>
  );
};
