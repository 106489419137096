import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { PRICE_OFFER, PRICE_OFFER_data, PRICE_OFFER_vars } from 'api/queries/priceOffer';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { exceptionMessages } from 'constants/exceptionMessages';
import { Currency, GraphQlRequestError, RoutePage } from 'enum/enum';
import { ExceptionMessage, Loader, Typography } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { setCurrentTicketPriceOfferState, setPriceOffer } from 'redux/slices/flightOffer/priceOffer-slice';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { setJourneyOptionDetails } from 'redux/slices/journeyOption/journeyOption-slice';
import { FlightOption } from './FlightOption';
import { JourneyOffers } from './styles';

interface FlightOptionListProps {
  items: JourneyOfferModel[];
  refetchFlightOptions: () => void;
}

export const FlightOptionList = ({ items, refetchFlightOptions }: FlightOptionListProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { direction } = useAppSelector((state) => state.search);
  const { currency } = useAppSelector((state) => state.payment);
  const [setPriceOfferRequest, { loading: isLoadingPriceOffer, data }] = useLazyQuery<PRICE_OFFER_data, PRICE_OFFER_vars>(PRICE_OFFER);

  const hasFlightOptions = !!items?.length;

  const hasPriceOfferError =
    data?.priceOffer[0]?.__typename === GraphQlRequestError.PRICE_OFFER_TYPE ||
    data?.priceOffer[0]?.__typename === GraphQlRequestError.JOURNEY_OFFER_NOT_AVAILABLE;

  const onSelectFlightOption = async (e: React.MouseEvent<HTMLElement>, id: string, journeyOption: JourneyOfferModel) => {
    e.preventDefault();
    const priceOfferData = await setPriceOfferRequest({
      variables: {
        journeyUUID: id,
        addedServices: [],
        currency: currency ?? Currency.USD,
      },
    });

    // early return for exception, if API can't price the offer
    if (
      !priceOfferData?.data?.priceOffer?.length ||
      priceOfferData.data.priceOffer[0]?.__typename === GraphQlRequestError.PRICE_OFFER_TYPE ||
      priceOfferData?.data.priceOffer[0]?.__typename === GraphQlRequestError.JOURNEY_OFFER_NOT_AVAILABLE
    )
      return;
    dispatch(setCurrentTicketPriceOfferState(journeyOption.priceQuote));
    dispatch(setPriceOffer(priceOfferData.data.priceOffer[0]));
    dispatch(allowPageAccess(RoutePage.BOOKING));
    dispatch(setJourneyOptionDetails({ journeyOption: journeyOption, direction }));
    dispatch(setCurrentTicketPriceOfferState(priceOfferData?.data?.priceOffer[0]?.priceQuote));
    history.push(routes.booking, { access: true });
  };

  const onRedirectHome = () => {
    return history.push(routes.home);
  };

  if (isLoadingPriceOffer) return <Loader />;
  if (hasPriceOfferError) {
    return (
      <ExceptionMessage
        buttonName={'Back to flight search'}
        goBackHandler={refetchFlightOptions}
        exceptionText={exceptionMessages.PRICE_OFFER_ISSUE}
      />
    );
  }
  if (!hasFlightOptions) {
    return <ExceptionMessage goBackHandler={onRedirectHome} exceptionText={exceptionMessages.RECOMMENDATION_NOT_FOUND} />;
  }

  return (
    <JourneyOffers data-testid="flight-options-page">
      <>
        <Typography variant="smallTitle">Flight Options:</Typography>
        {items.map((option) => (
          <FlightOption key={option.id} {...option} onSelectFlightOption={(e) => onSelectFlightOption(e, option.id, option)} />
        ))}
      </>
    </JourneyOffers>
  );
};
