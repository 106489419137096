import { styled } from '@mui/material';
import { breakpoints } from 'style';

export const ButtonsBlock = styled('div')`
  display: flex;
  gap: 8px;
  margin-top: 24px;
  width: 100%;

  button:nth-of-type(1) {
    flex: 1;
  }

  button:nth-of-type(2) {
    flex: 2;
  }

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column-reverse;
  }
`;
