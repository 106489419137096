import React, { useState } from 'react';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';

import { DirectionEnum } from 'enum/enum';
import { Modal } from 'components';
import { convertFullDate, getNextDay, isLastDayOfMonth } from 'utils/dates';
import { multipleOrSingleWordEnding } from 'utils/strings';
import { InputHome } from 'components/pages/home/InputHome';
import { SearchModel } from 'models/search.model';

import { useRequestRecommendationDates } from './useRequestRecommendationDates';
import { DatepickerRanged, DatepickerSingle, SelectionDateWrapper } from './styles';

interface PropTypes {
  control: Control<any>;
  name: string;
  direction: DirectionEnum;
  inputValue: string | string[];
  error: boolean;
  setValue: UseFormSetValue<FieldValues>;
  index: number;
  returnDate: string | null;
  clearErrors: UseFormClearErrors<SearchModel>;
  arrivalAirport: string;
  departureAirport: string;
}

interface CalendarValue {
  value: null | Date;
}

export const SelectionDates = ({
  control,
  name,
  direction,
  inputValue,
  error,
  setValue,
  index,
  returnDate,
  clearErrors,
  departureAirport,
  arrivalAirport,
}: PropTypes) => {
  const isMulti = direction === DirectionEnum.MULTI;
  const isOneWay = direction === DirectionEnum.ONE_WAY;
  const isRoundWay = direction === DirectionEnum.ROUND;
  const initialMonth = isLastDayOfMonth() ? getNextDay() : new Date();

  const [isOpenCalendarSingle, setIsOpenCalendarSingle] = useState<boolean>(false);
  const [isOpenCalendarRange, setIsOpenCalendarRange] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);

  const { renderRangePickerDay, renderSinglePickerDay } = useRequestRecommendationDates({
    arrivalAirport,
    departureAirport,
    currentMonth,
  });

  const setCurrentMonthHandler = (curr: Date) => setCurrentMonth(curr);

  const disableCurrentDate = (day: Date) => convertFullDate(day) === convertFullDate(new Date());

  const closeRangeCalendarRange = () => setIsOpenCalendarRange(false);

  const closeRangeCalendarSingle = () => setIsOpenCalendarSingle(false);

  const onSelectionDateClick = () => {
    return isOneWay || isMulti ? setIsOpenCalendarSingle(true) : setIsOpenCalendarRange(true);
  };

  const toggleCalendarModal = () => {
    setIsOpenCalendarSingle(!isOpenCalendarSingle);
  };

  const commonPickerProps = {
    disabled: true,
    shouldDisableDate: (day: Date) => disableCurrentDate(day),
    disableHighlightToday: true,
    disablePast: true,
    renderInput: () => <></>,
    defaultCalendarMonth: initialMonth,
    onMonthChange: setCurrentMonthHandler,
  };

  return (
    <>
      <SelectionDateWrapper data-testid="select-date" onClick={onSelectionDateClick}>
        <InputHome
          id={'date-selection'}
          hasError={error}
          placeholder={multipleOrSingleWordEnding('Select Travel Date', isRoundWay)}
          value={inputValue}
          onClickIcon={toggleCalendarModal}
          startIcon={<DateRangeIcon />}
          endIcon={<ModeEditOutlineIcon />}
        />
      </SelectionDateWrapper>
      <Modal
        showModalHeaderWithBackground={false}
        open={isOneWay || isMulti ? isOpenCalendarSingle : isOpenCalendarRange}
        onClose={isOneWay || isMulti ? closeRangeCalendarSingle : closeRangeCalendarRange}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name={name}
            render={({ field: { ...rest } }) => {
              return !isRoundWay ? (
                <DatepickerSingle
                  {...rest}
                  {...commonPickerProps}
                  renderDay={renderSinglePickerDay}
                  orientation={'portrait'}
                  open={isOpenCalendarSingle}
                  onClose={closeRangeCalendarSingle}
                />
              ) : (
                <DatepickerRanged
                  {...rest}
                  {...commonPickerProps}
                  renderDay={renderRangePickerDay}
                  value={[rest.value ? rest.value : null, returnDate]}
                  onChange={(value: RangeInput<CalendarValue[]>) => {
                    setValue(name, value[0]);
                    setValue(`ItineraryRequest.${index}.returnDate`, value[1]);
                    if (value[1]) {
                      clearErrors(`ItineraryRequest.${index}.returnDate`);
                    }
                  }}
                  open={isOpenCalendarRange}
                  onClose={closeRangeCalendarRange}
                  onBlur={closeRangeCalendarRange}
                  onDismiss={closeRangeCalendarRange}
                />
              );
            }}
          />
        </LocalizationProvider>
      </Modal>
    </>
  );
};
