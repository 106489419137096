import React from 'react';
import { FormControl, InputLabel, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { MuiSelect } from './styles';

interface IOption {
  title: string;
  value: string | number;
}

export interface SelectType extends Omit<SelectProps, 'onChange'> {
  options: IOption[];
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

export const ManualSelect = ({ options, name, label, onChange, ...props }: SelectType) => {
  return (
    <FormControl>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <MuiSelect {...props} labelId={name} onChange={onChange}>
        {options.map(({ title, value }) => {
          return (
            <MenuItem key={title} value={value}>
              {title}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};
