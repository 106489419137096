import { Accordion, styled } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    gap: '12px',
  },
}));
export const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  &.MuiAccordionDetails-root {
    padding-bottom: 8px;
  }
`;

export const AmenityItem = styled('div')`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiPaper-root {
    border-radius: 4px;

    &:before {
      display: none;
    }
  }

  & .MuiAccordionSummary-content {
    display: flex;
    flex-wrap: wrap;
  }
`;
