import { styled } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { colors } from 'style';

export const AutocompleteStyled = styled(Autocomplete)<any>`
  .MuiFormControl-root {
    border-radius: 4px;
    ${(props) =>
      props.error &&
      `
        border: 1px solid ${colors.error};
      `}
    transition: 0.8ms border ease-in;
  }
  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiFilledInput-root {
    padding: 0 0 0 16px !important;

    & input {
      padding: 0 0 0 14px !important;
    }

    &:before {
      border-bottom: none !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
  .MuiFilledInput-input {
    height: 56px;
  }
`;
