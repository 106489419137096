import React, { useMemo, useState } from 'react';
import { ServiceModel } from 'models/service.model';
import { serviceCategories, serviceIcons } from 'constants/serviceIcons';
import { getAmountCurrency } from 'utils/currency';
import { ManualAutocomplete, Typography } from 'components';

import { useGetSelectedServiceData } from './useSelectServicesContext';
import { GroupedServiceTitle, ServiceOptions } from './styles';

interface GroupedServiceProps {
  title: string;
  items: ServiceModel[];
}

export const GroupedServiceItem = ({ title, items }: GroupedServiceProps) => {
  const [serviceValue, setServiceValue] = useState('');
  const { setSelectedIds, selectedIds } = useGetSelectedServiceData();
  const icon = serviceIcons[title as keyof typeof serviceCategories];

  const getCategoryServiceOptions = useMemo(() => {
    return items.map((o) => {
      const price = getAmountCurrency(o.priceQuote);
      const title = `${o.description} - ${price}`;
      return { uuid: o.id, name: title };
    });
  }, [items]);

  const onSelectChange = (currValue: string) => {
    setServiceValue(currValue);
    const index = items.findIndex((service) => selectedIds.includes(service.id));

    if (index !== -1) {
      const newSelectedIds = selectedIds.filter((id) => id !== items[index].id);
      if (currValue) {
        newSelectedIds.push(currValue);
      }
      setSelectedIds(newSelectedIds);
    } else {
      if (currValue) {
        setSelectedIds([...selectedIds, currValue]);
      }
    }
  };

  return (
    <div>
      <GroupedServiceTitle>
        {icon}
        {title}
      </GroupedServiceTitle>
      <ServiceOptions>
        <Typography variant="body">Add service: </Typography>
        <ManualAutocomplete
          value={serviceValue}
          settingKeyName="uuid"
          label="Service"
          onChange={(v) => {
            onSelectChange(v);
          }}
          options={getCategoryServiceOptions}
        />
      </ServiceOptions>
    </div>
  );
};
