import React, { FC, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export interface ToggleType {
  checked: boolean;
  label: string;
  onChangeCallBack: (statusChecked: boolean) => void;
}

export const Toggle: FC<ToggleType> = ({ checked, label, onChangeCallBack }) => {
  const [statusChecked, setStatusChecked] = useState(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusChecked(!statusChecked);
    onChangeCallBack(!statusChecked);
  };

  return <FormControlLabel control={<Switch checked={statusChecked} onChange={handleChange} />} label={label} />;
};
