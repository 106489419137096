import { Currency, PaymentMode, PaymentNetwork, PaymentNetworkTitle, PaymentStatusEnum } from 'enum/enum';
import evcplusLogo from 'assets/img/evcplus.png';
import zaadLogo from 'assets/img/zaad.jpg';
import golisLogo from 'assets/img/golis.png';
import creditCards from 'assets/img/creditCards.png';
import mPressaExpress from 'assets/img/m_pesa_express.png';
import waafi from 'assets/img/waafi.png';
import mpesa from 'assets/img/mpesa.png';
import voucher from 'assets/img/voucher.png';
import visa from 'assets/img/visa.png';
import edhabLogo from 'assets/img/edahab.png';
import mastercard from 'assets/img/mastercard.png';
import amex from 'assets/img/amex.png';
import discover from 'assets/img/discover.png';
import unionpay from 'assets/img/unionpay.png';
import jcb from 'assets/img/jcb.png';
import stripe from 'assets/img/stripe.png';
import dinersclub from 'assets/img/dinersclub.png';
import { isDauusDomain } from 'utils/dauus';

export const isMpesaAvailable = JSON.parse(process.env.REACT_APP_MPESA_PAYMENT_AVAILABLE ?? 'false');
const isVoucher = isDauusDomain();

export const payments = [
  {
    network: PaymentNetwork.EVC_PLUS,
    title: PaymentNetworkTitle.EVC_PLUS,
    img: evcplusLogo,
    paymentMode: PaymentMode.WAAFI,
  },
  { network: PaymentNetwork.ZAAD, title: PaymentNetworkTitle.ZAAD, img: zaadLogo, paymentMode: PaymentMode.WAAFI },
  { network: PaymentNetwork.SAHAL, title: PaymentNetworkTitle.SAHAL, img: golisLogo, paymentMode: PaymentMode.WAAFI },
  ...(isMpesaAvailable
    ? [
        {
          network: PaymentNetwork.M_PESA_EXPRESS,
          title: PaymentNetworkTitle.M_PESA_EXPRESS,
          img: mPressaExpress,
          paymentMode: PaymentMode.MPESA_EXPRESS,
        },
      ]
    : []),
  {
    network: PaymentNetwork.CREDIT_CARD,
    title: PaymentNetworkTitle.CREDIT_CARD,
    img: creditCards,
    paymentMode: PaymentMode.STRIPE,
  },
  ...(isVoucher
    ? [
        {
          network: PaymentNetwork.VOUCHER,
          title: PaymentNetworkTitle.VOUCHER,
          img: voucher,
          paymentMode: PaymentMode.VOUCHER,
        },
      ]
    : []),
];

export const currencyOptions = [
  { title: Currency.USD, value: Currency.USD },
  { title: Currency.KES, value: Currency.KES },
];
export const getCardTypes = (isVoucher: boolean) => {
  const cardTypes = [
    { cardBrand: 'waafi', img: waafi },
    { cardBrand: 'mpesa', img: mpesa },
    { cardBrand: 'visa', img: visa },
    { cardBrand: 'Edahab', img: edhabLogo },
    { cardBrand: 'mastercard', img: mastercard },
    { cardBrand: 'amex', img: amex },
    { cardBrand: 'discover', img: discover },
    { cardBrand: 'unionpay', img: unionpay },
    { cardBrand: 'jcb', img: jcb },
    { cardBrand: 'stripe', img: stripe },
    { cardBrand: 'dinersclub', img: dinersclub },
  ];

  if (isVoucher) {
    cardTypes.push({ cardBrand: 'voucher', img: voucher });
  }

  return cardTypes;
};

export const successfulPaymentStatuses = [
  PaymentStatusEnum.SUCCEEDED,
  PaymentStatusEnum.AUTHORIZED,
  PaymentStatusEnum.PAID,
  PaymentStatusEnum.COMPLETE,
];

export const failedPaymentStatuses = [PaymentStatusEnum.CANCELLED, PaymentStatusEnum.FAILED, PaymentStatusEnum.ERROR];

export const pendingPaymentStatuses = [PaymentStatusEnum.NOT_PAID, PaymentStatusEnum.OPEN];
