import { Box, Skeleton, styled } from '@mui/material';

export const ImageWrapper = styled(Box)<{ maxWidth: number; height?: number }>`
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  ${(props) => `max-width: ${props.maxWidth}px;`}
  & img {
    ${(props) => (props.height ? `height: ${props.height}px;` : '')}
    ${(props) => `max-width: ${props.maxWidth}px;`}
    object-fit: cover;
  }
`;

export const SkeletonAvatar = styled(Skeleton)<{ maxWidth: number; height?: number }>`
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  ${(props) => `max-width: ${props.maxWidth}px;`}
`;

SkeletonAvatar.defaultProps = {
  variant: 'rectangular',
};
