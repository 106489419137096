import AirlineSeatReclineNormalRoundedIcon from '@mui/icons-material/AirlineSeatReclineNormalRounded';
import RestaurantMenuRoundedIcon from '@mui/icons-material/RestaurantMenuRounded';
import LuggageIcon from '@mui/icons-material/Luggage';
import WheelchairPickupRoundedIcon from '@mui/icons-material/WheelchairPickupRounded';

export const serviceCategories = {
  MEAL: 'MEAL',
  SEAT: 'SEAT',
  BAGGAGE: 'Checked Baggage',
  PAXINFO: 'PAXINFO',
};

export const serviceIcons = {
  [serviceCategories.PAXINFO]: <WheelchairPickupRoundedIcon />,
  [serviceCategories.BAGGAGE]: <LuggageIcon />,
  [serviceCategories.MEAL]: <RestaurantMenuRoundedIcon />,
  [serviceCategories.SEAT]: <AirlineSeatReclineNormalRoundedIcon />,
};
