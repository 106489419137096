import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { JustifyCenter } from 'style';
import { routes } from 'routes';
import { getAmountCurrency } from 'utils/currency';
import { PriceModel } from 'models/price.model';
import { ActionButtons, Loader, MarginTop } from 'components';
import { SummaryItem } from 'components/pages/summary/SummaryItem/SummaryItem';
import { allowPageAccess, resetPageAccess } from 'redux/slices/accessability/accessability-slice';
import { JourneyOfferSource, RoutePage } from 'enum/enum';
import { capitalizeWord } from 'utils/strings';
import { useAppSelector } from 'hooks/useAppSelector';
import { ItinerariesDetailed } from 'components/ItinerariesDetailed/ItinerariesDetailed';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { SERVICE_LIST_data, SERVICE_LIST_vars, SERVICES_LIST } from 'api/queries/servicesList';
import { useFlightOrderRequest } from 'sharedRequest/useFlightOrderRequest';
import { journeyOffersSource } from 'constants/journeyOffersSource';

import { ContentWrapper } from './styles';

export const Summary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const journeyOption = useAppSelector((state) => state.journeyOption.journeyOption);
  const isAvailableJourneySource = journeyOffersSource.includes(journeyOption?.source as JourneyOfferSource);
  const passengersList = useAppSelector((state) => state.booking.passengers);
  const passengersCount = useAppSelector((state) => state.search.passengers);
  const id = useAppSelector((state) => state.priceOffer.priceOffer?.id);
  const priceQuote = useAppSelector((state) => state.priceOffer.priceOffer?.priceQuote);
  const { cabin } = useAppSelector((state) => state.search);
  const [isCreateOrderLoading, setIsCreateOrderLoading] = useState<boolean>(false);

  const price = getAmountCurrency(priceQuote as PriceModel);

  const { loading: isLoadingServices, data: servicesData } = useQuery<SERVICE_LIST_data, SERVICE_LIST_vars>(SERVICES_LIST, {
    variables: { journeyUUID: journeyOption?.id as string },
    skip: !isAvailableJourneySource,
  });
  const isServices = !!servicesData?.serviceList?.length && isAvailableJourneySource;
  const createFlightOrderRequest = useFlightOrderRequest(passengersList, passengersCount);

  const goBackHandler = () => {
    dispatch(resetPageAccess(RoutePage.SUMMARY));
    history.push(routes.booking, { access: true });
  };

  const createFlightHandler = async () => {
    setIsCreateOrderLoading(true);
    if (!isServices) {
      await createFlightOrderRequest(id);
      dispatch(allowPageAccess(RoutePage.PAYMENT_OPTIONS));
      history.push(routes.paymentOptions, { access: true });
    } else {
      dispatch(allowPageAccess(RoutePage.SERVICES));
      history.push(routes.services, { access: true });
    }
    setIsCreateOrderLoading(false);
  };

  if (isLoadingServices || isCreateOrderLoading) return <Loader />;

  return (
    <div data-testid="summary-page">
      <Typography fontSize={18} lineHeight={'21px'} fontWeight={300}>
        Check Order Summary:
      </Typography>

      <>
        <MarginTop marginTop={'28'}>
          <ContentWrapper>
            <ItinerariesDetailed
              journeyOffer={journeyOption as JourneyOfferModel}
              cabin={capitalizeWord(cabin)}
              price={price}
              hasFooterButton={false}
            />
          </ContentWrapper>
        </MarginTop>
        <MarginTop marginTop={'28'}>
          {passengersList?.map((item, index: number) => {
            return <SummaryItem key={`${item.firstName}${item.lastName}`} {...item} passengerCount={index + 1} goBackHandler={goBackHandler} />;
          })}
        </MarginTop>
        <JustifyCenter>
          <ActionButtons submitButtonName={isServices ? 'Continue To Services' : 'Continue To Payment'} onSubmit={createFlightHandler} />
        </JustifyCenter>
      </>
    </div>
  );
};
