import { Paper, styled } from '@mui/material';
import { colors } from 'style';

export const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.white};
  gap: 6px;
  width: 100%;
  padding: 12px;
`;
