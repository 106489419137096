import { Box, styled, ListItemProps, ListItemAvatarProps, Typography } from '@mui/material';

type StyledListItemProps = ListItemProps & {
  disableGutters?: boolean;
};

type StyledListItemAvatarProps = ListItemAvatarProps & {
  minWidth?: string;
  marginRight?: number | string;
};

export const StyledBoxMain = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledBoxContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 1236px;
  }
`;

export const StyledBoxRow = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-direction: row;
  }
`;

export const StyledBoxColInfo = styled(Box)`
  width: 100%;
  order: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    order: 1;
  }
`;

export const StyledBoxColMap = styled(Box)`
  flex: 0 0 100%;
  position: relative;
  max-width: 100%;
  order: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex: 0 0 50%;
    max-width: 50%;
    order: 2;
  }
`;

export const StyledBoxColContainer = styled(Box)`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    max-width: 1236px;
    padding-top: ${({ theme }) => theme.spacing(8)};
    padding-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

export const StyledBoxColMapContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 50vw;
  }
`;

export const StyledBoxColMapWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledBoxColMapInner = styled(Box)`
  overflow: hidden;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    position: absolute;
  }
`;

export const StyledList = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledListItem = styled(Box)<StyledListItemProps>`
  width: auto;
  padding: 0;
`;

export const StyledListItemAvatar = styled(Box)<StyledListItemAvatarProps>`
  min-width: auto !important;
  margin-right: 16px;
`;

export const StyledAvatarBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 40px;
  height: 40px;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 700;
`;
