import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Modal } from 'components';
import { routes } from 'routes';
import { resetSearchState } from 'redux/slices/search/search-slice';
import { resetAllPagesAccess } from 'redux/slices/accessability/accessability-slice';

import { ButtonWrapper, ModalWrapper } from './styles';

type IntervalId = ReturnType<typeof setInterval>;

export const TTLModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [expirationTime, setExpirationTime] = useState(0);
  const MODAL_EXPIRATION_TIME = Number(process.env.REACT_APP_TTL_MILLISECONDS);
  const intervalIdRef = useRef<IntervalId | null>(null);

  useEffect(() => {
    const currentTime = Date.now();
    const expirationTimeStr = sessionStorage.getItem('modalExpirationTime');
    if (expirationTimeStr && Number(expirationTimeStr) < currentTime) {
      setShowModal(true);
    }
    if (!expirationTimeStr) {
      sessionStorage.setItem('modalExpirationTime', (currentTime + MODAL_EXPIRATION_TIME).toString());
      setExpirationTime(MODAL_EXPIRATION_TIME + currentTime);
    }
  }, []);

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      const currentTime = Date.now();
      if (expirationTime < currentTime) {
        if (expirationTime === 0) return;
        setShowModal(true);
      }
    }, 1000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [expirationTime]);

  const handleModalConfirm = () => {
    const currentTime = Date.now();
    setExpirationTime(currentTime + MODAL_EXPIRATION_TIME);
    sessionStorage.setItem('modalExpirationTime', (currentTime + MODAL_EXPIRATION_TIME).toString());
    setShowModal(false);
    dispatch(resetAllPagesAccess());
    dispatch(resetSearchState());
    history.push(routes.home);
  };

  return (
    <>
      {showModal && (
        <Modal onClose={handleModalConfirm} open={showModal}>
          <ModalWrapper>
            <p>
              Please note that prices for tickets may have changed since your last search. For the most up-to-date pricing information, please click
              on the Confirm button and return to the homepage to start a new search. Thank you for using our ticketing service!
            </p>
            <ButtonWrapper>
              <Button variant="contained" onClick={handleModalConfirm}>
                Confirm
              </Button>
            </ButtonWrapper>
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
};
