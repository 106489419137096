import React from 'react';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { ItineraryDetailed } from 'components/ItinerariesDetailed/ItineraryDetailed/ItineraryDetailed';
import { ItineraryDetailedList } from './styles';

interface ItinerariesDetailedProps {
  journeyOffer: JourneyOfferModel;
  price: string;
  cabin: string;
  hasFooterButton: boolean;
  onFooterSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ItinerariesDetailed = ({ journeyOffer, price, cabin, hasFooterButton, onFooterSubmit }: ItinerariesDetailedProps) => {
  const { baggageAllowance, itineraries } = journeyOffer;

  return (
    <ItineraryDetailedList>
      {itineraries.map((itinerary, index) => {
        return (
          <ItineraryDetailed
            key={itinerary.id}
            segments={itinerary.segments}
            baggageAllowance={baggageAllowance}
            price={price}
            cabin={cabin}
            changeOfDate={itinerary.changeOfDate}
            hasFooterButton={hasFooterButton}
            hasFooter={index === itineraries.length - 1}
            onFooterSubmit={onFooterSubmit}
          />
        );
      })}
    </ItineraryDetailedList>
  );
};
