import React from 'react';
import { SegmentModel } from 'models/segment.model';
import { BaggageAllowanceModel } from 'models/baggage.model';
import { Typography } from 'components';
import { TechnicalStops } from 'components/ItinerariesDetailed/TechnicalStops';
import { Amenities } from 'components/ItinerariesDetailed/Amenities';
import { Baggage } from 'components/ItinerariesDetailed/Baggage';
import { convertDateForFlight, convertHoursMinutes, convertShortDate, parseISO8601Time } from 'utils/dates';
import { getChangeDateCount } from 'utils/strings';

import {
  ArrivesWithChangeDate,
  ConnectionDetailsSection,
  DurationTimeText,
  FlightLineLeft,
  FlightLineRight,
  SegmentDetailedContent,
  SegmentDetailedItemContainer,
  SegmentDetailedLine,
  SegmentDetailedShortNames,
  SegmentDetailedTimes,
} from './styles';
import { getShortNameAirport, useGetInformationDetails } from './helper';
import { FareDescription } from '../FareDescription';
import { ConnectionDetails } from '../ConnectionDetails';
import { ConnectionDetailsContainer } from '../ConnectionDetails/styles';

export interface Props {
  baggageAllowance?: BaggageAllowanceModel | null;
  startDepartureDate: Date;
}

export const SegmentDetailedItem = ({
  departureAt,
  arrivalAt,
  originAirport,
  destinationAirport,
  amenities,
  technicalStops,
  fareDescription,
  connectionDetails,
  changeOfDate,
  duration,
  baggageAllowance,
  startDepartureDate,
}: SegmentModel & Props) => {
  const { hasConnectionDetailsItems, formedArrayConnectionDetails } = useGetInformationDetails(connectionDetails);
  const durationTime = parseISO8601Time(duration);
  const isSameDeparture = convertShortDate(startDepartureDate) === convertShortDate(departureAt);
  const isSameArrival = convertShortDate(startDepartureDate) === convertShortDate(arrivalAt);
  return (
    <>
      <SegmentDetailedItemContainer>
        <SegmentDetailedContent>
          <SegmentDetailedShortNames>
            <Typography variant="subtitle">{originAirport?.code}</Typography>
            <Typography variant="subtitle">{destinationAirport?.code}</Typography>
          </SegmentDetailedShortNames>
          <SegmentDetailedTimes>
            <Typography variant="adaptiveText">{convertHoursMinutes(departureAt)}</Typography>
            <FlightLineLeft />
            {durationTime && <DurationTimeText variant="adaptiveText">{durationTime}</DurationTimeText>}
            <FlightLineRight />
            <ArrivesWithChangeDate>
              <Typography variant="adaptiveText">{convertHoursMinutes(arrivalAt)}</Typography>
              <Typography variant="smallBody">{getChangeDateCount(changeOfDate)}</Typography>
            </ArrivesWithChangeDate>
          </SegmentDetailedTimes>
          <SegmentDetailedLine>
            <Typography variant={isSameDeparture ? 'adaptiveText' : 'adaptiveBoldText'}>{convertDateForFlight(departureAt)}</Typography>
            <Typography variant={isSameArrival ? 'adaptiveText' : 'adaptiveBoldText'}>{convertDateForFlight(arrivalAt)}</Typography>
          </SegmentDetailedLine>
          <SegmentDetailedLine>
            <Typography variant="adaptiveText">{getShortNameAirport(originAirport.name)}</Typography>
            <Typography variant="adaptiveText">{getShortNameAirport(destinationAirport.name)}</Typography>
          </SegmentDetailedLine>
        </SegmentDetailedContent>
        <ConnectionDetailsSection>
          <TechnicalStops technicalStops={technicalStops} />
          <Baggage baggageAllowance={baggageAllowance} />
          <Amenities amenities={amenities} />
          <FareDescription descriptions={fareDescription} />
        </ConnectionDetailsSection>
      </SegmentDetailedItemContainer>
      {!hasConnectionDetailsItems && (
        <SegmentDetailedItemContainer>
          <ConnectionDetailsSection>
            <ConnectionDetailsContainer>
              <ConnectionDetails connectionDetails={formedArrayConnectionDetails} />
            </ConnectionDetailsContainer>
          </ConnectionDetailsSection>
        </SegmentDetailedItemContainer>
      )}
    </>
  );
};
