import { DateType } from 'models/date.model';
import dayjs from 'dayjs';
import { regex } from 'validation/regex';

export const getTimeDifference = (first: Date, second: Date): string => {
  const start = dayjs(first);
  const end = dayjs(second);
  const diff = end.diff(start);
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${hours}h ${formattedMinutes}m`;
};

export const convertShortDate = (date: any): string => {
  return dayjs(date).isValid() ? dayjs(date).format('MMM DD') : '';
};

export const convertFullDate = (date: any): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const isLastDayOfMonth = () => {
  const today = dayjs();
  const lastDayOfMonth = today.endOf('month');
  return today.isSame(lastDayOfMonth, 'day');
};

export const getNextDay = () => {
  const currentDate = new Date();
  const nextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);
  return nextDay;
};

export const convertHoursMinutes = (date: DateType): string => {
  return dayjs(date).format('h:mm A');
};

export const convertDayMonth = (date: Date): string => {
  return dayjs(date).format('DD/MM');
};

export const convertDateForFlight = (date: Date): string => {
  return dayjs(date).format('dddd, MMM DD');
};

export const parseISO8601Time = (duration: string | null): string => {
  if (!duration) return '';

  const hasMatchDate = duration.match(regex.extractHoursWithMinutesByISO);
  const objDate = {
    hours: '',
    minutes: '',
  };

  if (hasMatchDate) {
    // if we get days in parsed data - we multiply days count * 24 hours + rest parsed hours
    // e.g
    // day - 1, hours - 5, minutes - 35
    // hours - (1 * 24) + 5 = 29 hours, 35 minutes
    const days = (parseFloat(hasMatchDate[3]) || 0).toString();
    const hours = (parseFloat(hasMatchDate[4]) || 0).toString();
    const minutes = parseFloat(hasMatchDate[5]) || 0;
    const formattedMinutes = minutes >= 10 ? minutes.toString() : `0${minutes}`;
    const totalHours = Number(days) * 24 + Number(hours);

    objDate.hours = totalHours.toString();
    objDate.minutes = formattedMinutes;
  } else {
    console.error('Invalid ISO 8601 duration format');
  }

  const { hours, minutes } = objDate;
  const formattedHours = +hours >= 1 ? `${hours}h` : '';
  const formattedMinutes = +minutes >= 1 ? `${minutes}m` : '';

  return `${formattedHours} ${formattedMinutes}`;
};

export const padWithLeadingZero = (num: string) => (+num < 10 ? `0${num}` : num.toString());

export const countYearsAgeByDate = (birtDate: string, startDate: Date): number => {
  const birthDateToDayjs = dayjs(birtDate);
  const startDateToDayjs = dayjs(startDate);
  return startDateToDayjs.diff(birthDateToDayjs, 'year');
};

export const formatDateToLongString = (date: string) => {
  return dayjs(date).format('MMMM D, YYYY');
};

export const getDaysDifference = (date: Date, daysToAdd: number, format?: string) => {
  return dayjs(date)
    .add(daysToAdd, 'day')
    .format(format ?? 'ddd, MMM DD');
};
