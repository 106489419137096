import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setReferralCode } from 'redux/slices/meta/meta-slice';
import { parseQueryString } from 'utils/strings';
import { checkValidRefCode } from './utils';

export const CheckRefCode = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { refcode } = parseQueryString(search);
  if (checkValidRefCode(refcode)) {
    dispatch(setReferralCode({ refCode: refcode }));
  }

  return null;
};
