import { Card, styled } from '@mui/material';

export const SummaryItemWrapper = styled(Card)`
  padding: 16px 22px 10px 22px;
  margin-bottom: 30px;
`;

export const ContentWrapper = styled(Card)`
  border: none;
`;
