import React from 'react';

import { FlightOptionList } from './FlightOptionList';
import { InteractionLoader } from '../InteractionLoader/InteractionLoader';
import { useGetRequestOptionsWithParams } from './shared/hooks/useGetRequestOptionsWithParams';

export const FlightOptions = () => {
  const { data, isLoading, refetch } = useGetRequestOptionsWithParams();

  if (isLoading) return <InteractionLoader />;

  return <FlightOptionList items={data?.journeyOffers ?? []} refetchFlightOptions={refetch} />;
};
