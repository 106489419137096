import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { EmailAuthProvider, FacebookAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { useHistory } from 'react-router-dom';
import 'firebaseui/dist/firebaseui.css';

import { routes } from 'routes';
import { setToken } from 'redux/slices/auth/auth-slice';
import { USER_LOGIN, USER_LOGIN_data, USER_LOGIN_vars } from 'api/queries/userLogin';
import { firebaseConfig } from '../../../../firebase/config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const ui = new firebaseui.auth.AuthUI(auth);

interface SignInData {
  user: {
    accessToken: string;
  };
}

export const SignInContent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [login] = useLazyQuery<USER_LOGIN_data, USER_LOGIN_vars>(USER_LOGIN);

  const uiConfig: firebaseui.auth.Config = {
    signInOptions: [GoogleAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID],
    tosUrl: routes.privacyTerms,
    privacyPolicyUrl: routes.privacyTerms,
    callbacks: {
      signInSuccessWithAuthResult: (data: SignInData) => {
        const token = data.user.accessToken;

        login({
          variables: { jwtToken: token },
        }).then(() => {
          dispatch(setToken(token ?? null));
          history.push(routes.home);
          toast.success('You have been logged in');
        });

        return false;
      },
    },
  };

  useEffect(() => {
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return <div id="firebaseui-auth-container"></div>;
};
