import { styled } from '@mui/material';

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
