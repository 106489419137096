import React from 'react';

import { convertDayMonth, convertHoursMinutes } from 'utils/dates';
import { SegmentModel } from 'models/segment.model';
import { ImageWithLoader, Typography } from 'components';
import { baseLogoUrl } from 'constants/baseUrl';
import { ChangeDateNotification } from 'components/ChangeDateNotification/ChangeDateNotification';
import { getChangeDateCount } from 'utils/strings';
import { AirlineInfoModel } from 'models/airline.model';

import { DurationLine } from './DurationLine';
import {
  ArrivesWithChangeDate,
  ChangeDayNotification,
  Segment,
  SegmentInfo,
  TypographyAirportCode,
  TypographyTime,
  ValidationAirline,
  Wrapper,
} from './styles';

type PropsType = {
  segments: SegmentModel[];
  totalTripDuration: string | null;
  changeOfDate: number;
  operationAirlines: AirlineInfoModel[];
};

export const Itinerary = ({ segments, totalTripDuration, changeOfDate, operationAirlines }: PropsType) => {
  const showMarketingAirline = (airline: AirlineInfoModel): string => {
    const operationAirlineNames = operationAirlines.map((o) => o.name);
    return !operationAirlineNames.includes(airline.name) ? airline.name : '';
  };

  const departureWay = segments[0];
  const departureLogo = `${baseLogoUrl}${departureWay.marketingAirline.code}.png`;
  const departureMarketingAirline = showMarketingAirline(departureWay.marketingAirline);
  const arrivalWay = segments[segments.length - 1];

  return (
    <Wrapper>
      <ChangeDateNotification changeOfDate={changeOfDate} departureAt={departureWay.departureAt} />
      <Segment>
        <SegmentInfo>
          <ImageWithLoader maxWidth={35} src={departureLogo} />
          <Typography variant="smallBody">{convertDayMonth(departureWay.departureAt)}</Typography>
        </SegmentInfo>

        <SegmentInfo>
          <TypographyTime variant="smallTitle">{convertHoursMinutes(departureWay.departureAt)}</TypographyTime>
          <TypographyAirportCode variant="subtitleBold">{departureWay.originAirport.code}</TypographyAirportCode>
          {departureMarketingAirline && <ValidationAirline variant="smallBody">{departureMarketingAirline}</ValidationAirline>}
        </SegmentInfo>

        <DurationLine segments={segments} totalTripDuration={totalTripDuration} />

        <SegmentInfo>
          <ArrivesWithChangeDate>
            <TypographyTime variant="smallTitle">{convertHoursMinutes(arrivalWay.arrivalAt)}</TypographyTime>
            <ChangeDayNotification>
              <TypographyTime variant="smallBody">{getChangeDateCount(changeOfDate)}</TypographyTime>
            </ChangeDayNotification>
          </ArrivesWithChangeDate>
          <TypographyAirportCode variant="subtitleBold">{arrivalWay.destinationAirport.code}</TypographyAirportCode>
        </SegmentInfo>
      </Segment>
    </Wrapper>
  );
};
