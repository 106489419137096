import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from 'components/kit/Typography';
import { AccordionDetailsStyled, AccordionStyled, DescriptionItem } from './styles';
import AccordionSummary from '@mui/material/AccordionSummary';

interface FareDescriptionProps {
  descriptions: string[];
}

export const FareDescription = ({ descriptions }: FareDescriptionProps) => {
  if (!descriptions?.length) return <></>;

  return (
    <AccordionStyled disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Fare descriptions:</AccordionSummary>
      <AccordionDetailsStyled>
        {descriptions.map((d) => {
          return (
            <DescriptionItem>
              <Typography key={d} variant={'body'}>
                {d}
              </Typography>
            </DescriptionItem>
          );
        })}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
