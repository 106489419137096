import React from 'react';
import { Header } from 'components';
import { Typography } from '@mui/material';
import { colors, ContainerStyled } from 'style';
import { Login } from 'components/pages/login/Login';

export const LoginPage = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      Login
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <Login />
      </ContainerStyled>
    </>
  );
};
