import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { routes } from 'routes';
import { resetPaymentState, setPaymentState } from 'redux/slices/payment/payment-slice';
import { FLIGHT_ORDER_DETAILS, FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars } from 'api/queries/flightOrderDetails';
import { useAppSelector } from 'hooks/useAppSelector';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { PaymentMode, PaymentStatusEnum, RoutePage } from 'enum/enum';
import { failedPaymentStatuses, pendingPaymentStatuses, successfulPaymentStatuses } from 'constants/payments';
import { resetFlightOrderState, setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';

const REQUEST_INTERVAL = 3000;
const REQUEST_INTERVAL_MPESA = 5000;
const INITIAL_SECONDS = 30;

export const usePaymentApproval = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(INITIAL_SECONDS);

  const { paymentBillingInfo, paymentMode } = useAppSelector((state) => state.payment);
  const { flightOrderId } = useAppSelector((state) => state.flightOrder);

  const paymentState = paymentBillingInfo?.paymentState;
  const isMpesa = paymentMode === PaymentMode.MPESA_EXPRESS;
  const isPaymentStatusFailed = useMemo(() => failedPaymentStatuses.includes(paymentState as PaymentStatusEnum), [paymentState]);
  const isTimeOut = seconds === 0 && paymentMode !== PaymentMode.MPESA_EXPRESS;

  const { loading: loadingFlightOrder, error } = useQuery<FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars>(FLIGHT_ORDER_DETAILS, {
    variables: {
      flightOrderId: flightOrderId as string,
    },
    pollInterval: isMpesa ? REQUEST_INTERVAL_MPESA : REQUEST_INTERVAL,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',

    skip: isPaymentStatusFailed || isTimeOut,

    onCompleted: (data) => {
      dispatch(setFlightOrderDetails(data.flightOrderDetails));
      const associatedPayments = data?.flightOrderDetails.associatedPayments;
      const foundSuccessStatus = associatedPayments?.find((p) => successfulPaymentStatuses.includes(p.paymentState))?.paymentState;
      const responseStatus = foundSuccessStatus || associatedPayments[associatedPayments.length - 1].paymentState;
      dispatch(setPaymentState(responseStatus));
      if (successfulPaymentStatuses.includes(responseStatus as PaymentStatusEnum)) {
        dispatch(allowPageAccess(RoutePage.SUCCESS));
        history.push(routes.success, { access: true });
      }
      if (pendingPaymentStatuses.includes(responseStatus as PaymentStatusEnum)) {
        setLoading(true);
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const changePaymentNumber = () => {
    dispatch(resetPaymentState());
    history.push(routes.entry);
  };

  const retryRequestHandler = async () => {
    dispatch(setPaymentState(PaymentStatusEnum.OPEN));
    dispatch(resetFlightOrderState());
    setSeconds(INITIAL_SECONDS);
  };

  return {
    isFailed: isPaymentStatusFailed || !!error,
    isLoading: loading || loadingFlightOrder,
    isTimeOut,
    seconds,
    setSeconds,
    changePaymentNumber,
    retryRequestHandler,
  };
};
