import { Box, styled } from '@mui/material';
import { MobileDatePicker, MobileDateRangePicker } from '@mui/lab';
import { Skeleton } from '@mui/material';
import { colors } from 'style';

export const DatepickerSingle = styled<any>(MobileDatePicker)`
  .PrivatePickersToolbar-root {
    background-color: ${colors.primary} !important;

    & span,
    & div {
      color: ${colors.white};
    }

    ,
    & svg {
      color: ${colors.white};
    }
  }

  & .PrivateDateRangePickerToolbar-penIcon {
    display: none;
  }
`;

export const DatepickerRanged = styled<any>(MobileDateRangePicker)`
  .PrivatePickersToolbar-root {
    background-color: ${colors.primary} !important;

    & span,
    & div {
      color: ${colors.white};
    }

    ,
    & svg {
      color: ${colors.white};
    }
  }

  & .PrivateDateRangePickerToolbar-penIcon {
    display: none;
  }
`;

export const DayWrapper = styled(Box)(() => ({
  position: 'relative',
}));

export const RecommendationNotify = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${colors.secondary};
`;

export const DayRangeWrapper = styled(Box)<{
  selected: boolean | undefined;
  isStart: boolean;
  isEnd: boolean;
  isHighlighting: boolean;
  day: string;
}>`
  position: relative;

  .MuiDateRangePickerDay-root:first-of-type,
  .MuiDateRangePickerDay-root:last-of-type {
    border-top-left-radius: ${(props) =>
      ((props.selected && props.isEnd) || (props.isHighlighting && !props.isEnd && !props.isStart && !props.day.startsWith('Sun'))) &&
      '0 !important'};
    border-bottom-left-radius: ${(props) =>
      ((props.selected && props.isEnd) || (props.isHighlighting && !props.isEnd && !props.isStart && !props.day.startsWith('Sun'))) &&
      '0 !important'};
    border-top-right-radius: ${(props) =>
      ((props.selected && props.isStart) || (props.isHighlighting && !props.isEnd && !props.isStart && !props.day.startsWith('Sat'))) &&
      '0 !important'};
    border-bottom-right-radius: ${(props) =>
      ((props.selected && props.isStart) || (props.isHighlighting && !props.isEnd && !props.isStart && !props.day.startsWith('Sat'))) &&
      '0 !important'};
  }
}
`;

export const SelectionDateWrapper = styled(Box)`
  position: relative;
  width: 100%;
`;

export const SkeletonCalendarDay = styled(Skeleton)(() => ({
  margin: '2px',
}));
SkeletonCalendarDay.defaultProps = {
  width: '36px',
  height: '36px',
  animation: 'wave',
  variant: 'circular',
};
