import React from 'react';
import { Line, SegmentSquareArea, SegmentSquare, SquareSegmentsWrapper, TotalDuration } from './styles';
import { SegmentModel } from 'models/segment.model';
import { parseISO8601Time } from 'utils/dates';

interface DurationLineProps {
  segments: SegmentModel[];
  totalTripDuration: string | null;
}

export const DurationLine = ({ segments, totalTripDuration }: DurationLineProps) => {
  const durationTime = parseISO8601Time(totalTripDuration);

  const segmentSquare = () => {
    if (segments.length >= 2) {
      return (
        <SquareSegmentsWrapper>
          {segments.slice(0, -1).map((segment) => {
            return (
              <SegmentSquareArea key={segment.id}>
                <SegmentSquare />
              </SegmentSquareArea>
            );
          })}
        </SquareSegmentsWrapper>
      );
    }
  };

  return (
    <Line>
      {segmentSquare()}
      <TotalDuration variant="subtitle">{durationTime}</TotalDuration>
    </Line>
  );
};
