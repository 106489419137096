import { ListItem, Paper, styled } from '@mui/material';
import { colors } from 'style';

export const PassengersDropdown = styled(Paper)`
  padding: 16px;
  position: absolute !important;
  top: 56px;
  right: 0;
  z-index: 9;
  min-width: 500px;
  border: 1px solid ${colors.border};
  @media (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }
`;

export const ListItemStyled = styled(ListItem)`
  border-bottom: 1px solid ${colors.gray10};

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const DoneButtonBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
