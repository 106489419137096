import React from 'react';
import { Header } from 'components';
import { Typography } from '@mui/material';
import { PaymentEntry } from 'components/pages/entry/PaymentEntry';
import { colors, ContainerStyled } from 'style';

export const PaymentEntryPage = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      Payment Entry
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <PaymentEntry />
      </ContainerStyled>
    </>
  );
};
