import React, { ReactNode } from 'react';
import { Alert } from '@mui/material';
import { AlertWrapper } from './style';

interface PropsType {
  variant: 'error' | 'info' | 'success' | 'warning';
  children: ReactNode;
}

export const Notification = ({ variant, children }: PropsType) => {
  return (
    <AlertWrapper>
      <Alert severity={variant}>{children}</Alert>
    </AlertWrapper>
  );
};
