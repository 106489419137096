import { Card, styled } from '@mui/material';
import { breakpoints } from 'style';

export const ContentWrapper = styled(Card)`
  border: none;
  margin-top: 14px;
`;

export const ButtonsBlock = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 16px;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 8px;
  }
`;
