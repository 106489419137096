import { Select } from '@mui/material';
import styled from '@emotion/styled';

export const MuiSelect = styled(Select)`
  .MuiSelect-select {
    width: 80px;
    padding: 6px 0 10px 12px;
    background-color: white;
  }

  &:before {
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    bottom: 3px;
  }

  &:after,
  &:focus,
  &::selection {
    border-bottom: none;
    background-color: unset;
  }
`;
