import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from 'routes';
import { resetAllPagesAccess } from 'redux/slices/accessability/accessability-slice';
import { resetSearchState } from 'redux/slices/search/search-slice';
import { resetPriceOfferState } from 'redux/slices/flightOffer/priceOffer-slice';
import { resetBookingState } from 'redux/slices/booking/booking-slice';
import { resetFlightOrderState } from 'redux/slices/flightOrder/flightOrder-slice';
import { resetJourneyState } from 'redux/slices/journeyOption/journeyOption-slice';
import { resetPaymentState } from 'redux/slices/payment/payment-slice';

const CLEARING_KEY = ':persist:root';
const CLEAR_TIME_KEY = 'lastClearTime';
const ONE_WEEK_MS = 604800000;

export const useClearingData = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const clearValues = () => {
    dispatch(resetAllPagesAccess());
    dispatch(resetBookingState());
    dispatch(resetSearchState());
    dispatch(resetFlightOrderState());
    dispatch(resetJourneyState());
    dispatch(resetPriceOfferState());
    dispatch(resetPaymentState());
    history.push(routes.home);
  };

  useEffect(() => {
    const clearLocalStorageData = () => {
      clearValues();
      localStorage.removeItem(CLEARING_KEY);
    };

    const lastClearTime = Number(localStorage.getItem(CLEAR_TIME_KEY)) ?? 0;
    const currentTime = Date.now();

    if (!lastClearTime || currentTime - lastClearTime >= ONE_WEEK_MS) {
      clearLocalStorageData();
      localStorage.setItem(CLEAR_TIME_KEY, currentTime.toString());
    }
  }, []);
};
