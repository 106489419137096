import React from 'react';
import { Box } from '@mui/material';

type PropsType = {
  children: React.ReactNode;
  marginTop?: string;
  sx?: Record<string, unknown>;
};

export const MarginTop = React.memo((props: PropsType) => {
  const setMargin = props.marginTop ? props.marginTop + 'px' : '20px';

  return <Box sx={{ marginTop: setMargin, padding: '0', ...props.sx }}>{props.children}</Box>;
});
