import React from 'react';
import { convertFullDate } from 'utils/dates';
import { useQuery } from '@apollo/client';
import { RECOMMENDATION_DATES, RECOMMENDATION_DATES_data, RECOMMENDATION_DATES_vars } from 'api/queries/recommendationDates';
import { PickersDayProps } from '@mui/lab/PickersDay/PickersDay';
import { DateRangePickerDay, DateRangePickerDayProps, PickersDay } from '@mui/lab';
import { DayRangeWrapper, DayWrapper, RecommendationNotify, SkeletonCalendarDay } from 'components/pages/home/SelectionDate/styles';

interface Props {
  arrivalAirport: string;
  departureAirport: string;
  currentMonth: Date;
}

export const useRequestRecommendationDates = ({ arrivalAirport, departureAirport, currentMonth }: Props) => {
  const currentDate = convertFullDate(currentMonth);

  const { data, loading } = useQuery<RECOMMENDATION_DATES_data, RECOMMENDATION_DATES_vars>(RECOMMENDATION_DATES, {
    variables: {
      departureAirport: departureAirport ?? '',
      arrivalAirport: arrivalAirport ?? '',
      departureDate: currentDate,
    },
    skip: !arrivalAirport || !departureAirport,
  });

  const renderSinglePickerDay = (
    day: Date,
    _selectedDate: Date | null,
    DayComponentProps: PickersDayProps<unknown> & DateRangePickerDayProps<Date>,
  ) => {
    const formatted = convertFullDate(day);
    const dateIsHighlighted = data?.searchRecommendation?.find((d) => {
      return d.recommendationDate === formatted;
    });
    if (loading && !DayComponentProps.outsideCurrentMonth) {
      return <SkeletonCalendarDay />;
    }

    if (dateIsHighlighted && !DayComponentProps.outsideCurrentMonth) {
      return (
        <DayWrapper>
          <RecommendationNotify />
          <PickersDay {...DayComponentProps} />
        </DayWrapper>
      );
    }
    return <PickersDay {...DayComponentProps} />;
  };

  const renderRangePickerDay = (day: Date, dayProps: DateRangePickerDayProps<Date>) => {
    const dateRangePickerDayProps = dayProps as DateRangePickerDayProps<Date>;
    const formattedDay = convertFullDate(day);
    const recommendedDay = data?.searchRecommendation?.find((d) => d.recommendationDate === formattedDay);
    const visibleRecommendedDay = recommendedDay && !dateRangePickerDayProps.outsideCurrentMonth;

    if (loading && !dateRangePickerDayProps.outsideCurrentMonth) {
      return <SkeletonCalendarDay />;
    }

    return (
      <>
        {visibleRecommendedDay ? (
          <DayRangeWrapper
            selected={dayProps.selected}
            isStart={dayProps.isStartOfHighlighting}
            isEnd={dayProps.isEndOfHighlighting}
            isHighlighting={dayProps.isHighlighting}
            day={String(dayProps.key)}
            key={String(dayProps.key)}
          >
            <RecommendationNotify />
            <DateRangePickerDay {...dayProps} />
          </DayRangeWrapper>
        ) : (
          <DateRangePickerDay {...dayProps} />
        )}
      </>
    );
  };

  return {
    renderSinglePickerDay,
    renderRangePickerDay,
  };
};
