import React from 'react';
import { Header } from 'components';
import { AboutPageComponents } from 'components/pages/about/AboutPageComponents';
import { StyledTypographyHeader } from 'components/pages/about/components';

export const AboutPage = () => {
  const headerContent = <StyledTypographyHeader variant="title">About Us</StyledTypographyHeader>;
  return (
    <>
      <Header content={headerContent} />
      <AboutPageComponents />
    </>
  );
};
