import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  token: string | null;
};

const initialState: InitialState = {
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
  },
});
export const { setToken } = authSlice.actions;
