import { gql } from '@apollo/client';
import { PaymentMode } from 'enum/enum';
import { PRICE_QUOTE_FRAGMENT } from 'api/fragments';
import { PriceModel } from 'models/price.model';
import { CreatePaymentModel } from 'models/createPayment.model';

export interface CREATE_PAYMENT_data {
  createPayment: CreatePaymentModel;
}

export interface CREATE_PAYMENT_vars {
  flightOrderId: string;
  paymentMode: PaymentMode;
  accountNumber?: string;
  valueInput: PriceModel;
}

export const CREATE_PAYMENT = gql`
  mutation createPayment($flightOrderId: UUID!, $paymentMode: PAYMENTMODECHOICES!, $accountNumber: String, $valueInput: MoneyInputType!) {
    createPayment(flightOrderId: $flightOrderId, paymentMode: $paymentMode, accountNumber: $accountNumber, valueInput: $valueInput) {
      paymentId
      paymentState
      paymentMode
      displayStringEn
      stripeClientSecret
      paybillNumber
      merchantName
      billRefNumber
      paymentValue {
        ...PriceQuoteFragment
      }
    }
  }
  ${PRICE_QUOTE_FRAGMENT}
`;
