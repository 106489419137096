import { gql } from '@apollo/client';
import { AirportInfoModel } from 'models/airport.model';

export interface AIRPORTS_data {
  airports: AirportInfoModel[];
}

export const AIRPORTS = gql`
  query airports {
    airports {
      name
      city
      cityCode
      airportCode
    }
  }
`;
