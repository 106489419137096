import React from 'react';
import { Button } from '@mui/material';

import { AirlineInfoModel } from 'models/airline.model';
import {
  ButtonSection,
  FlightOptionFooterInfo,
  FlightOptionFooterWrapper,
  OperatedAirlineText,
  OperationAirlines,
  TotalSectionFooter,
  TypographyTicketClass,
  TypographyTotal,
} from './styles';
import { getUniqNames } from 'utils/strings';

interface FlightOptionFooterProps {
  operationAirlines: AirlineInfoModel[];
  priceTotal: string;
  cabin: string;
  onSubmitHandler: (e: React.MouseEvent<HTMLElement>) => void;
  onDetailsHandler: () => void;
}

export const FlightOptionFooter = ({ operationAirlines, priceTotal, cabin, onSubmitHandler, onDetailsHandler }: FlightOptionFooterProps) => {
  const uniqOperatedAirlines = getUniqNames(operationAirlines, 'name').join(', ');

  return (
    <FlightOptionFooterWrapper>
      <FlightOptionFooterInfo>
        <OperationAirlines>
          <OperatedAirlineText>Operated by: {uniqOperatedAirlines}</OperatedAirlineText>
        </OperationAirlines>
        <TotalSectionFooter>
          <TypographyTotal>{priceTotal}</TypographyTotal>
          <TypographyTicketClass>{cabin}</TypographyTicketClass>
        </TotalSectionFooter>
      </FlightOptionFooterInfo>
      <ButtonSection>
        <Button onClick={onDetailsHandler} variant="outlined">
          Details
        </Button>
        <Button onClick={onSubmitHandler} variant="contained">
          Select
        </Button>
      </ButtonSection>
    </FlightOptionFooterWrapper>
  );
};
