import React, { useEffect } from 'react';
import { colors, ContainerStyled } from 'style';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { FLIGHT_ORDER_DETAILS, FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars } from 'api/queries/flightOrderDetails';
import { Header } from 'components/Header';
import { PaymentSuccessful } from 'components/pages/success/PaymentSuccessful';
import { useAppSelector } from 'hooks/useAppSelector';
import { setSuccessfulOrder } from 'redux/slices/flightOrder/flightOrder-slice';
import { useGetPaymentInfo } from 'hooks/useGetPaymentInfo';

export const PaymentSuccessfulPageComponent = () => {
  const dispatch = useDispatch();
  const { confirmationNumber, price, card, cardLast4, lastCardDigitsLabel } = useGetPaymentInfo();
  const { flightOrderId, associatedPayments } = useAppSelector((state) => state.flightOrder);

  const [getFlightOrderDetails, { loading }] = useLazyQuery<FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars>(FLIGHT_ORDER_DETAILS, {
    variables: {
      flightOrderId: flightOrderId as string,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (flightOrderDetailsData) => {
      const successfulData = {
        orderPaymentStatus: flightOrderDetailsData.flightOrderDetails.orderPaymentStatus,
        associatedPayments: flightOrderDetailsData.flightOrderDetails.associatedPayments,
        journeys: flightOrderDetailsData.flightOrderDetails.journeys,
      };
      dispatch(setSuccessfulOrder(successfulData));
    },
  });

  useEffect(() => {
    getFlightOrderDetails();
  }, []);

  return (
    <>
      <Header
        content={
          <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
            Payment Successful!
          </Typography>
        }
      />
      <ContainerStyled>
        <PaymentSuccessful
          price={price}
          loading={loading}
          lastCardDigitsLabel={lastCardDigitsLabel}
          associatedPayments={associatedPayments}
          networkConfirmationCode={confirmationNumber}
          cardLast4={cardLast4}
          card={card}
        />
      </ContainerStyled>
    </>
  );
};
