/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'}>
              Fasax.com is a flight search website. We partner with nearly all of the airlines that operate within Somalia and greater East Africa.
              This allows us to utilize our extensive industry know-how to find you the best available flights at the lowest possible price.
              <br />
              We design and implement creative solutions to everyday travel problems.
              <br />
              Fasax.com is the key to disparate and compartmentalized travel accommodations in East Africa.
              <br />
            </Typography>
          </Box>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={6}>
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={'https://static.fasax.com/public/other-logos/withTravelfasax.png'}
              width={1}
              height={1}
              sx={{
                filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
