import React, { useEffect } from 'react';
import { JustifyCenter } from 'style';
import { colors } from 'style';
import { MarginTop } from 'components/index';
import { useWindowSize } from 'hooks/useWindowWidth';
import { Typography } from '@mui/material';

type PropTypes = {
  seconds: number;
  setSeconds: (seconds: number) => void;
};

export const Timer = ({ seconds, setSeconds }: PropTypes) => {
  const windowSize = useWindowSize();

  const { height, width } = windowSize;
  const isSmallHeight = height <= 720;
  const isSmallWidth = width <= 480;

  const startTimer = () => {
    setSeconds(seconds - 1);
  };

  useEffect(() => {
    const setTimeoutId = setTimeout(startTimer, 1000);
    if (seconds < 0) {
      clearTimeout(setTimeoutId);
    }
    return () => {
      clearTimeout(setTimeoutId);
    };
  });

  return (
    <JustifyCenter>
      <MarginTop marginTop={isSmallHeight ? '12' : '36'}>
        <Typography fontSize={120} lineHeight={'120px'} fontWeight={500} sx={{ textAlign: 'center', fontSize: isSmallWidth ? '96px' : '120px' }}>
          00:{seconds < 10 ? 0 : ''}
          {seconds}
        </Typography>
        <MarginTop marginTop={isSmallHeight ? '12' : '36'}>
          <JustifyCenter>
            <Typography fontSize={22} lineHeight={'24px'} fontWeight={500} color={colors.primary} sx={{ textAlign: 'center' }}>
              Please approve payment by entering PIN in your Mobile Money account
            </Typography>
          </JustifyCenter>
        </MarginTop>
      </MarginTop>
    </JustifyCenter>
  );
};
