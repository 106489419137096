import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from 'components';
import { DirectionEnum } from 'enum/enum';
import { convertShortDate } from 'utils/dates';
import { countObjItems } from 'utils/math';
import { SearchModel } from 'models/search.model';
import { BoardContent, BoardInformation, ChargeBordStyled, Column, HeaderRow, Row } from './styles';

type PropsType = {
  priceTotal: string | null;
  values: SearchModel;
};

export const ChargeBoard = ({ priceTotal, values }: PropsType) => {
  const { ItineraryRequest, passengers, direction, cabin: ticketClass } = values;
  const passengersCount = countObjItems(passengers);
  return (
    <ChargeBordStyled>
      <BoardContent>
        <HeaderRow>
          <Typography variant="title">Total Charge:</Typography>
          <Typography variant="title">{priceTotal}</Typography>
        </HeaderRow>
      </BoardContent>
      <BoardInformation>
        <Column>
          <Row>
            <Typography variant="titleBold">{ItineraryRequest[0].originAirport?.airportCode}</Typography>
            <Typography variant="titleBold">
              <span>{'- -'}</span>
            </Typography>
            <Typography variant="titleBold">{ItineraryRequest[0].destinationAirport?.airportCode}</Typography>
          </Row>

          <Typography variant="subtitle">{convertShortDate(ItineraryRequest[0]?.departureDate)}</Typography>
          {direction === DirectionEnum.ROUND && (
            <>
              <Typography variant="subtitle">
                <span> - </span>
              </Typography>
              <Typography variant="subtitle">{convertShortDate(ItineraryRequest[1]?.departureDate)}</Typography>
            </>
          )}
        </Column>

        <Column>
          <Row>
            <PersonIcon />
            {passengersCount}
          </Row>
          <Typography variant="subtitle">{ticketClass}</Typography>
        </Column>
      </BoardInformation>
    </ChargeBordStyled>
  );
};
