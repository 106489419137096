import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ButtonsBlock } from './styles';

interface ActionButtonsProps {
  submitButtonName: string;
  disabled?: boolean;
  onSubmit?: () => void;
  onBack?: () => void;
}

export const ActionButtons = ({ onSubmit, submitButtonName, disabled, onBack }: ActionButtonsProps) => {
  const history = useHistory();

  const onBackHandler = () => {
    onBack?.();
    history.goBack();
  };
  return (
    <ButtonsBlock>
      <Button disabled={disabled} onClick={onBackHandler} variant="text" fullWidth size="large">
        Back
      </Button>
      <Button disabled={disabled} onClick={onSubmit ?? undefined} type={onSubmit ? 'button' : 'submit'} variant={'contained'} fullWidth size="large">
        {submitButtonName}
      </Button>
    </ButtonsBlock>
  );
};
