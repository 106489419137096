import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface Option {
  uuid: string;
  name: string;
}

interface AutocompleteFieldProps {
  value: string;
  label: string;
  onChange: (newValue: string) => void;
  options: Option[];
  settingKeyName?: keyof Option;
}

export const ManualAutocomplete = ({ label, value, onChange, options, settingKeyName = 'name' }: AutocompleteFieldProps) => {
  return (
    <Autocomplete
      options={options}
      fullWidth
      getOptionLabel={(option: Option) => option.name}
      value={options.find((option) => option[settingKeyName] === value) || null}
      onChange={(e, newValue: Option | null) => {
        onChange(newValue ? newValue[settingKeyName] : '');
      }}
      renderInput={(params) => <TextField {...params} label={label} size="small" />}
    />
  );
};
