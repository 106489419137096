import React, { ReactElement, useState } from 'react';
import ReactGA from 'react-ga';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { LogoIcon } from 'assets/icons/LogoIcon';
import { Drawer } from 'components';

import { AppBarStyled, LogoIconWrapper, ToolbarStyled } from './styles';

type HeaderType = {
  content: string | ReactElement;
};

export const Header = ({ content }: HeaderType) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const toggleDrawer = (event: any) => {
    ReactGA.event({
      category: 'Home page',
      action: 'toggle',
      label: 'drawer',
    });
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <AppBarStyled position="static">
        <ToolbarStyled>
          <LogoIconWrapper onClick={toggleDrawer}>
            <LogoIcon />
            <KeyboardArrowDownIcon />
          </LogoIconWrapper>
          {content}
        </ToolbarStyled>
      </AppBarStyled>
      <Drawer isOpen={openDrawer} setIsOpen={setOpenDrawer} />
      <Drawer isOpen={openDrawer} setIsOpen={setOpenDrawer} />
    </>
  );
};
