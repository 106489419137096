import React from 'react';
import { ServiceItemContainer } from './styles';
import { GroupedServiceItem } from './GroupedServiceItem';
import { ServiceListItem } from 'models/service.model';

interface ServiceItemProps {
  items: ServiceListItem[];
}

export const ServiceItemCategory = ({ items }: ServiceItemProps) => {
  return (
    <ServiceItemContainer>
      {items.map((i) => (
        <GroupedServiceItem key={i.name} title={i.name} items={i.services} />
      ))}
    </ServiceItemContainer>
  );
};
