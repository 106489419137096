import { requestedItinerariesType } from '../types';

export const getPrepareDataFlightOptions = (
  departureDate: string,
  destination: string,
  preparedReturnDate: null | string | undefined,
  origin: string,
) => {
  const requestedItineraries = [
    {
      departureDate: departureDate,
      includeDaysAfter: 0,
      includeDaysBefore: 0,
      destinationAirportCode: destination,
      originAirportCode: origin,
    },
  ];

  if (preparedReturnDate) {
    const backFlight: requestedItinerariesType = {
      departureDate: preparedReturnDate,
      originAirportCode: destination,
      destinationAirportCode: origin,
      includeDaysAfter: 0,
      includeDaysBefore: 0,
    };
    requestedItineraries.push(backFlight);
  }

  return requestedItineraries;
};
