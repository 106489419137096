import { Modal } from 'components/kit/Modal';
import { useAppDispatch } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { resetTicketPriceChange } from 'redux/slices/flightOffer/priceOffer-slice';
import { useHistory } from 'react-router-dom';
import { useGetFlightDetails } from './useGetFlightDetails';
import { ModalContent } from './ModalContent';

export const ChangeTicketPriceModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { currentTicketPrice, ticketPrice, flightDirections } = useGetFlightDetails();

  useEffect(() => {
    setShowModal(currentTicketPrice?.isChange ?? false);
  }, [currentTicketPrice]);

  const resetPriceOfferCurrentPrice = () => {
    dispatch(resetTicketPriceChange());
  };

  const handleModalCancel = () => {
    resetPriceOfferCurrentPrice();
    history.goBack();
  };

  return (
    <>
      {showModal && (
        <Modal onClose={resetPriceOfferCurrentPrice} open={showModal}>
          <ModalContent
            flightDirections={flightDirections}
            ticketPrice={ticketPrice}
            handleConfirm={resetPriceOfferCurrentPrice}
            handleCancel={handleModalCancel}
          />
        </Modal>
      )}
    </>
  );
};
