import { styled } from '@mui/material';
import { headerLogoWidth } from 'style/vars/constants';

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderFlightDetailed = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Passengers = styled('div')`
  display: flex;
  align-items: center;
  margin-right: ${headerLogoWidth}px;
`;
