import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FLIGHT_ORDER_DETAILS, FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars } from 'api/queries/flightOrderDetails';
import { useLazyQuery } from '@apollo/client';
import { schema } from './schema';
import { defaultValues } from './defaultValues';
import { MarginTop } from 'components/MarginTop';
import { Input } from 'components/kit/Input';
import { CheckTripText, WelcomeText } from 'components/pages/mytrips/styles';
import { useDispatch } from 'react-redux';
import { routes } from 'routes';
import { useHistory } from 'react-router-dom';
import { setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';
import { allowPageAccess } from 'redux/slices/accessability/accessability-slice';
import { RoutePage } from 'enum/enum';

interface MyTripsFormValues {
  PNR: string;
  lastName: string;
}

export const MyTripsForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [requestTrips, { loading }] = useLazyQuery<FLIGHT_ORDER_DETAILS_data, FLIGHT_ORDER_DETAILS_vars>(FLIGHT_ORDER_DETAILS);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MyTripsFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (values: MyTripsFormValues) => {
    const { data } = await requestTrips({
      variables: {
        PNR: values.PNR,
        lastName: values.lastName,
      },
    });
    dispatch(allowPageAccess(RoutePage.SUCCESS));
    dispatch(setFlightOrderDetails(data!.flightOrderDetails));
    return history.push(routes.order, { access: true });
  };

  return (
    <>
      <CheckTripText>Check on your trip</CheckTripText>
      <WelcomeText>Welcome back</WelcomeText>

      <form onSubmit={handleSubmit(onSubmit)}>
        <MarginTop marginTop={'12'}>
          <Typography variant={'subtitle2'}>Enter your confirmation code (ex: 5XYZZY)</Typography>
          <Input error={errors?.PNR} control={control} name={'PNR'} label={'Code*'} />
        </MarginTop>

        <MarginTop marginTop={'12'}>
          <Input error={errors?.lastName} control={control} name={'lastName'} label={'Last Name*'} fullWidth />
        </MarginTop>

        <MarginTop marginTop={'30'}>
          <Button fullWidth={true} size={'large'} variant={'contained'} type={'submit'} disabled={loading}>
            Search
          </Button>
        </MarginTop>
      </form>
    </>
  );
};
