import React from 'react';
import { ContainerStyled } from 'style';
import Box from '@mui/material/Box';
import { Headline } from 'components/pages/help/Headline';
import { Content } from 'components/pages/help/Content';
import { FooterHelpPage } from 'components/pages/help/Footer';
import { useTheme } from '@mui/material/styles';

export const HelpPageContent = () => {
  const theme = useTheme();
  return (
    <ContainerStyled>
      <Box>
        <Box
          sx={{
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%,  ${theme.palette.primary.main} 100%`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
            <Headline />
          </Box>
        </Box>
        <Box width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }} maxWidth={800}>
          <Content />
        </Box>
        <Box bgcolor={theme.palette.primary.main}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.main} 100%)`,
              marginTop: -13,
              paddingTop: 13,
            }}
          >
            <FooterHelpPage />
          </Box>
        </Box>
      </Box>
    </ContainerStyled>
  );
};
