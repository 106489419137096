import { useState, useEffect } from 'react';

export const useNumberAnimation = (value: number, duration = 1000): number => {
  const [animatedValue, setAnimatedValue] = useState<number>(value);

  useEffect(() => {
    let startTime: number | null = null;
    let animationFrameId: number | undefined;

    const startAnimation = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      if (progress < duration) {
        const newValue = Math.floor(value * (progress / duration));
        setAnimatedValue(newValue);
        animationFrameId = requestAnimationFrame(startAnimation);
      } else {
        setAnimatedValue(value);
      }
    };

    startAnimation(0);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [value, duration]);

  return animatedValue;
};
