import { styled } from '@mui/material';
import { breakpoints } from 'style';

export const ButtonWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  & > button {
    margin-top: 5px;
  }
  @media (max-width: ${breakpoints.md}) {
    & > button {
      flex-grow: 1;
    }
  }
`;
