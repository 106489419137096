export const initialPassenger = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  nationality: '',
  passportNumber: '',
  birthDay: '',
  birthMonth: '',
  birthYear: '',
  dateOfBirth: '',
  passengerType: '',
};

export const defaultValues = (passengersCount: number) => {
  // fill the default values for list of passengers
  const passCount = passengersCount === 0 ? 1 : passengersCount;
  return {
    passengers: Array(passCount).fill(initialPassenger),
    pnrPhoneNumber: '',
    pnrEmail: '',
  };
};
