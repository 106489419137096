import { Box, Container, Paper, styled } from '@mui/material';
import { colors } from '../vars/colors';
import { headerFooterHeight } from 'style/vars/constants';
import { breakpoints } from 'style/vars/breakpoints';

export const ContainerStyled = styled(Container)`
  min-height: calc(100vh - ${headerFooterHeight}px);
  padding: 24px;
  @media (max-width: ${breakpoints.sm}) {
    padding: 12px;
  }
`;

export const FlexRow = styled(Box)<{
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  alignSelf?: string;
  height?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => props.flexWrap && props.flexWrap};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  align-items: ${(props) => props.alignItems && props.alignItems};
  align-self: ${(props) => props.alignSelf && props.alignSelf};
  height: ${(props) => props.height && props.height};
  padding: ${(props) => props.padding && props.padding};
`;

export const FlexColumn = styled(Box)<{
  justifyContent?: string;
  height?: string;
  marginBottom?: string;
  alignItems?: string;
  marginRight?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent && props.justifyContent};
  height: ${(props) => props.height && props.height};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  margin-right: ${(props) => props.marginRight && props.marginRight};
  align-items: ${(props) => props.alignItems && props.alignItems};
  width: ${(props) => props.width && props.width};
`;

export const BoxSpaceBetween = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
`;

export const JustifyCenter = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const PaperWrapper = styled(Paper)`
  padding: 30px 32px 28px 32px;
`;

export const PaperItemWrapper = styled(Box)`
  padding: 26px 22px 16px 22px;
  border-top: 1px solid ${colors.gray10};
  @media (max-width: 380px) {
    padding: 20px 16px 12px 16px;
  }
`;

export const ButtonBlock = styled('div')`
  margin-top: 30px;
  padding: 0;
`;
