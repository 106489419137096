import { gql } from '@apollo/client';

export interface USER_LOGOUT_data {
  loggedIn: boolean;
}

export const USER_LOGOUT = gql`
  query userLogout {
    userLogout {
      loggedIn
    }
  }
`;
