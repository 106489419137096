import { gql } from '@apollo/client';
import { AddedServiceModel } from 'models/service.model';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { JOURNEY_FRAGMENT } from 'api/fragments';

export interface PRICE_OFFER_data {
  priceOffer: JourneyOfferModel[];
}

export interface PRICE_OFFER_vars {
  journeyUUID: string;
  addedServices: AddedServiceModel[];
  currency: string;
}

const PRICING_ERROR_FRAGMENT = gql`
  fragment ErrorFragment on JourneyOfferNotAvailable {
    __typename
    service
    description
  }
`;

export const PRICE_OFFER = gql`
  query priceOffer($journeyUUID: UUID!, $addedServices: [AddedService!]!, $currency: CURRENCYCHOICES!) {
    priceOffer(journeyUUID: $journeyUUID, addedServices: $addedServices, currency: $currency) {
      ...JourneyFragment
      ...ErrorFragment
    }
  }
  ${JOURNEY_FRAGMENT}
  ${PRICING_ERROR_FRAGMENT}
`;
