import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { AutocompleteRenderInputParams, Stack, TextField } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { DirectionEnum } from 'enum/enum';
import { AirportInfoModel } from 'models/airport.model';
import Fuse from 'fuse.js';

import { AutocompleteStyled } from './styles';
import { useAppSelector } from 'hooks/useAppSelector';

type PropsType = {
  control: Control<any>;
  name: string;
  items: AirportInfoModel[];
  direction: DirectionEnum;
  inputLogo: ReactElement;
  placeholderText: string;
  error?: FieldError;
};

export const AutocompleteAirport = ({ inputLogo, placeholderText, control, name, error, items }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | null>('');
  const [open, setOpen] = useState<boolean>(false);
  const { fuzzyLevel } = useAppSelector((state) => state.debugger);

  const getOptionLabel = (option: AirportInfoModel) => {
    if (option.airportCode && option.name) {
      return `${option.airportCode} - ${option.city}`;
    }
    return '';
  };

  const onOpenHandler = () => {
    if (inputValue) {
      setOpen(true);
    }
  };

  const onChangeInputHandler = (event: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const fuseOptions = {
    keys: ['name', 'city', 'cityCode', 'airportCode'],
    threshold: fuzzyLevel,
  };
  const fuse = new Fuse(items, fuseOptions);

  const filterOptions = (options: AirportInfoModel) => {
    if (inputValue) {
      const searchResults = fuse.search(inputValue);
      return searchResults.map((result) => result.item);
    }
    return options;
  };

  useEffect(() => {
    if (!inputValue) {
      setOpen(false);
    }
  }, [inputValue]);

  return (
    <Stack>
      <Controller
        defaultValue={null}
        control={control}
        name={name}
        render={({ field: { value: autocompleteValue, onChange, ...rest } }) => {
          const autocomplete = `${autocompleteValue?.airportCode} - ${autocompleteValue?.city}`;
          const isOpenSuggestion = !autocompleteValue || autocomplete !== inputValue ? open : false;
          return (
            <AutocompleteStyled
              error={!!error}
              id={`airport-${name}`}
              open={isOpenSuggestion}
              noOptionsText={'No Matching Airport'}
              onOpen={onOpenHandler}
              value={autocompleteValue}
              onChange={(e: React.SyntheticEvent, v: AirportInfoModel) => {
                onChange(v);
              }}
              inputValue={inputValue?.trim()}
              filterOptions={filterOptions}
              onInputChange={onChangeInputHandler}
              onClose={() => setOpen(false)}
              options={items}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option: AirportInfoModel, value: AirportInfoModel) => option.airportCode === value.airportCode}
              renderOption={(props: AutocompleteRenderInputParams, option: AirportInfoModel) => (
                <li {...props}>
                  {option.airportCode} - {option.name}, {option.city}
                </li>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  value={autocompleteValue ? `${autocompleteValue.airportCode} -${autocompleteValue.city}` : ''}
                  variant="filled"
                  placeholder={!autocompleteValue ? placeholderText : ''}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: inputLogo,
                  }}
                />
              )}
              {...rest}
            />
          );
        }}
      />
    </Stack>
  );
};
