import React from 'react';
import { Typography, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import { LinkContainer, LinkItem, PrivacyContactWrapper } from 'components/pages/privacy-terms/PrivacyContactCard/styles';
import { MarginTop } from 'components/MarginTop';

const links = [
  {
    link: 'https://www.facebook.com/fasaxtravel',
    icon: <FacebookOutlinedIcon color="primary" />,
  },
  {
    link: 'https://www.instagram.com/fasaxtravel/',
    icon: <InstagramIcon color="primary" />,
  },
  {
    link: 'https://twitter.com/FasaxTravel',
    icon: <TwitterIcon color="primary" />,
  },
  {
    link: 'tel:+252633228603',
    icon: <PhoneIcon color="primary" />,
  },
  {
    link: 'mailto:support@fasax.com',
    icon: <AlternateEmailIcon color="primary" />,
  },
];
export const PrivacyContactCard = () => {
  return (
    <MarginTop>
      <PrivacyContactWrapper>
        <Typography fontWeight={700} gutterBottom>
          How can you contact us about this notice?
        </Typography>
        <Typography variant={'body2'} color={'text.secondary'}>
          If you have any questions or concerns about our policies, please contact us.
        </Typography>
        <MarginTop>
          <LinkContainer>
            {links.map(({ link, icon }, index) => {
              return (
                <LinkItem key={link}>
                  <Link href={link} target={index > 2 ? '' : '_blank'}>
                    {icon}
                  </Link>
                </LinkItem>
              );
            })}
          </LinkContainer>
        </MarginTop>
      </PrivacyContactWrapper>
    </MarginTop>
  );
};
