import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { routes } from './index';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const location = useLocation<{ access?: boolean }>();
  const isDirectAccess = !location.state || !location.state.access;
  return <Route {...rest} render={(props) => (isDirectAccess ? <Redirect to={routes.home} /> : <Component {...props} />)} />;
};
