import { Box, Paper, styled } from '@mui/material';
import { colors } from 'style';

export const ChargeBordStyled = styled(Paper)`
  background-color: ${colors.primary};
  color: ${colors.white};
`;

export const BoardContent = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 14px 34px 8px;
`;

export const BoardInformation = styled(BoardContent)`
  border-top: 1px solid ${colors.white};
  padding: 14px 0 8px;
  display: flex;
  gap: 16px;
`;

export const Row = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderRow = styled(Row)`
  gap: 16px;
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
