import React, { useEffect, useMemo, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import { useDispatch } from 'react-redux';
import { InputLabel, ListItemText, Slider } from '@mui/material';

import { useAppSelector } from 'hooks/useAppSelector';
import { useDebounce } from 'hooks/useDebounce';
import {
  setAerocrsChecked,
  setAmadeusChecked,
  setEnableTTLModal,
  setFuzzyLevel,
  setOrchestraChecked,
  setProductionMode,
} from 'redux/slices/debuggerOptions/debuggerOptions-slice';
import { setAmadeus, setOrchestra } from 'redux/slices/search/search-slice';

import { SliderContainer, VersionContainer, VersionItem } from './styles';
import { Toggle } from '../kit/Toggle';
import { ListHeaderItem } from '../Drawer/styles';

export const Debugger = () => {
  const { isOrchestra, isAmadeus, isAerocrs, isDebug, fuzzyLevel, isProductionMode, enableTTL } = useAppSelector((state) => state.debugger);
  const dispatch = useDispatch();
  const [date, setDate] = useState<string>('');
  const [commit, setCommit] = useState<string>('');
  const [fuzzyLevelValue, setFuzzyLevelValue] = useState<number>(fuzzyLevel);

  const debouncedValue = useDebounce(fuzzyLevelValue, 500);
  const valuetext = (value: number) => `${value}`;

  const handlerChangeOrchestra = (value: boolean) => {
    dispatch(setOrchestraChecked(value));
    dispatch(setOrchestra(value));
  };

  const handlerChangeAmadeus = (value: boolean) => {
    dispatch(setAmadeusChecked(value));
    dispatch(setAmadeus(value));
  };

  const handlerChangeAerocrs = (value: boolean) => {
    dispatch(setAerocrsChecked(value));
  };

  const handleChangeProductionMode = (value: boolean) => {
    dispatch(setProductionMode(value));
  };

  const handleChangeTTLModal = (value: boolean) => {
    dispatch(setEnableTTLModal(value));
  };

  const generatedFuzzyMarks = useMemo(() => {
    const marks = [];
    for (let i = 0; i <= 10; i++) {
      const currValue = +(i / 10).toFixed(2);
      marks.push({ label: `${currValue * 10}`, value: currValue });
    }
    return marks;
  }, []);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const newValueAsNumber = newValue as number;
    setFuzzyLevelValue(newValueAsNumber);
  };

  useEffect(() => {
    dispatch(setFuzzyLevel(debouncedValue));
  }, [debouncedValue]);

  useEffect(() => {
    const buildVersion = document.querySelector('meta[build-version]');
    const commitVersion = document.querySelector('meta[name="ui-version"]');
    if (buildVersion) {
      setDate(buildVersion.getAttribute('build-version') || '');
    }
    if (commitVersion) {
      setCommit(commitVersion.getAttribute('content') || '');
    }
  }, []);

  return (
    <>
      {isDebug ? (
        <>
          <ListHeaderItem>
            <ListItemText primary="Debug" />
          </ListHeaderItem>
          <ListItem>
            <Toggle checked={isOrchestra} label="Orchestra" onChangeCallBack={handlerChangeOrchestra} />
          </ListItem>
          <ListItem>
            <Toggle checked={isAmadeus} label="Amadeus" onChangeCallBack={handlerChangeAmadeus} />
          </ListItem>
          <ListItem>
            <Toggle checked={isAerocrs} label="Aerocrs" onChangeCallBack={handlerChangeAerocrs} />
          </ListItem>
          <ListItem>
            <Toggle checked={isProductionMode} label="Production mode" onChangeCallBack={handleChangeProductionMode} />
          </ListItem>
          <ListItem>
            <Toggle checked={enableTTL} label="Enable TTL Modal" onChangeCallBack={handleChangeTTLModal} />
          </ListItem>
          <SliderContainer>
            <InputLabel>Fuzzy level:</InputLabel>
            <Slider
              max={1}
              getAriaValueText={valuetext}
              step={0.05}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => value * 10}
              marks={generatedFuzzyMarks}
              value={fuzzyLevelValue}
              onChange={handleSliderChange}
            />
          </SliderContainer>

          <ListItem>
            <VersionContainer>
              <VersionItem>
                <strong>Date build: </strong>
                <div>{date}</div>
              </VersionItem>
              <VersionItem>
                <strong>Last commit: </strong>
                <div>{commit}</div>
              </VersionItem>
            </VersionContainer>
          </ListItem>
        </>
      ) : null}
    </>
  );
};
