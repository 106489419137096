import { gql } from '@apollo/client';
import { FlightOrderModel } from 'models/flightOrder.model';
import { ASSOCIATED_PAYMENT_FRAGMENT, EXTERNAL_PNRS_FRAGMENT, JOURNEY_FRAGMENT, PASSENGERS_FRAGMENT } from 'api/fragments';

export interface FLIGHT_ORDER_DETAILS_data {
  flightOrderDetails: FlightOrderModel;
}

export interface FLIGHT_ORDER_DETAILS_vars {
  flightOrderId?: string;
  PNR?: string;
  lastName?: string;
}

export const ORDER_DETAILS_FRAGMENT = gql`
  fragment OrderDetailsFragment on OrderDetails {
    flightOrderId
    paymentState
    fasaxPNR
    passengerCount
    pnrEmail
    pnrPhoneNumber
    priceOrderUsd {
      amount
      currency
    }
    priceOrderKes {
      amount
      currency
    }
    orderPaymentStatus
    passengers {
      ...PassengersFragment
    }
    associatedPayments {
      ...AssociatedPaymentFragment
    }
    externalPNRs {
      ...ExternalPNRsFragment
    }
    journeys {
      ...JourneyFragment
    }
  }
  ${ASSOCIATED_PAYMENT_FRAGMENT}
  ${PASSENGERS_FRAGMENT}
  ${EXTERNAL_PNRS_FRAGMENT}
  ${JOURNEY_FRAGMENT}
`;

export const FLIGHT_ORDER_DETAILS = gql`
  query flightOrderDetails($flightOrderId: UUID, $PNR: AlphanumericStr, $lastName: AlphaStr) {
    flightOrderDetails(flightOrderId: $flightOrderId, PNR: $PNR, lastName: $lastName) {
      ...OrderDetailsFragment
    }
  }
  ${ORDER_DETAILS_FRAGMENT}
`;
