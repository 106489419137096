/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  'https://static.fasax.com/public/other-logos/zaad.jpg',
  'https://static.fasax.com/public/other-logos/evcplus.png',
  'https://static.fasax.com/public/logos/I4F.png',
  'https://static.fasax.com/public/logos/FZS.png',
  'https://static.fasax.com/public/logos/FMA.png',
  'https://static.fasax.com/public/logos/FHL.png',
  'https://static.fasax.com/public/logos/F6X.png',
  'https://static.fasax.com/public/logos/FDA.png',
  'https://static.fasax.com/public/other-logos/golis.png',
  'https://static.fasax.com/public/other-logos/amadeus_logo.png',
];

export const Partners = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Trusted by your favorite travel companies
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              We are an ARC Accredited Online Travel Agency, which allows us to book and issue tickets on IATA member airlines.
              <br />
              We are working hard to add every airline and payment processor in East Africa to our partners list.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box display="flex" flexWrap="wrap" justifyContent={'flex-start'}>
              {mock.map((item, i) => (
                <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                  <Box
                    component="img"
                    height={1}
                    width={1}
                    src={item}
                    alt="..."
                    sx={{
                      filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(0.7)' : 'none',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
