import { useEffect } from 'react';
import { getSessionId } from 'utils/sessionId';

export const ChatwootBubble = () => {
  const sessionId = getSessionId();
  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: 'en', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
    };
  });

  (function (d, t) {
    const BASE_URL = 'https://app.chatwoot.com';
    const g = d.createElement(t);
    const s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: 'YX6FhQpYnCwL3Tg3dHPHfDQr',
        baseUrl: BASE_URL,
      });
      window.$chatwoot.setUser('user_id', {
        name: 'user',
        id: sessionId,
      });
    };
  })(document, 'script');

  return null;
};
