import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { capitalizeWord } from 'utils/strings';
import { getAmountCurrency } from 'utils/currency';
import { useToggle } from 'hooks/useToggle';
import { Modal } from 'components';
import { ItinerariesDetailed } from 'components/ItinerariesDetailed/ItinerariesDetailed/ItinerariesDetailed';

import { FlightOptionFooter } from './FlightOptionFooter';
import { FlightOptionCard, FlightOptionContent } from './styles';
import { ItinerariesList } from './ItinerariesList';

interface FlightOptionProps {
  onSelectFlightOption: (e: React.MouseEvent<HTMLElement>) => void;
}

export const FlightOption = (props: JourneyOfferModel & FlightOptionProps) => {
  const { priceQuote, itineraries, onSelectFlightOption } = props;
  const { isOpen: isOpenDetails, toggle: toggleDetails } = useToggle();

  const tickerPrice = getAmountCurrency(priceQuote);

  const { cabin } = useAppSelector((state) => state.search);
  return (
    <FlightOptionCard>
      <FlightOptionContent>
        <ItinerariesList items={itineraries} operationAirlines={props.ticketingDetails.operatingAirlines} />
      </FlightOptionContent>

      <FlightOptionFooter
        operationAirlines={props.ticketingDetails.operatingAirlines}
        onSubmitHandler={onSelectFlightOption}
        onDetailsHandler={toggleDetails}
        priceTotal={tickerPrice}
        cabin={capitalizeWord(cabin)}
      />
      <Modal title="Flight Details" open={isOpenDetails} onClose={toggleDetails}>
        <ItinerariesDetailed
          journeyOffer={props}
          price={tickerPrice}
          cabin={capitalizeWord(cabin)}
          hasFooterButton={true}
          onFooterSubmit={onSelectFlightOption}
        />
      </Modal>
    </FlightOptionCard>
  );
};
