import { gql } from '@apollo/client';
import { ServiceListItem } from 'models/service.model';
import { Currency } from 'enum/enum';

export interface SERVICE_LIST_data {
  serviceList: ServiceListItem[];
}

export interface SERVICE_LIST_vars {
  currency?: Currency;
  journeyUUID: string;
}

export const SERVICES_LIST = gql`
  query QueryServiceList($journeyUUID: UUID!) {
    serviceList(journeyUUID: $journeyUUID) {
      name
      services {
        id
        offerId
        features
        type
        description
        priceQuote {
          amount
          currency
        }
        paxRef
        segRef
      }
    }
  }
`;
