import { DirectionEnum, TicketClassEnum } from 'enum/enum';

export const directionOptions = [
  { title: 'One-way', value: DirectionEnum.ONE_WAY },
  { title: 'Round-trip', value: DirectionEnum.ROUND },
  { title: 'Multi-City', value: DirectionEnum.MULTI },
];

export const ticketClassOptions = [
  { title: 'Economy', value: TicketClassEnum.ECONOMY },
  { title: 'Business', value: TicketClassEnum.BUSINESS },
  { title: 'First', value: TicketClassEnum.FIRST },
];
