import React from 'react';
import { Paper } from '@mui/material';

import { Typography } from 'components';
import { PassengerGroupedServicesBySegments } from 'models/passenger.model';
import { ServiceItemCategory } from './ServiceItemCategory';
import { EmptyServices, NoAvailableNotifier, PassengerCardHeader, ServicesSegmentContainer } from './styles';

interface PassengerServiceProps {
  item: PassengerGroupedServicesBySegments;
}

export const PassengerServiceCard = ({ item }: PassengerServiceProps) => {
  return (
    <Paper>
      <PassengerCardHeader>
        <Typography variant="smallTitle">
          {item.firstName} {item.lastName}
        </Typography>
        <Typography variant="body">{item.dateOfBirth}</Typography>
      </PassengerCardHeader>
      {item?.hasServices ? (
        item?.servicesBySegment.map((s) => {
          if (!s.serviceList.length) return null;
          return (
            <ServicesSegmentContainer key={s.destination}>
              <Typography variant="smallTitle">Add services for: {s.destination}</Typography>
              <ServiceItemCategory items={s.serviceList} />
            </ServicesSegmentContainer>
          );
        })
      ) : (
        <EmptyServices>
          <NoAvailableNotifier>
            <Typography variant="sectionTitle">There are no services available for the passenger</Typography>
          </NoAvailableNotifier>
        </EmptyServices>
      )}
    </Paper>
  );
};
