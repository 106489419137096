import { Paper, styled } from '@mui/material';
import { colors } from 'style';

export const ConnectionDetailsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  width: 100%;
  padding: 12px;
  gap: 6px;
`;

export const ConnectionDetailItem = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;
