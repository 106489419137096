import { Box, styled } from '@mui/material';
import { colors } from 'style';

export const CardWrapper = styled(Box)`
  padding: 24px 16px 18px 24px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray30};
  border-radius: 6px;
`;

export const CardContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ButtonsBlock = styled(Box)`
  display: flex;
  gap: 20px;
  margin-top: 24px;
  margin-left: auto;
  button {
    width: 100%;
    min-width: max-content;
  }
`;
