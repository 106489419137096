export const multipleOrSingleWordEnding = (word: string, isMultiple: boolean) => {
  return `${word}${isMultiple ? 's' : ''}`;
};

export const capitalizeWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getUniqNames = <T>(data: T[], accessor: keyof T) => [...new Set(data.map((d) => d[accessor]))];

export const getChangeDateCount = (changeOfDate: number) => {
  if (!changeOfDate) return; // if null or 0 changeDate will not be displayed
  if (changeOfDate > 0) return `+${changeOfDate}`; // positive count days
  return `${changeOfDate}`; // negative days case
};

export const parseQueryString = (queryString: string): { [key: string]: string } => {
  return queryString
    .replace(/^\?/, '')
    .split('&')
    .reduce((params: { [key: string]: string }, param: string) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
};

export const removePlusFromNumber = (num: string) => {
  return num?.replaceAll('+', '');
};
