import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Contact } from 'components/pages/about/Contact';

export const FooterHelpPage = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          sx={{
            fontWeight: 'medium',
          }}
        >
          Didn't find what you are looking for?
        </Typography>
      </Box>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={'center'} alignItems={{ xs: 'stretched', sm: 'center' }}>
        <Contact />
      </Box>
    </Box>
  );
};
