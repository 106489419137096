import React from 'react';

import { MarginTop, Typography } from 'components';
import { ButtonTab, TabsWrapper } from 'components/pages/entry/PaymentTabs/styles';
import { PaymentOption } from 'components/pages/paymentOptions/PaymentOptions';

interface IProps {
  tabs: PaymentOption[];
  setActive: (tab: PaymentOption) => void;
  active: PaymentOption;
  disabled?: boolean;
}

export const PaymentTabs = ({ tabs, setActive, active, disabled }: IProps) => {
  const onClickHandler = (currentTab: PaymentOption) => {
    setActive(currentTab);
  };

  return (
    <>
      <MarginTop marginTop={'16'}>
        <Typography variant="smallTitleBold">Ku Bixi</Typography>
      </MarginTop>
      <MarginTop marginTop={'20'}>
        <TabsWrapper>
          {tabs.map((tab, i) => {
            return (
              <ButtonTab disabled={disabled} key={tab.network} active={tabs[i].network === active.network} onClick={() => onClickHandler(tabs[i])}>
                {tabs[i].title}
              </ButtonTab>
            );
          })}
        </TabsWrapper>
      </MarginTop>
    </>
  );
};
