import { DatepickerLimitModel } from 'models/datepicker-limit.model';
import { PassengerCountModel } from 'models/passenger.model';
import { PassengerType } from 'enum/enum';

interface PassengerInfoType extends DatepickerLimitModel {
  type: string;
  title: string;
  queryType: PassengerType;
}

const passengerInfo: PassengerInfoType[] = [
  { type: 'adults', title: 'Adult', min: 12, max: 100, queryType: PassengerType.ADULT },
  { type: 'children', title: 'Child', min: 2, max: 12, queryType: PassengerType.CHILD },
  { type: 'infants', title: 'Infant', min: 0, max: 2, queryType: PassengerType.INFANT },
];

export const useBookingFormData = (passengersCountData: PassengerCountModel) => {
  const generatePassengerInfo = () => {
    const passengers: Omit<PassengerInfoType, 'type'>[] = [];

    Object.entries(passengersCountData).forEach(([passengerTypeName, passengerByTypeCount]) => {
      const passengerTypeData = passengerInfo.find((item) => item.type === passengerTypeName) as PassengerInfoType;
      if (passengerTypeData) {
        const { queryType, title, min, max } = passengerTypeData;
        for (let i = 0; i < passengerByTypeCount; i++) {
          passengers.push({ title: `${title} ${i + 1}`, min, max, queryType });
        }
      }
    });

    return passengers;
  };

  return generatePassengerInfo();
};
