import React, { useState } from 'react';
import { ImageWrapper, SkeletonAvatar } from 'components/ImageWithLoader/styles';

interface ImageWithLoaderProps {
  src: string;
  maxWidth: number;
  height?: number;
}

export const ImageWithLoader = ({
  src,
  maxWidth,
  height,
  ...rest
}: ImageWithLoaderProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ImageWrapper maxWidth={maxWidth} height={height}>
      <img onLoad={() => setIsLoaded(true)} src={src} alt="flight_image" {...rest} />
      {isLoaded ? null : <SkeletonAvatar maxWidth={maxWidth} height={height} />}
    </ImageWrapper>
  );
};
