import { styled } from '@mui/material';

export const VersionContainer = styled('div')`
  font-size: 12px;
`;

export const VersionItem = styled('div')`
  margin-bottom: 10px;
`;

export const SliderContainer = styled('div')`
  padding: 0 16px;
`;
