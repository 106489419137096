import styled from '@emotion/styled';
import { Card } from '@mui/material';
import { colors } from 'style';

export const LinkContainer = styled.div`
  display: flex;
`;

export const LinkItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const PrivacyContactWrapper = styled(Card)(() => ({
  border: `1px solid ${colors.gray20}`,
  boxShadow: 'none',
  padding: '20px',
}));
