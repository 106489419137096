import { SearchModel } from 'models/search.model';
import { convertFullDate } from 'utils/dates';
import { DirectionEnum } from 'enum/enum';

export const creatingRouterLinkForTrip = (values: SearchModel) => {
  const firstFlight = values.ItineraryRequest[0];
  const departureDate = firstFlight.departureDate ? `/${convertFullDate(firstFlight.departureDate)}` : '';
  const returnDate = firstFlight.returnDate && values.direction === DirectionEnum.ROUND ? `/${convertFullDate(firstFlight.returnDate)}` : '';
  const destinationAirport = firstFlight?.destinationAirport?.airportCode;
  const originAirport = firstFlight?.originAirport?.airportCode;
  const cabin = values.cabin.toLowerCase();
  const direction = values.direction;
  const adults = values.passengers.adults !== 0 ? values.passengers.adults : '';
  const children = values.passengers.children !== 0 ? `&children=${values.passengers.children}` : '';
  const infants = values.passengers.infants !== 0 ? `&infants=${values.passengers.infants}` : '';

  return `${originAirport}-${destinationAirport}${departureDate}${returnDate}/${cabin}?direction=${direction}&adults=${adults}${children}${infants}`;
};
