import React from 'react';
import { Typography } from '@mui/material';
import { formatDateToLongString } from 'utils/dates';
import { ContainerStyled } from 'style';
import blogData from './blog.json';
import { toast } from 'react-toastify';
import { BlogWrapper, PostDate } from './Blog.css';

export const Blog = () => {
  const createBlogContent = () => {
    try {
      return JSON.parse(JSON.stringify(blogData));
    } catch (e) {
      toast.success('Went something wrong. Please try again later');
    }
  };
  const blogContent = createBlogContent();

  const renderFormattedContent = () => {
    if (!blogContent) {
      return null;
    }

    const { title, content, postDate } = blogContent;

    return (
      <BlogWrapper>
        <PostDate>{formatDateToLongString(postDate)}</PostDate>
        <Typography component="h1" variant="h4" fontWeight={600}>
          {title}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </BlogWrapper>
    );
  };

  return (
    <>
      <ContainerStyled>{renderFormattedContent()}</ContainerStyled>
    </>
  );
};
