import { Box, styled } from '@mui/material';
import { colors } from 'style';
import { Typography } from 'components/kit/Typography';

export const Wrapper = styled('div')`
  display: grid;
  gap: 0 8px;
  padding: 12px 16px;
`;

export const Segment = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  width: '100%',
  alignItems: 'center',
}));

export const SegmentInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'maxWidth',
}));

export const TypographyTime = styled(Typography)`
  width: max-content;
`;

export const TypographyAirportCode = styled(Typography)`
  color: ${colors.gray60};
`;

export const Line = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  borderTop: `1px solid ${colors.border}`,
  height: 'min-content',
}));

export const TotalDuration = styled(Typography)`
  position: absolute;
  top: 3px;
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
`;

export const SegmentSquareArea = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SegmentSquare = styled(Box)`
  width: 6px;
  height: 6px;
  border: 1px solid ${colors.black};
  background-color: ${colors.white};
`;

export const SquareSegmentsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  top: -3px;
`;

export const ArrivesWithChangeDate = styled(Box)`
  display: flex;
  position: relative;
`;

export const ChangeDayNotification = styled('span')`
  position: absolute;
  bottom: 4px;
  left: 100%;
`;

export const ValidationAirline = styled(Typography)`
  width: max-content;
  color: ${colors.gray60};
`;
