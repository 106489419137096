import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { CardBrandImage, Loader, MarginTop, Typography } from 'components';
import { colors, JustifyCenter, PaperWrapper } from 'style';
import { routes } from 'routes';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { CardModel } from 'models/card.model';

import { PaymentDescription } from './styles';

interface IProps {
  associatedPayments: AssociatedPaymentModel[];
  networkConfirmationCode: string;
  loading: boolean;
  price: string;
  cardLast4: string;
  card: CardModel;
  lastCardDigitsLabel: string;
}

export const PaymentSuccessful = ({ associatedPayments, networkConfirmationCode, loading, price, cardLast4, card, lastCardDigitsLabel }: IProps) => {
  const history = useHistory();

  const reviewOrderHandler = () => {
    history.push(routes.order, { access: true });
  };

  const paymentDescriptions = [
    { title: 'Payment Total', value: price },
    { title: lastCardDigitsLabel, value: cardLast4 },
    { title: 'Confirmation Number', value: networkConfirmationCode },
  ];

  if (!associatedPayments.length || loading) return <Loader />;

  return (
    <div data-testid="success-page">
      <Typography display="flex" justifyContent="center" variant="title">
        Thank you for your payment!
      </Typography>

      <MarginTop marginTop={'30'}>
        <PaperWrapper>
          <JustifyCenter>
            <Typography color={colors.primary} variant="titleBold">
              Payment Receipt
            </Typography>
          </JustifyCenter>
          <JustifyCenter>
            <CardBrandImage src={card.img} />
          </JustifyCenter>
          {paymentDescriptions.map((description) => {
            return (
              <PaymentDescription key={description.title}>
                <Typography variant="titleBold" color={colors.gray60}>
                  {description.title}
                </Typography>
                <Typography variant="titleBold">{description.value}</Typography>
              </PaymentDescription>
            );
          })}
        </PaperWrapper>
      </MarginTop>
      <MarginTop marginTop={'30'}>
        <Button variant="contained" onClick={reviewOrderHandler} fullWidth size="large">
          Review Order Details
        </Button>
      </MarginTop>
    </div>
  );
};
