import React, { useState, useEffect } from 'react';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

export const useInstallPwaPromptEvent = (): [
  BeforeInstallPromptEvent | null,
  React.Dispatch<React.SetStateAction<BeforeInstallPromptEvent | null>>,
] => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const beforeInstallPromptListener = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptListener as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptListener as any);
    };
  }, []);

  return [deferredPrompt, setDeferredPrompt];
};
