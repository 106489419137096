import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { PriceModel } from 'models/price.model';
import { Currency } from 'enum/enum';

interface InitialState {
  priceOffer: JourneyOfferModel | null;
  currentTicketPrice: {
    value: PriceModel;
    isChange: boolean;
  };
}
const initialState: InitialState = {
  priceOffer: null,
  currentTicketPrice: {
    value: {
      amount: '0',
      currency: Currency.USD,
    },
    isChange: false,
  },
};

export const priceOfferSlice = createSlice({
  name: 'flightOffer',
  initialState,
  reducers: {
    setPriceOffer(state, action: PayloadAction<JourneyOfferModel>) {
      state.priceOffer = action.payload;
    },
    resetPriceOfferState(state) {
      return initialState;
    },
    setCurrentTicketPriceOfferState(state, action: PayloadAction<PriceModel>) {
      state.currentTicketPrice = {
        isChange: parseInt(state.currentTicketPrice.value.amount) !== 0 && state.currentTicketPrice.value.amount !== action.payload.amount,
        value: action.payload,
      };
    },
    resetTicketPriceChange(state) {
      state.currentTicketPrice = {
        ...state.currentTicketPrice,
        isChange: false,
      };
    },
  },
});
export const { setPriceOffer, resetPriceOfferState, setCurrentTicketPriceOfferState, resetTicketPriceChange } = priceOfferSlice.actions;
