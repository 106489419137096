import React from 'react';
import { PassengerListItem } from 'components';
import { SummaryItemWrapper } from 'components/pages/summary/styles';
import { PassengerModel } from 'models/passenger.model';

type additionalPropsType = {
  passengerCount: number;
  goBackHandler: () => void;
};

export const SummaryItem = ({
  firstName,
  lastName,
  gender,
  pnrEmail,
  dateOfBirth,
  passengerCount,
  goBackHandler,
}: PassengerModel & additionalPropsType) => {
  return (
    <SummaryItemWrapper>
      <PassengerListItem
        firstName={firstName}
        lastName={lastName}
        passengerCount={passengerCount}
        pnrEmail={pnrEmail}
        dateOfBirth={dateOfBirth}
        goBackHandler={goBackHandler}
        gender={gender.value}
        isBackButton={true}
      />
    </SummaryItemWrapper>
  );
};
