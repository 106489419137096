export const privacyContentDummy = [
  {
    title: '1. Definitions',
    description:
      'The terms "we”, “us”, “our”, “Fasax”, and “Fasax.com” refer to Fasax Services LLC, a Limited Liability Company registered in the State of Arizona, United States of America. The terms “you” and “customer” refer to you, the customer and end user of this website. The terms “travel provider”, “travel service provider”, and “service provider” refer to one or more independent companies that provide travel services such as air transportation, hotel accommodations, ground transportation, travel insurance, visa services, or any other related service that is offered on this website.',
    links: [],
  },
  {
    title: '2. General Terms',
    description:
      'Fasax.com is a website provided to assist customers in shopping for and managing their travel booked by Fasax. Use of this website is conditioned on your acceptance of the Fasax terms and conditions, without modification. By accessing this website, you agree to be bound by this agreement. If you book a flight or make any other purchase via this website, chat, or Fasax customer service, you agree that this agreement applies to all transactions between you and Fasax.',
    links: [],
  },
  {
    title: '3. Flight Terms and Conditions',
    description:
      'When you book flights and flight related services through Fasax.com, you make a booking directly with one or more travel providers that are governed by their contract of carriage. We are not a party to the contract between you and the service provider. We act as an intermediary between you and the travel provider. The price for travel services may include commissions and service charges for our service mediating the agreement between you and the travel provider(s). By booking a flight through Fasax.com, you authorize us to pay travel service providers on your behalf, such that the transaction between you and the relevant service providers is complete. We are not responsible for the travel services to be provided by the travel service providers, and we make no representations or warranties regarding the safety, suitability, or quality of the services mediated on Fasax.com.',
    links: [],
  },
  {
    title: '4. Performance of Travel Services',
    description:
      'The performance of travel services booked through Fasax.com is solely the responsibility of the service provider. Any claims related to the performance or non-performance of a travel service are the responsibility of the service provider and subject to their contract of carriage. If a travel provider is unable to provide a travel service for any reason, including insolvency, cessation of operations, or bankruptcy, we can only act as an intermediary and refund payments we have already received from the travel provider. We do not make any representations or warranties regarding the creditworthiness of any travel provider.',
    links: [],
  },
  {
    title: '5. Payment',
    description:
      'Payments for travel services may be processed by Fasax, our affiliates, or by the service providers. Payment may be split into multiple transactions between us and one or more service providers, but the total amount will not exceed the amount presented to you. If we suspect a transaction may be fraudulent, we reserve the right to refuse processing it. We may request additional information to verify a transaction. If we do not receive a response, we may cancel or reverse the transaction. We also reserve the right to cancel or reverse transactions that we believe may be fraudulent or otherwise illegal.',
    links: [],
  },
  {
    title: '6. Acknowledgment of risks inherent in travel',
    description:
      'Travel to, and within Somalia is subject to a number of risks, up to and including hazards associated with armed conflict and terrorism. It is very important that you continually assess these risks and ongoing developments within the region, given your own personal context and risk tolerance. Fasax DOES NOT REPRESENT OR WARRANT THAT TRAVEL TO DESTINATIONS IN SOMALIA IS WITHOUT RISK. Fasax may provide you operational or other updates that we believe to be relevant to your travel, but Fasax DOES NOT REPRESENT OR WARRANT THAT THESE UPDATES ARE COMPLETE, CURRENT, OR ACCURATE. Travel and security conditions may change suddenly and without notice. OFFERS OF TRAVEL TO DESTINATIONS ON THIS WEBSITE DO NOT INDICATE THAT TRAVEL IS SAFE OR ADVISABLE TO SUCH DESTINATIONS.',
    links: [],
  },
  {
    title: '7. Travel advisories',
    description:
      'Fasax urges passengers to review travel prohibitions, warnings, announcements and advisories issued by the United States Government, the UK Government, the Republic of Somaliland, the Federal Government of Somalia, and the Republic of Kenya prior to booking travel to international destinations. \n You can review travel advisories from the US and UK governments at the following links:',
    links: [
      'https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/Somalia.html',
      'https://www.osac.gov/Country/Somalia/Detail',
      'https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories/kenya-travel-advisory.html',
      'https://www.gov.uk/foreign-travel-advice/somalia',
      'https://www.gov.uk/foreign-travel-advice/kenya',
    ],
  },
  {
    title: '8. Visa, passport, and other document requirements',
    description:
      'Fasax does not provide authoritative advice on the legal requirements for visiting any country. It is your responsibility to ensure that you have valid travel documents and meet the requirements to enter and transit through all points on your itinerary. You may be required to pay visa fees, airport fees, or other taxes and fees as part of your journey that are your responsibility, independent of this agreement. These fees are subject to change. Any information provided by Fasax on this website or otherwise is provided on a best efforts basis and should be verified with local authorities. You should also be aware that the Republic of Somaliland has immigration laws, taxes, and requirements that are completely independent of those of the Federal Republic of Somalia.',
    links: [],
  },
  {
    title: '9. Privacy Policy',
    description:
      'When you search for and book travel on Fasax.com, you give us information, much of which is necessary to complete your booking. We share this information as necessary with travel providers and other third parties so that they can confirm your booking and comply with applicable laws. We use cookies to store information throughout your search and booking flow to keep track of your selections. We use Google Analytics cookies to collect metrics and better understand who is visiting our site. We also use cookies from Sentry.io and others to detect errors in our site and improve our user experience. We also collect information from your device, including browser headers and IP addresses to understand who visits our site and improve our user experience. If you have a question, concern, or request about our privacy policy, please contact',
    links: ['privacy@fasax.com'],
  },
  {
    title: '10. Governing Law, Severability, and Entire Agreement',
    description:
      'Fasax.com is operated by a U.S. company and this Agreement is governed by the laws of the State of Arizona, United States of America. You consent to the exclusive jurisdiction and venue of the courts of Maricopa County, Arizona, USA. You also accept that this and other terms referenced on our website constitute the entire agreement between you and Fasax. If any part of this agreement is determined to be illegal, invalid, or unenforceable, the legality, validity, and enforceability of all remaining terms will not be affected.',
    links: [],
  },
];
