import { gql } from '@apollo/client';
import { PassengerModelBackendFormat } from 'models/passenger.model';
import { FlightOrderModel } from 'models/flightOrder.model';
import { ASSOCIATED_PAYMENT_FRAGMENT, EXTERNAL_PNRS_FRAGMENT, JOURNEY_FRAGMENT, PASSENGERS_FRAGMENT } from 'api/fragments';

export interface CREATE_FLIGHT_ORDER_data {
  createFlightOrder: FlightOrderModel;
}

export interface CREATE_FLIGHT_ORDER_vars {
  adults: number;
  children: number;
  infants: number;
  journeyUUID: string;
  passengers: PassengerModelBackendFormat[];
  pnrPhoneNumber: string;
  pnrEmail: string;
}

export const CREATE_FLIGHT_ORDER = gql`
  mutation createFlightOrder(
    $adults: Int!
    $children: Int
    $infants: Int
    $journeyUUID: String!
    $passengers: [PassengerInput!]!
    $pnrPhoneNumber: PhoneNumber!
    $pnrEmail: EmailAddress
  ) {
    createFlightOrder(
      orderDetails: {
        adults: $adults
        journeyUUID: $journeyUUID
        children: $children
        infants: $infants
        passengers: $passengers
        pnrPhoneNumber: $pnrPhoneNumber
        pnrEmail: $pnrEmail
      }
    ) {
      ... on OrderDetails {
        flightOrderId
        passengers {
          ...PassengersFragment
        }
        orderPaymentStatus
        associatedPayments {
          ...AssociatedPaymentFragment
        }
        paymentState
        fasaxPNR
        externalPNRs {
          ...ExternalPNRsFragment
        }
        journeys {
          ...JourneyFragment
        }
        priceOrderUsd {
          currency
          amount
        }
        priceOrderKes {
          currency
          amount
        }
        passengerCount
        pnrPhoneNumber
        pnrEmail
      }
    }
  }
  ${PASSENGERS_FRAGMENT}
  ${ASSOCIATED_PAYMENT_FRAGMENT}
  ${EXTERNAL_PNRS_FRAGMENT}
  ${JOURNEY_FRAGMENT}
`;
