import React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type PropsType = {
  children: React.ReactNode;
  marginBottom?: string;
  sx?: SxProps<Theme>;
};

export const MarginBottom = React.memo((props: PropsType) => {
  const setMargin = props.marginBottom ? props.marginBottom + 'px' : '20px';

  return <Box sx={{ marginBottom: setMargin, padding: '0', ...props.sx }}>{props.children}</Box>;
});
