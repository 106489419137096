import React from 'react';
import Card from '@mui/material/Card';
import { Button } from '@mui/material';

import { SegmentModel } from 'models/segment.model';

import { SegmentDetailed } from '../SegmentDetailed';
import { TotalSection, TypographyTicketClass, SegmentDetailedFooter, TypographyTotal } from './styles';
import { BaggageAllowanceModel } from 'models/baggage.model';

interface ItineraryDetailedProps {
  segments: SegmentModel[];
  price: string;
  cabin: string;
  hasFooter: boolean;
  hasFooterButton: boolean;
  changeOfDate: number;
  onFooterSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  baggageAllowance?: BaggageAllowanceModel | null;
}

export const ItineraryDetailed = ({
  segments,
  cabin,
  price,
  hasFooter,
  changeOfDate,
  hasFooterButton,
  onFooterSubmit,
  baggageAllowance,
}: ItineraryDetailedProps) => {
  return (
    <Card>
      <SegmentDetailed segments={segments} baggageAllowance={baggageAllowance} changeOfDate={changeOfDate} />
      {hasFooter && (
        <SegmentDetailedFooter>
          <TotalSection>
            <TypographyTotal>{price}</TypographyTotal>
            <TypographyTicketClass>{cabin}</TypographyTicketClass>
          </TotalSection>
          {hasFooterButton && (
            <Button onClick={onFooterSubmit} variant="contained">
              Select
            </Button>
          )}
        </SegmentDetailedFooter>
      )}
    </Card>
  );
};
