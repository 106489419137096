import * as yup from 'yup';
import 'yup-phone';
import { validation } from 'validation/validation';
import { PaymentNetwork } from 'enum/enum';

export const schema = (network: PaymentNetwork) => {
  return yup.object().shape({
    phoneNumber: validation.paymentPhoneNumber(network),
  });
};
