import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { privacyContentDummy } from 'dummyData/privacyContentDummy';

const PrivacySection = ({ title, description, links }: { title: string; description: string; links: Array<string> | null }) => {
  const typeLink = (link: string) => {
    const type = link.includes('http') ? 'http' : '@';
    return type;
  };
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
      {links ? (
        links.map((link) => {
          if (typeLink(link) === 'http') {
            return (
              <Link href={link} display="block">
                {link}
              </Link>
            );
          } else {
            return (
              <Link href={`mailto:${link}`} display="block">
                {link}
              </Link>
            );
          }
        })
      ) : (
        <></>
      )}
    </Box>
  );
};

export const PrivacyContent = () => {
  return (
    <Box>
      {privacyContentDummy.map((item, i) => (
        <Box key={i} marginBottom={i < privacyContentDummy.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};
