import { styled, TextField } from '@mui/material';
import { colors } from 'style';

export const TextFieldStyled = styled(TextField)<{ hasError?: boolean }>`
  .MuiFilledInput-root {
    padding: 16px;
    border-radius: 4px;
    ${(props) =>
      props.hasError &&
      `
        border: 1px solid ${colors.error};
      `}
    transition: 0.8ms border ease-in;

    & input {
      padding: 0 0 0 14px !important;
      line-height: 24px !important;
      font-size: 16px;
    }

    &:before {
      border-bottom: none !important;
    }

    &:after {
      border-bottom: none !important;
    }
  }
`;
