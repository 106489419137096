import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { PassengerCountModel, PassengerModel } from 'models/passenger.model';
import { CREATE_FLIGHT_ORDER, CREATE_FLIGHT_ORDER_data, CREATE_FLIGHT_ORDER_vars } from 'api/mutations/createFlightOrder';
import { useAppSelector } from 'hooks/useAppSelector';
import { setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';

export const useFlightOrderRequest = (passengersList: PassengerModel[], passengersCount: PassengerCountModel) => {
  const dispatch = useDispatch();
  const { paymentPassengerInfo } = useAppSelector((state) => state.payment);
  const [createFlightOrderGraph] = useMutation<CREATE_FLIGHT_ORDER_data, CREATE_FLIGHT_ORDER_vars>(CREATE_FLIGHT_ORDER);
  return async (id: string | undefined) => {
    const transformedPassengersList = passengersList.map((p) => {
      const copyPassenger = { ...p };
      delete copyPassenger.birthDay;
      delete copyPassenger.birthYear;
      delete copyPassenger.birthMonth;
      return { ...copyPassenger, nationality: p.nationality.value, gender: p.gender.value };
    });
    const { data: flightOrderData } = await createFlightOrderGraph({
      variables: {
        journeyUUID: id as string,
        adults: passengersCount.adults,
        children: passengersCount.children,
        infants: passengersCount.infants,
        passengers: transformedPassengersList,
        pnrPhoneNumber: paymentPassengerInfo?.pnrPhoneNumber as string,
        pnrEmail: paymentPassengerInfo?.pnrEmail as string,
      },
    });

    if (flightOrderData) {
      dispatch(setFlightOrderDetails(flightOrderData?.createFlightOrder));
    }
  };
};
