import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

export const AccordionStyled = styled(Accordion)(() => ({
  '&.MuiPaper-root:before ': {
    display: 'none',
  },
  '&.MuiPaper-root ': {
    borderRadius: '4px',
  },
}));

export const DescriptionItem = styled('div')`
  display: flex;
`;

export const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
}));
